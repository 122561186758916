import { FunctionComponent } from "react";

const TopicWork: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    width={45}
    height={40}
    viewBox="0 0 45 40"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path d="M42.5 7.43H29.896V2.5a2.5 2.5 0 0 0-2.5-2.5H17.347a2.5 2.5 0 0 0-2.5 2.5v4.93H2.5A2.5 2.5 0 0 0 0 9.932v27.473a2.5 2.5 0 0 0 2.5 2.5h40a2.5 2.5 0 0 0 2.5-2.5V9.93a2.5 2.5 0 0 0-2.5-2.5ZM17.401 2.318h9.972v5.114h-9.99l.018-5.114Zm25.044 35.028H2.559V22.26h14.83v2.56h9.971v-2.56h15.086v15.085Zm0-17.384h-15.09v-2.559h-9.972v2.56H2.56V9.988h39.887v9.972Z" />
  </svg>
);

export { TopicWork };
