import { classNames } from "@/utils/classNames";
import React, { ButtonHTMLAttributes, FunctionComponent } from "react";
import { GetIcon } from "../icon";

type ButtonModifier = "primary" | "secondary" | "tertiary" | "custom" | "blank";

type Props = {
  modifier?: ButtonModifier;
  fontWeight?: string;
  defaultPadding?: boolean;
  loading?: boolean;
};

const getModifierStyles = (modifier: ButtonModifier) => {
  const modifierStyles: { [key: string]: string } = {
    primary:
      "bg-green-accent-2 w-full rounded-3xl text-[#F6F1FB] shadow-button transition-all duration-300 desktop:hover:bg-green-accent-1 active:bg-green-accent-1 pressed:bg-light-4 disabled:bg-[#17656A] disabled:hover:bg-[#17656A] disabled:text-[#8BB2B4] disabled:hover:text-[#8BB2B4] focus:drop-shadow-[0_4px_4px_rgba(0,0,0,0.25)] outline-none",
    custom: "w-full rounded-3xl shadow-[0_10px_12px_-6px_rgba(0,0,0,0.06)]", // still a button with shadows and w/h/p sizes
    blank: "", // completely blank button
    secondary: "rounded-3xl text-white border-[1px] border-green-accent-2", // secondary button
    tertiary:
      "rounded-3xl text-green-accent-2 border-[1px] border-green-accent-2", // tertiary button
  };
  return modifierStyles[modifier];
};

const Button: FunctionComponent<
  Props & ButtonHTMLAttributes<HTMLButtonElement>
> = ({
  children,
  className,
  modifier = "primary",
  fontWeight = "font-semibold",
  defaultPadding = true,
  loading,
  ...restOfProps
}) => {
  const styles = {
    primary: modifier === "primary",
    secondary: modifier === "secondary",
    tertiary: modifier === "tertiary",
    custom: modifier === "custom",
    blank: modifier === "blank",
  };

  return (
    <button
      disabled={loading}
      className={classNames(
        getModifierStyles(modifier),
        className,
        styles.primary ? fontWeight : null,
        styles.primary && defaultPadding ? "pt-3 pb-3 pl-4 pr-4" : null,
        styles.custom && defaultPadding ? "pt-3 pb-3 pl-4 pr-4" : null,
        styles.secondary && defaultPadding ? "pt-3 pb-3 pl-12 pr-12" : null,
        styles.tertiary && defaultPadding ? "pt-3 pb-3" : null
      )}
      {...restOfProps}
    >
      {loading ? (
        <GetIcon
          className="animate-spin fill-current text-light-4 m-auto  w-6 h-6"
          iconName="loading"
        />
      ) : (
        children
      )}
    </button>
  );
};

export { Button };
