import { FunctionComponent } from "react";

const ExploreIcon: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="21"
    height="19"
    viewBox="0 0 21 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.7543 2.45999C18.2916 1.99713 17.7423 1.62996 17.1377 1.37945C16.5332 1.12894 15.8851 1 15.2307 1C14.5763 1 13.9283 1.12894 13.3237 1.37945C12.7191 1.62996 12.1698 1.99713 11.7072 2.45999L10.747 3.42013L9.78688 2.45999C8.85237 1.52548 7.58491 1.00048 6.26333 1.00048C4.94174 1.00048 3.67428 1.52548 2.73978 2.45999C1.80527 3.39449 1.28027 4.66195 1.28027 5.98354C1.28027 7.30513 1.80527 8.57258 2.73978 9.50709L3.69992 10.4672L10.747 17.5143L17.7941 10.4672L18.7543 9.50709C19.2171 9.04445 19.5843 8.49514 19.8348 7.89056C20.0853 7.28598 20.2143 6.63796 20.2143 5.98354C20.2143 5.32911 20.0853 4.6811 19.8348 4.07651C19.5843 3.47193 19.2171 2.92263 18.7543 2.45999V2.45999Z"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export { ExploreIcon };
