import { FunctionComponent } from "react";

export const UnionIcon: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      id="Union"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.342991 0.75C0.342991 0.335786 0.678778 0 1.09299 0C1.5072 0 1.84299 0.335786 1.84299 0.75V15.25C1.84299 15.6642 1.5072 16 1.09299 16C0.678778 16 0.342991 15.6642 0.342991 15.25V0.75ZM14.9366 7.46967C15.2295 7.76256 15.2295 8.23744 14.9366 8.53033L10.1636 13.3033C9.87072 13.5962 9.39584 13.5962 9.10295 13.3033C8.81006 13.0104 8.81006 12.5355 9.10295 12.2426L12.5956 8.75L2.84289 8.75C2.42868 8.75 2.09289 8.41421 2.09289 8C2.09289 7.58579 2.42868 7.25 2.84289 7.25L12.5956 7.25L9.10295 3.75736C8.81006 3.46447 8.81006 2.98959 9.10295 2.6967C9.39584 2.40381 9.87072 2.40381 10.1636 2.6967L14.9366 7.46967Z"
      fill="white"
    />
  </svg>
);
