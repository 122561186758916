import { FunctionComponent } from "react";

export const ArrowDown: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    width={14}
    height={8}
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    viewBox="0 0 11 11"
  >
    <path d="M6.707 7.647a1.993 1.993 0 0 1-1.414-.585L0 1.768 1.414.354l5.293 5.293L12 .354l1.414 1.414L8.121 7.06a1.993 1.993 0 0 1-1.414.586Z" />
  </svg>
);
