import { FunctionComponent } from "react";

const ReturnIcon: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="15"
    fill="none"
    viewBox="0 0 14 15"
    className={className}
  >
    <path d="M12.833 7.578a5.852 5.852 0 11-1.678-4.083H8.75v1.167h3a1.085 1.085 0 001.083-1.084v-3h-1.166v1.796A6.991 6.991 0 1014 7.578h-1.167z"></path>
  </svg>
);

export { ReturnIcon };
