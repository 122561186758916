import { FunctionComponent } from "react";

const MarkerIcon: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="fi-rs-marker" clipPath="url(#clip0_653_2892)">
      <g id="_01_align_center">
        <path
          id="Vector"
          d="M7.97278 16.505L7.50806 16.1067C6.86741 15.5702 1.27344 10.7391 1.27344 7.2052C1.27344 3.50527 4.27284 0.505859 7.97278 0.505859C11.6727 0.505859 14.6721 3.50527 14.6721 7.2052C14.6721 10.7391 9.07816 15.5703 8.44016 16.1093L7.97278 16.505ZM7.97278 1.95445C5.07425 1.95773 2.72534 4.30664 2.72206 7.20517C2.72206 9.42523 6.16369 12.9717 7.97278 14.595C9.78191 12.9711 13.2235 9.42255 13.2235 7.20517C13.2202 4.30664 10.8713 1.95777 7.97278 1.95445Z"
          fill="#33878E"
        />
        <path
          id="Vector_2"
          d="M7.97197 9.86044C6.50534 9.86044 5.31641 8.6715 5.31641 7.20488C5.31641 5.73825 6.50534 4.54932 7.97197 4.54932C9.43859 4.54932 10.6275 5.73825 10.6275 7.20488C10.6275 8.6715 9.43862 9.86044 7.97197 9.86044ZM7.97197 5.87707C7.23866 5.87707 6.64419 6.47154 6.64419 7.20485C6.64419 7.93816 7.23866 8.53263 7.97197 8.53263C8.70528 8.53263 9.29975 7.93816 9.29975 7.20485C9.29975 6.47154 8.70531 5.87707 7.97197 5.87707Z"
          fill="#33878E"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_653_2892">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);

export { MarkerIcon };
