import { SupportModal } from "@/features/creators/creator/subscribe-modal";
import { TransactionType } from "@/model/transactionType";
import { useRouter } from "next/router";
import {
  createContext,
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";
import { useSEvents } from "../sevents/hooks";
import UserContext from "../user";
import { ParsedUrlQuery } from "querystring";

// TO DO add REF to know from where the transaction comes
interface CheckoutData {
  price: number;
  priceId?: string;
  transactionType: TransactionType;
  openModal: boolean;
  creator?: Creator;
  contentId?: string;
  contentType?: KumuContentType;
  context: ContextType; // adds information in regards of where the support modal was opened for tracking reasons
}

interface DispatchCheckoutContext {
  setCheckoutDataContext: (
    data: CheckoutData,
    status?: StatusType,
    onClose?: () => void
  ) => void;
  updateCheckoutDataContext: (data: any) => void;
  resetCheckoutDataContext: () => void;
  onClose: () => void;
}

interface CheckoutContext {
  checkoutData?: CheckoutData;
  status: StatusType;
}

const defaultState: CheckoutContext & DispatchCheckoutContext = {
  checkoutData: undefined,
  status: "IN_PROGRESS",
  setCheckoutDataContext: () => {},
  resetCheckoutDataContext: () => {},
  updateCheckoutDataContext: () => {},
  onClose: () => {},
};

const CheckoutContext = createContext<
  CheckoutContext & DispatchCheckoutContext
>(defaultState);

type OwnProps = {
  value?: CheckoutContext & DispatchCheckoutContext;
};

type Props = React.PropsWithChildren<OwnProps>;

const CheckoutProvider: FunctionComponent<Props> = ({ value, children }) => {
  const [state, setState] = useState(value ? value : defaultState);
  const { user } = useContext(UserContext);
  const { query, replace, pathname } = useRouter();
  const { support: supportSEvent } = useSEvents();

  const getRedirectUrl = (pathname: string, query: ParsedUrlQuery) => {
    delete query.context;
    delete query.status;
    delete query.type;
    delete query.amount;
    delete query.content;

    replace(
      {
        pathname,
        query,
      },
      undefined,
      { shallow: true }
    );
  };

  useEffect(() => {
    if (query.status) {
      const checkoutData = JSON.parse(
        localStorage.getItem("checkoutContext")!
      ) as CheckoutData;
      localStorage.removeItem("checkoutContext");

      const transactionTypeKey = query.type as keyof typeof TransactionType;
      const transactionType = TransactionType[transactionTypeKey];
      setCheckoutDataContext(checkoutData, query.status as StatusType, () =>
        getRedirectUrl(pathname, query)
      );

      switch (query.status) {
        case "SUCCESS":
          supportSEvent.success({
            payload: {
              entityID: query.content as string,
              context: query.context as ContextType,
              userID: user.id,
              transactionType: transactionType,
            },
          });
          break;
        case "FAILED":
        case "CANCELLED":
          supportSEvent.failure({
            payload: {
              entityID: query.content as string,
              context: query.context as ContextType,
              userID: user.id,
            },
          });
          break;
      }
    }
  }, [query]);

  const setCheckoutDataContext = (
    data: CheckoutData,
    status?: StatusType,
    onClose?: () => void
  ) => {
    setState({
      ...state,
      checkoutData: { ...data, context: data.context ?? "DEFAULT" },
      status: status || state.status,
      onClose: onClose
        ? () => {
            onClose();
            defaultOnClose();
          }
        : defaultOnClose,
    });
  };

  const updateCheckoutDataContext = (data: any) => {
    setState({
      ...state,
      checkoutData: {
        ...state.checkoutData,
        ...data,
      },
    });
  };

  const resetCheckoutDataContext = () => setState(defaultState);
  const defaultOnClose = () => resetCheckoutDataContext();

  return (
    <CheckoutContext.Provider
      value={{
        ...state,
        setCheckoutDataContext: setCheckoutDataContext,
        updateCheckoutDataContext: updateCheckoutDataContext,
        resetCheckoutDataContext: resetCheckoutDataContext,
      }}
    >
      {state.checkoutData?.openModal && <SupportModal></SupportModal>}
      {children}
    </CheckoutContext.Provider>
  );
};

export default CheckoutContext;

export { CheckoutProvider };
