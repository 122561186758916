import { FunctionComponent } from "react";

const LockCardIcon: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    viewBox="0 0 10 10"
  >
    <g clipPath="url(#a)">
      <path
        d="M9.516 4.828h-.352V3.54a3.164 3.164 0 1 0-6.328 0v1.29h-.352a.938.938 0 0 0-.937.937v4.921a.938.938 0 0 0 .937.938h7.032a.937.937 0 0 0 .937-.938V5.766a.937.937 0 0 0-.937-.938ZM6 9.047a.938.938 0 1 1 0-1.875.938.938 0 0 1 0 1.875Zm1.992-4.453a.234.234 0 0 1-.234.234H4.242a.234.234 0 0 1-.234-.234V3.539a1.992 1.992 0 0 1 3.984 0v1.055Z"
        fill="#fff"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path
          fill="#fff"
          transform="translate(.375 .375)"
          d="M0 0h11.25v11.25H0z"
        />
      </clipPath>
    </defs>
  </svg>
);

export { LockCardIcon };
