import { FunctionComponent } from "react";

export const InsightsIcon: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    className={className}
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3123_29464)">
      <path d="M1.66667 17.7656V0.265625H0V17.7656C0 18.4287 0.263392 19.0646 0.732233 19.5334C1.20107 20.0022 1.83696 20.2656 2.5 20.2656H20V18.599H2.5C2.27899 18.599 2.06702 18.5112 1.91074 18.3549C1.75446 18.1986 1.66667 17.9866 1.66667 17.7656Z" />
      <path d="M18.7502 3.59912H14.1669L16.4944 5.92662L12.9169 9.50412L11.2502 7.83745L5.23438 13.8533L6.41687 15.0358L11.2544 10.2025L12.921 11.8691L17.6769 7.11329L20.0002 9.43245V4.84912C20.0002 4.5176 19.8685 4.19966 19.6341 3.96524C19.3997 3.73082 19.0817 3.59912 18.7502 3.59912Z" />
    </g>
    <defs>
      <clipPath id="clip0_3123_29464">
        <rect
          width="20"
          height="20"
          fill="white"
          transform="translate(0 0.285645)"
        />
      </clipPath>
    </defs>
  </svg>
);
