import { FunctionComponent } from "react";

const MeditationIcon: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="100"
    height="47"
    viewBox="0 0 100 47"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M42.534 37.4351C52.8714 37.4351 61.2515 29.055 61.2515 18.7176C61.2515 8.38014 52.8714 0 42.534 0C32.1965 0 23.8164 8.38014 23.8164 18.7176C23.8164 29.055 32.1965 37.4351 42.534 37.4351Z"
      fill="#EBBD46"
    />
    <path
      d="M99.8433 17.7403C98.1568 15.9586 96.3762 14.2684 94.5091 12.6769C90.8627 9.58473 86.8258 6.9554 82.326 5.29908C80.6319 4.67345 78.8815 4.21263 77.0989 3.92301C76.8848 3.88839 76.6738 3.85375 76.4596 3.8254C76.4439 3.82382 76.4281 3.82382 76.4124 3.8254C76.3795 3.81608 76.3458 3.80975 76.3117 3.8065C75.874 3.74983 75.4363 3.70574 74.9954 3.67426C72.6213 3.51643 70.2371 3.73105 67.9293 4.31034C66.5903 4.64816 65.2838 5.10336 64.0247 5.67064C62.7634 6.28815 61.5405 6.98112 60.3625 7.74576C59.7299 8.13 59.0784 8.48207 58.4103 8.80063C56.4596 9.72486 54.3947 10.3856 52.2699 10.7656C50.835 11.023 49.382 11.1662 47.9245 11.1938C46.6586 11.219 45.4652 11.2631 44.2655 11.7197C42.9851 12.2007 41.835 12.9747 40.9071 13.9796C39.9793 14.9846 39.2994 16.1927 38.9218 17.5073C38.892 17.6241 38.9004 17.7473 38.9457 17.8589C38.9911 17.9705 39.071 18.0647 39.1737 18.1276C39.498 18.3607 39.8129 18.6252 40.1561 18.833C40.459 18.9712 40.7823 19.0595 41.1134 19.0943C41.655 19.2077 42.1977 19.3179 42.7414 19.425C44.7409 19.8217 46.7489 20.1734 48.7652 20.4799C52.821 21.0939 56.8998 21.5263 61.0018 21.7772C61.0268 21.7809 61.0521 21.783 61.0774 21.7835C65.7115 22.0632 70.3567 22.1084 74.9954 21.9189C75.6976 21.8906 76.3967 21.8559 77.0989 21.815C80.0242 21.6512 82.9421 21.393 85.8528 21.0403C88.5451 20.7129 91.2269 20.3067 93.8982 19.8217C95.8064 19.4754 97.7073 19.0891 99.6008 18.6629C99.6928 18.6357 99.7762 18.5853 99.8429 18.5164C99.9097 18.4476 99.9576 18.3627 99.982 18.2699C100.006 18.1772 100.006 18.0797 99.9821 17.9869C99.9578 17.8942 99.91 17.8092 99.8433 17.7403Z"
      fill="#D9CEF8"
    />
    <path
      d="M60.9384 42.699C58.2286 39.8002 55.243 37.1721 52.0239 34.8519C50.9564 34.1025 49.8575 33.3972 48.727 32.7516C47.3411 31.9516 45.9018 31.2477 44.4193 30.645C44.0887 30.5096 43.7549 30.3805 43.4212 30.2577C41.5249 29.5554 39.5579 29.0612 37.5548 28.784C37.539 28.7809 37.5233 28.784 37.5076 28.7809C37.4761 28.7746 37.4414 28.7683 37.4099 28.762C36.4389 28.6361 35.4606 28.5761 34.4815 28.5825C33.7793 28.5825 33.0739 28.6203 32.3748 28.6896C31.2454 28.8008 30.1256 28.9945 29.0244 29.269C28.4797 29.4044 27.9381 29.5618 27.4059 29.7413C26.6298 29.9967 25.8677 30.2931 25.123 30.6293C25.1104 30.6356 25.1009 30.6387 25.0884 30.645C23.8382 31.2566 22.6267 31.9444 21.4608 32.7044C21.4356 32.7201 21.4073 32.7359 21.3821 32.7516C20.0578 33.5438 18.6568 34.2 17.2004 34.7102C15.9502 35.1474 14.6679 35.4864 13.3651 35.7242C11.9303 35.9823 10.4771 36.1244 9.01959 36.1493C7.75373 36.1745 6.56032 36.2185 5.36374 36.6783C4.08296 37.1593 2.93241 37.9331 2.00403 38.9381C1.07565 39.943 0.395162 41.1511 0.016941 42.4659C-0.0122369 42.5827 -0.00356355 42.7057 0.0417149 42.8172C0.0869933 42.9287 0.166536 43.0229 0.268851 43.0863C0.593175 43.3193 0.911217 43.5806 1.2513 43.7885C1.5538 43.9278 1.87724 44.0161 2.20855 44.0498C2.75017 44.1632 3.29177 44.2765 3.83652 44.3836C5.83608 44.7804 7.84507 45.1309 9.86349 45.4353C12.6314 45.8541 15.4108 46.189 18.2018 46.4398C19.5243 46.5626 20.8478 46.6634 22.1725 46.7421C25.5701 46.9468 28.9709 47.0266 32.3749 46.9814C33.0771 46.972 33.7793 46.9563 34.4815 46.9342C38.6506 46.8114 42.8071 46.4986 46.9511 45.9958C51.57 45.437 56.1576 44.6435 60.6959 43.6184C60.7878 43.5915 60.871 43.5414 60.9377 43.4728C61.0045 43.4043 61.0523 43.3196 61.0767 43.2271C61.1011 43.1346 61.1012 43.0374 61.077 42.9449C61.0527 42.8523 61.005 42.7676 60.9384 42.699Z"
      fill="#D9CEF8"
    />
  </svg>
);

export { MeditationIcon };
export default MeditationIcon;
