import { FunctionComponent } from "react";

const TopicStretching: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    width={51}
    height={40}
    viewBox="0 0 51 40"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path d="M44.119 11.647a8.406 8.406 0 0 0-7.53-4.607H34.41a2.845 2.845 0 0 1-2.755-2.15l-.963-3.812a.907.907 0 0 0-.86-.679h-8.616a.898.898 0 0 0-.86.68L19.39 4.89a2.836 2.836 0 0 1-2.755 2.149h-2.179a8.402 8.402 0 0 0-7.525 4.607l-5.815 8.398a.898.898 0 0 0 .74 1.414.894.894 0 0 0 .743-.387l5.849-8.45a.551.551 0 0 0 .065-.107 6.61 6.61 0 0 1 5.943-3.67h2.18a4.642 4.642 0 0 0 4.503-3.507l.795-3.13h7.204l.795 3.13a4.641 4.641 0 0 0 4.504 3.507h2.179a6.61 6.61 0 0 1 5.944 3.67c.019.037.04.073.064.107l5.85 8.45a.903.903 0 0 0 1.482-1.027l-5.836-8.398Z" />
    <path d="M49.19 21.889a1.327 1.327 0 0 1-1.096-.572l-5.849-8.45a1.296 1.296 0 0 1-.099-.163 6.18 6.18 0 0 0-5.557-3.438H34.41a5.071 5.071 0 0 1-4.92-3.8l-.71-2.806h-6.537l-.713 2.807a5.072 5.072 0 0 1-4.921 3.83h-2.18a6.18 6.18 0 0 0-5.556 3.437 1.25 1.25 0 0 1-.095.155l-5.85 8.454a1.333 1.333 0 1 1-2.191-1.517l5.798-8.372a8.84 8.84 0 0 1 7.899-4.818h2.175a2.407 2.407 0 0 0 2.338-1.822L19.914 1A1.332 1.332 0 0 1 21.204 0h8.595a1.332 1.332 0 0 1 1.289 1.006l.963 3.808a2.412 2.412 0 0 0 2.338 1.822h2.179a8.84 8.84 0 0 1 7.9 4.818l5.792 8.372a1.327 1.327 0 0 1-1.091 2.088l.021-.025ZM21.573 1.779h7.873l.86 3.439a4.212 4.212 0 0 0 4.087 3.18h2.179a7.035 7.035 0 0 1 6.344 3.945l.038.06L48.8 20.85a.486.486 0 0 0 .658.12.473.473 0 0 0 .12-.662l-5.84-8.466a7.999 7.999 0 0 0-7.148-4.371H34.41a3.27 3.27 0 0 1-3.171-2.472l-.963-3.808a.477.477 0 0 0-.46-.356h-8.6a.469.469 0 0 0-.46.356l-.967 3.808a3.267 3.267 0 0 1-3.172 2.472h-2.174a7.98 7.98 0 0 0-7.148 4.375l-.03.047-5.819 8.398a.477.477 0 0 0 .12.658.482.482 0 0 0 .658-.12l5.85-8.45a.15.15 0 0 0 .03-.048 7.04 7.04 0 0 1 6.33-3.915h2.179a4.212 4.212 0 0 0 4.087-3.18l.873-3.456Z" />
    <path d="M12.96 20.71a.907.907 0 0 0-1.586-.536L5.456 26.99a.903.903 0 0 0 1.363 1.186l4.478-5.157.937 14.797a.902.902 0 0 0 .898.86h24.756a.903.903 0 0 0 .902-.86l.937-14.797 4.474 5.157a.907.907 0 1 0 1.367-1.186l-5.922-6.816a.907.907 0 0 0-1.4.044.898.898 0 0 0-.182.493L37.04 36.862H26.413v-8.828a.902.902 0 0 0-1.806 0v8.828H13.98L12.96 20.711Z" />
    <path d="M37.887 39.097H13.132a1.337 1.337 0 0 1-1.328-1.25l-.86-13.753-3.786 4.366a1.337 1.337 0 0 1-2.338-.782 1.324 1.324 0 0 1 .326-.967l5.918-6.816a1.333 1.333 0 0 1 2.338.79l.993 15.748h9.795v-8.398a1.332 1.332 0 1 1 2.665 0v8.398h9.794l.997-15.747a1.332 1.332 0 0 1 2.338-.791l5.918 6.816a1.333 1.333 0 0 1-.133 1.878 1.29 1.29 0 0 1-.967.327 1.322 1.322 0 0 1-.911-.46l-3.79-4.362-.86 13.753a1.336 1.336 0 0 1-1.354 1.25ZM11.67 21.95l1.002 15.846a.473.473 0 0 0 .473.43H37.9a.473.473 0 0 0 .473-.43l1.001-15.846 5.157 5.944a.454.454 0 0 0 .323.163c.124.01.248-.03.343-.111a.49.49 0 0 0 .164-.327.481.481 0 0 0-.116-.344l-5.918-6.816a.482.482 0 0 0-.512-.138.464.464 0 0 0-.318.43L37.45 37.305H25.982v-9.27a.473.473 0 0 0-.945 0v9.257H13.579l-1.05-16.555a.473.473 0 0 0-.829-.28l-5.918 6.817a.46.46 0 0 0-.116.344.455.455 0 0 0 .164.322.429.429 0 0 0 .34.116.47.47 0 0 0 .326-.159l5.174-5.948Z" />
    <path d="M17.232 26.372 18.766 25a9.248 9.248 0 0 0 3.073-6.877v-5.071a.902.902 0 0 0-1.805 0v5.071a7.449 7.449 0 0 1-2.47 5.532l-1.535 1.37a.902.902 0 1 0 1.203 1.346Z" />
    <path d="M16.63 27.033a1.328 1.328 0 0 1-1.328-1.406 1.287 1.287 0 0 1 .43-.92l1.53-1.375a7.007 7.007 0 0 0 2.33-5.209v-5.07a1.333 1.333 0 0 1 2.664 0v5.07a9.677 9.677 0 0 1-3.19 7.195l-1.533 1.375c-.248.223-.57.345-.903.34Zm4.298-14.454a.473.473 0 0 0-.473.473v5.072a7.864 7.864 0 0 1-2.613 5.849l-1.534 1.375a.477.477 0 0 0-.16.327.49.49 0 0 0 .121.34.481.481 0 0 0 .666.038l1.53-1.375a8.802 8.802 0 0 0 2.931-6.555v-5.07a.472.472 0 0 0-.46-.474h-.008ZM30.087 12.15a.902.902 0 0 0-.907.903v5.071a9.247 9.247 0 0 0 3.073 6.877l1.534 1.37a.898.898 0 0 0 1.115.074.903.903 0 0 0 .088-1.418l-1.534-1.371a7.448 7.448 0 0 1-2.467-5.532v-5.071a.902.902 0 0 0-.902-.903Z" />
    <path d="M34.389 27.034c-.329 0-.646-.12-.89-.34l-1.534-1.375a9.68 9.68 0 0 1-3.215-7.195v-5.071a1.336 1.336 0 0 1 2.669 0v5.071a7.002 7.002 0 0 0 2.325 5.21l1.534 1.375a1.332 1.332 0 0 1-.89 2.325ZM30.09 12.58a.477.477 0 0 0-.477.473v5.071a8.816 8.816 0 0 0 2.93 6.554l1.535 1.376a.481.481 0 0 0 .762-.197.468.468 0 0 0-.13-.508l-1.534-1.375a7.854 7.854 0 0 1-2.613-5.85v-5.071a.477.477 0 0 0-.477-.473h.004ZM25.514 19.159a.899.899 0 0 0 .902-.902V11.81a.902.902 0 1 0-1.805 0v6.446a.904.904 0 0 0 .903.903Z" />
    <path d="M25.514 19.59a1.332 1.332 0 0 1-1.332-1.332V11.81a1.332 1.332 0 1 1 2.664 0v6.447a1.332 1.332 0 0 1-1.332 1.332Zm0-8.265a.473.473 0 0 0-.473.473v6.447a.473.473 0 0 0 .946 0v-6.447a.472.472 0 0 0-.473-.473Z" />
  </svg>
);

export { TopicStretching };
