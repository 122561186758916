import { FunctionComponent } from "react";

const SearchIcon: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1 21.7056L6.73246 15.9731C3.52706 12.0529 4.10659 6.27639 8.02686 3.07099C11.9471 -0.134399 17.7236 0.445128 20.929 4.36544C24.1344 8.28575 23.5549 14.0622 19.6346 17.2676C16.2579 20.0285 11.4036 20.0285 8.0269 17.2676L2.2944 23L1 21.7056ZM13.8161 17.5074C17.8607 17.5074 21.1395 14.2286 21.1395 10.1839C21.1395 6.13928 17.8607 2.86045 13.8161 2.86045C9.77142 2.86045 6.49259 6.13928 6.49259 10.1839C6.49715 14.2267 9.77331 17.5029 13.8161 17.5074Z" />
  </svg>
);

export { SearchIcon };
