import React, { HTMLInputTypeAttribute, InputHTMLAttributes } from "react";
// Utils
import { classNames } from "@/utils/classNames";
import { useTheme } from "@/context/theme";
import { Label } from "../label";
import { ErrorMessage } from "../error-message";

type Props = {
  className?: string;
  modifier?: "dark" | "light";
  hasError?: boolean;
  error?: string;
  withBorder?: boolean;
} & InputHTMLAttributes<HTMLInputElement>;

const hasOverridenStyle = (type?: HTMLInputTypeAttribute) =>
  type === "checkbox";

const Input = React.forwardRef<HTMLInputElement | null, Props>((props, ref) => {
  const {
    className,
    modifier = "light",
    hasError,
    disabled,
    error = "",
    withBorder = false,
    ...restOfProps
  } = props;

  const theme = useTheme();

  const styles = {
    dark: modifier === "dark",
    light: modifier === "light",
    green: theme.state.color === "green",
    purple: theme.state.color === "purple",
    error: hasError,
  };

  return (
    <>
      <input
        className={classNames(
          "bg-[#F1F1F1] block w-full rounded-lg border border-transparent p-4 mt-3 text-[#454545] focus:!border-[#212421] active:!border-[#212421] focus:ring-0 focus:outline-none",
          withBorder
            ? "border-[#D3D3D3] focus:!border-[#D3D3D3] active:!border-[#D3D3D3] focus:ring-0 focus:outline-none"
            : "border-transparent focus:!border-transparent active:!border-transparent focus:ring-0 focus:outline-none",
          disabled ? "opacity-60" : "",
          styles.error ? "!border-[#E8495C] !border-2" : "",
          restOfProps.type === "checkbox"
            ? "appearance-none w-[24px] h-[24px] border rounded-full"
            : null,
          restOfProps.checked ? "bg-[#F00]" : null,
          className
        )}
        ref={ref}
        disabled={disabled}
        {...restOfProps}
      />
      {hasError && error && <ErrorMessage text={error} />}
    </>
  );
});

Input.displayName = "Input";

export { Input };
