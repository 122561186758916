import { FunctionComponent } from "react";

export const LivePeopleIcon: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Icon">
      <path
        id="Vector"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.80001 8.58105C6.80001 7.25557 7.87452 6.18105 9.20001 6.18105C10.5255 6.18105 11.6 7.25557 11.6 8.58105C11.6 9.90654 10.5255 10.9811 9.20001 10.9811C7.87452 10.9811 6.80001 9.90654 6.80001 8.58105ZM9.20001 4.58105C6.99087 4.58105 5.20001 6.37192 5.20001 8.58105C5.20001 10.7902 6.99087 12.5811 9.20001 12.5811C11.4091 12.5811 13.2 10.7902 13.2 8.58105C13.2 6.37192 11.4091 4.58105 9.20001 4.58105ZM6 14.181C4.93913 14.181 3.92172 14.6025 3.17157 15.3526C2.42143 16.1028 2 17.1202 2 18.181V19.781C2 20.2229 2.35817 20.581 2.8 20.581C3.24183 20.581 3.6 20.2229 3.6 19.781V18.181C3.6 17.5445 3.85286 16.9341 4.30294 16.484C4.75303 16.0339 5.36348 15.781 6 15.781H12.4C13.0365 15.781 13.647 16.0339 14.0971 16.484C14.5471 16.9341 14.8 17.5445 14.8 18.181V19.781C14.8 20.2229 15.1582 20.581 15.6 20.581C16.0418 20.581 16.4 20.2229 16.4 19.781V18.181C16.4 17.1202 15.9786 16.1028 15.2284 15.3526C14.4783 14.6025 13.4609 14.181 12.4 14.181H6ZM17.2258 14.8849C17.3362 14.4571 17.7726 14.1998 18.2004 14.3103C19.0585 14.5318 19.8188 15.0321 20.3618 15.7326C20.9047 16.433 21.1997 17.294 21.2004 18.1803V19.7809C21.2004 20.2227 20.8422 20.5809 20.4004 20.5809C19.9586 20.5809 19.6004 20.2227 19.6004 19.7809V18.1815C19.5999 17.6498 19.423 17.1331 19.0972 16.7128C18.7714 16.2926 18.3153 15.9924 17.8004 15.8595C17.3726 15.749 17.1153 15.3127 17.2258 14.8849ZM14.9984 4.71002C14.5704 4.60043 14.1346 4.85857 14.025 5.28659C13.9154 5.71461 14.1735 6.15043 14.6016 6.26002C15.1178 6.3922 15.5754 6.69244 15.9022 7.11341C16.2289 7.53437 16.4063 8.05212 16.4063 8.58502C16.4063 9.11792 16.2289 9.63567 15.9022 10.0566C15.5754 10.4776 15.1178 10.7778 14.6016 10.91C14.1735 11.0196 13.9154 11.4554 14.025 11.8835C14.1346 12.3115 14.5704 12.5696 14.9984 12.46C15.8588 12.2397 16.6215 11.7393 17.1661 11.0377C17.7107 10.3361 18.0063 9.47319 18.0063 8.58502C18.0063 7.69685 17.7107 6.83394 17.1661 6.13233C16.6215 5.43072 15.8588 4.93032 14.9984 4.71002Z"
        fill="#F5F9FF"
        fillOpacity="0.95"
      />
    </g>
  </svg>
);
