import { FunctionComponent } from "react";

const MagicWand: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="fi-rs-magic-wand" clipPath="url(#clip0_444_11297)">
      <path
        id="Vector"
        d="M9.88693 3.724L1.0996 12.5127C0.738545 12.8998 0.541893 13.412 0.55109 13.9413C0.560286 14.4706 0.774613 14.9757 1.1489 15.35C1.52318 15.7244 2.02819 15.9389 2.55749 15.9482C3.08679 15.9575 3.59903 15.761 3.98627 15.4L12.7756 6.61267L9.88693 3.724ZM10.8903 6.61267L9.16627 8.33667L8.16627 7.33333L9.89027 5.60933L10.8903 6.61267ZM3.0436 14.4587C2.90883 14.5871 2.72979 14.6588 2.5436 14.6588C2.35741 14.6588 2.17837 14.5871 2.0436 14.4587C1.91121 14.3259 1.83686 14.1461 1.83686 13.9587C1.83686 13.7712 1.91121 13.5914 2.0436 13.4587L7.22293 8.27867L8.22627 9.282L3.0436 14.4587ZM14.7216 9.752L16.4996 10.6413L14.7216 11.5333L13.8329 13.308L12.9443 11.5333L11.1663 10.6413L12.9443 9.752L13.8329 7.97467L14.7216 9.752ZM4.94427 3.55533L3.16627 2.66667L4.94427 1.778L5.83293 0L6.7216 1.778L8.4996 2.66667L6.7216 3.55533L5.83293 5.33333L4.94427 3.55533ZM13.3883 3.11133L11.8329 2.33333L13.3883 1.55533L14.1663 0L14.9443 1.55533L16.4996 2.33333L14.9443 3.11133L14.1663 4.66667L13.3883 3.11133Z"
        fill="#03BD93"
      />
    </g>
    <defs>
      <clipPath id="clip0_444_11297">
        <rect width="16" height="16" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);

export { MagicWand };
