import { FunctionComponent } from "react";

const CheckedInputIcon: FunctionComponent<IconProps> = ({
  className,
  bgColor = "#33878E",
  mainColor = "white",
  viewBox = "0 0 25 24",
}) => (
  <svg
    width="25"
    height="24"
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g id="Group 215">
      <ellipse
        id="Ellipse 140"
        cx="12.9971"
        cy="12"
        rx="12.0027"
        ry="12"
        fill={bgColor}
      />
      <path
        id="Vector 104"
        d="M9.00024 11.8643L12.0101 14.7284L18.0302 9"
        stroke={mainColor}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export { CheckedInputIcon };
