const VideoToolsService = {
  importFileandPreview(file: File, revoke?: boolean): Promise<string> {
    return new Promise((resolve, reject) => {
      window.URL = window.URL || window.webkitURL;
      let preview = window.URL.createObjectURL(file);
      // remove reference
      if (revoke) {
        window.URL.revokeObjectURL(preview);
      }
      setTimeout(() => {
        resolve(preview);
      }, 1000);
    });
  },

  getVideoCover(
    isDesktop: boolean,
    file: File,
    urlOfFIle: string,
    seekTo = 0.0
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      try {
        // load the file to a video player
        const videoPlayer = document.createElement("video");
        if (!isDesktop) {
          if (file) {
            const reader = new FileReader();
            reader.onload = function (event: ProgressEvent<FileReader>) {
              const target = event.target as FileReader;
              const dataUrl = target.result as string;
              videoPlayer.src = dataUrl;
              videoPlayer.load();
            };
            reader.readAsDataURL(file);
          }
        } else {
          // videoPlayer.setAttribute('src', URL.createObjectURL(urlOfFIle));
          videoPlayer.setAttribute("src", urlOfFIle);
          videoPlayer.crossOrigin = "Anonymous";
          videoPlayer.load();
        }
        videoPlayer.addEventListener("error", (ex) => {
          reject(`error when loading video file ${ex}`);
        });
        // load metadata of the video to get video duration and dimensions
        videoPlayer.addEventListener("loadedmetadata", () => {
          // seek to user defined timestamp (in seconds) if possible
          if (videoPlayer.duration < seekTo) {
            reject("video is too short.");
            return;
          }
          setTimeout(() => {
            videoPlayer.currentTime = seekTo;
          }, 200);
          videoPlayer.addEventListener("seeked", () => {
            const canvas = document.createElement("canvas");
            canvas.width = videoPlayer.videoWidth;
            canvas.height = videoPlayer.videoHeight;
            const ctx = canvas.getContext("2d");
            ctx!.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);
            ctx!.canvas.toBlob(
              (blob) => {
                var reader = new FileReader();
                reader.readAsDataURL(blob as Blob);
                reader.onloadend = function () {
                  var base64data = reader.result;
                  resolve(base64data as string);
                };
              },
              "image/webp",
              1 /* quality */
            );
          });
        });
      } catch (error) {
        reject(error);
      }
    });
  },

  generateThumbnail(
    isDesktop: boolean,
    file: File,
    videoTimeInSeconds: number
  ): Promise<File> {
    return new Promise((resolve, reject) => {
      if (file.type.match("video")) {
        this.importFileandPreview(file).then((urlOfFIle) => {
          this.getVideoCover(
            isDesktop,
            file,
            urlOfFIle,
            videoTimeInSeconds
          ).then((base64Data) => {
            const byteCharacters = atob(base64Data.split(",")[1]);
            const byteArrays = [];

            for (
              let offset = 0;
              offset < byteCharacters.length;
              offset += 512
            ) {
              const slice = byteCharacters.slice(offset, offset + 512);

              const byteNumbers = new Array(slice.length);
              for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
              }

              const byteArray = new Uint8Array(byteNumbers);
              byteArrays.push(byteArray);
            }

            const blob = new Blob(byteArrays, { type: "image/webp" });
            const thumbnailFile = new File([blob], "1234567.webp", {
              type: "image/webp",
            });

            resolve(thumbnailFile);
          });
        });
      } else {
        reject("File type not valid");
      }
    });
  },
};

export { VideoToolsService };
