import { FunctionComponent } from "react";

const LoadingSpinnerIcon: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    className={className}
    viewBox="0 0 27 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.1"
      d="M26.5 13C26.5 20.1797 20.6797 26 13.5 26C6.3203 26 0.5 20.1797 0.5 13C0.5 5.8203 6.3203 0 13.5 0C20.6797 0 26.5 5.8203 26.5 13ZM4.4 13C4.4 18.0258 8.47421 22.1 13.5 22.1C18.5258 22.1 22.6 18.0258 22.6 13C22.6 7.97421 18.5258 3.9 13.5 3.9C8.47421 3.9 4.4 7.97421 4.4 13Z"
    />
    <path d="M26.5 13C26.5 11.2928 26.1637 9.60235 25.5104 8.02512C24.8571 6.44788 23.8995 5.01477 22.6924 3.80761C21.4852 2.60045 20.0521 1.64288 18.4749 0.989566C16.8977 0.336255 15.2072 -7.46234e-08 13.5 0L13.5 3.9C14.695 3.9 15.8784 4.13538 16.9824 4.5927C18.0865 5.05001 19.0897 5.72032 19.9347 6.56533C20.7797 7.41034 21.45 8.41352 21.9073 9.51758C22.3646 10.6216 22.6 11.805 22.6 13H26.5Z" />
  </svg>
);

export { LoadingSpinnerIcon };
