import { FunctionComponent } from "react";

export const AlertYellowIcon: FunctionComponent<IconProps> = ({
  className,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    className={className}
  >
    <circle
      cx="12"
      cy="12"
      r="11.984"
      fill="#D9B24D"
      fillOpacity="0.2"
      transform="rotate(-89.923 12 12)"
    ></circle>
    <path
      fill="#D9B24D"
      d="M12.027 13.971a.873.873 0 00.869-.899V7.26c0-.24-.09-.444-.27-.614a.888.888 0 00-.629-.254.873.873 0 00-.644.254.873.873 0 00-.255.644v5.813c0 .24.09.444.27.614.18.17.4.254.659.254zm-.12 3.785c.28 0 .514-.095.704-.284a.957.957 0 00.285-.704.956.956 0 00-.285-.704.956.956 0 00-.704-.285.956.956 0 00-.704.285.956.956 0 00-.284.704c0 .28.094.514.284.704.19.19.425.284.704.284z"
    ></path>
  </svg>
);
