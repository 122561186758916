import { FunctionComponent } from "react";

import { classNames } from "@/utils/classNames";
import { GetIcon } from "@/components/common/icon";
import { Label } from "@/components/common/label";
import { ModalProps } from ".";

const DesktopModal: FunctionComponent<ModalProps> = ({
  children,
  onClose,
  withoutClose = false,
  showModal = false,
  title = "",
  description,
  longDescription,
  modifier = "light",
  className,
  containerClassName,
  fullWidth = false,
  bodyColor = "white",
}) => {
  const styles = {
    dark: modifier === "dark",
    light: modifier === "light",
    black: modifier === "black",
    kumu: modifier === "kumu",
    profile: modifier === "profile",
  };

  return (
    <div
      className={classNames(
        "relative z-50",
        showModal ? "relative" : null,
        !showModal ? "hidden" : null
      )}
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      {showModal ? (
        <div
          className={classNames(
            styles.light || styles.kumu || styles.profile
              ? "bg-gray-500 bg-opacity-75"
              : styles.dark
              ? "bg-[#043346] bg-opacity-80"
              : styles.black
              ? "bg-black bg-opacity-90"
              : null,

            "fixed inset-0 "
          )}
        >
          <div className="fixed z-50 inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center sm:p-0">
              <div
                className={classNames(
                  styles.black ? "" : "shadow-xl",
                  "bg-white relative rounded-[20px] text-left overflow-hidden  transform transition-all sm:my-8 w-full",
                  !fullWidth ? "sm:max-w-lg" : "",
                  containerClassName
                )}
              >
                <div
                  className={classNames(
                    styles.profile ? "pt-0" : " pt-5",
                    styles.dark ? className : null,
                    styles.black ? "bg-black bg-opacity-0" : null,
                    !styles.kumu && !styles.profile ? "px-4 pb-4" : null
                  )}
                >
                  <header
                    className={classNames(
                      title ? "justify-between w-full" : null,
                      !title ? "justify-end w-full" : null,
                      "flex items-center"
                    )}
                  >
                    {title && typeof title === "string" ? (
                      <div
                        className={classNames(
                          withoutClose ? "w-full relative top-4" : null,
                          ""
                        )}
                      >
                        {styles.kumu ? (
                          <div className="px-4">
                            <p className="text-[26px] text-graphite font-bold leading-[38px] relative">
                              {title}
                            </p>
                            {description && typeof description === "string" ? (
                              <p className="text-graphite text-base font-bold leading-[22px] mt-2">
                                {description}
                              </p>
                            ) : null}
                            {longDescription &&
                            typeof longDescription === "string" ? (
                              <p className="text-graphite text-[14px] leading-[22px] mt-2">
                                {longDescription}
                              </p>
                            ) : null}
                          </div>
                        ) : (
                          <Label
                            fontSize="xl"
                            className={classNames(
                              styles.black ? "text-transparent" : null
                            )}
                            textAlignment="left"
                            textColor={
                              styles.dark
                                ? "white"
                                : styles.black
                                ? "transparent"
                                : ""
                            }
                          >
                            {title}
                          </Label>
                        )}
                      </div>
                    ) : null}
                    {title && typeof title !== "string" ? <>{title}</> : null}
                    {!withoutClose && (
                      <button
                        id="close-btn"
                        onClick={onClose}
                        className={classNames(
                          styles.kumu || styles.profile
                            ? "rounded-[6px] bg-black"
                            : "rounded-full border-[1px] border-solid border-[#B4B7C5]",
                          " p-2.5 absolute right-4 top-4",
                          styles.profile && "mt-4"
                        )}
                      >
                        <GetIcon
                          iconName="close"
                          className={classNames(
                            styles.kumu || styles.profile
                              ? "text-white"
                              : "text-[#B4B7C5]",
                            "fill-current h-2.5 w-2.5 stroke-current stroke-1"
                          )}
                        />
                      </button>
                    )}
                  </header>
                  {styles.kumu ? (
                    <hr className="mt-10 h-[1px] bg-graphite w-full" />
                  ) : null}
                  <div
                    className={classNames(
                      "flex w-full",
                      styles.kumu ? "p-4 bg-[#F1F1F1]" : null
                    )}
                  >
                    <div
                      className={classNames(
                        styles.black ? "bg-black bg-opacity-100" : null,
                        !styles.kumu && !styles.profile
                          ? "mt-4 pb-4 w-full"
                          : null,
                        styles.kumu ? " rounded-[8px] w-full" : null,
                        bodyColor === "white" ? "bg-white" : null,
                        bodyColor === "transparent" ? "bg-transparent" : null
                      )}
                    >
                      {children}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export { DesktopModal };
