import { FunctionComponent, ReactNode } from "react";

import useMediaQuery, { desktop } from "@/utils/hooks/useMediaQuery";
import { MobileModal } from "./mobile";
import { DesktopModal } from "./desktop";
import { classNames } from "@/utils/classNames";

export type ModalProps = {
  className?: string;
  containerClassName?: string;
  showModal: boolean;
  onClose: () => void;
  title?: string | ReactNode;
  description?: string;
  longDescription?: string;
  modifier?: "dark" | "light" | "black" | "kumu" | "profile";
  bodyColor?: "white" | "transparent";
  withoutClose?: boolean;
  isWarning?: boolean;
  children: React.ReactNode;
  fullWidth?: boolean;
};

const Modal: FunctionComponent<ModalProps> = (props) => {
  const isDesktop = useMediaQuery(desktop);
  const Component = isDesktop || props.isWarning ? DesktopModal : MobileModal;
  return <Component {...props}></Component>;
};

export { Modal };
