import { FunctionComponent } from "react";

const WatchIcon: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    width="10"
    height="11"
    viewBox="0 0 10 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g id="Reloj">
      <path
        id="Vector (Stroke)"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 1.40909C2.74065 1.40909 0.909091 3.24065 0.909091 5.5C0.909091 7.75935 2.74065 9.59091 5 9.59091C7.25935 9.59091 9.09091 7.75935 9.09091 5.5C9.09091 3.24065 7.25935 1.40909 5 1.40909ZM0 5.5C0 2.73858 2.23858 0.5 5 0.5C7.76142 0.5 10 2.73858 10 5.5C10 8.26142 7.76142 10.5 5 10.5C2.23858 10.5 0 8.26142 0 5.5Z"
        fill="white"
      />
      <path
        id="Vector (Stroke)_2"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.99847 2.31836C5.2495 2.31836 5.453 2.52186 5.453 2.77289V5.21919L7.01986 6.00263C7.24439 6.11489 7.3354 6.38792 7.22313 6.61245C7.11087 6.83698 6.83785 6.92799 6.61332 6.81573L4.7952 5.90666C4.64122 5.82966 4.54395 5.67227 4.54395 5.50011V2.77289C4.54395 2.52186 4.74744 2.31836 4.99847 2.31836Z"
        fill="white"
      />
    </g>
  </svg>
);

export { WatchIcon };
