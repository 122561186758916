export const SIXTY_URLS = {
  // Reserved for api exposed to the browser urls -> clientFetchService
  API: {
    COURSES: "/api/courses",
    COURSES_BY_ID: (courseId: string) => `/api/courses/${courseId}`,
    COURSE_RATING_BY_ID: (courseId: string) =>
      `/api/courses/${courseId}/rating`,
    COURSE_DELETE: (courseId: string) => `/api/courses/${courseId}/delete`,
    LIVE_DELETE: "/api/lives/delete",
    LIVES: "/api/lives",
    LIVE_STATUS: "/api/lives/status",
    LIVE_STATUS_BY_ID: (liveId: string) => `/api/lives/status/${liveId}`,
    COURSES_UPLOAD_THUMBNAIL: "/api/courses/upload-thumbnail",
    DASHBOARD: {
      SHOP: {
        ORDERS: "/api/dashboard/shop/orders",
      },
    },
    SHOP: {
      DASHBOARD: {
        ORDERS: "/api/shop/dashboard/orders",
      },
      ITEMS: "/api/shop/items",
      LIKE_ITEM: "/api/shop/itemLike",
      ADDRESSES: `/api/shop/addresses`,
      ORDERS: {
        CLAIM: "/api/shop/orders/claim",
      },
      CART: {
        ADD_ITEM_TO_CART: "/api/shop/cart",
        REMOVE_ITEM_FROM_CART: "/api/shop/cart",
      },
      CHECKOUT: {
        START_CHECKOUT: "/api/shop/checkout",
      },
    },
    USERS: "/api/users",
    USERS2: {
      TOPICS: "/api/users/topics",
    },
    AUTH: {
      LOG_IN: "/api/auth/login",
      SOCIAL: "/api/auth/social",
      SOCIAL_CREATOR: "/api/auth/social-creator",
      INIT_RESTORE: "/api/auth/init-restore",
      COMPLETE_RESTORE: "/api/auth/complete-restore",
    },
    CHAT: {
      CREATE: "/api/chat",
      MESSAGE: "/api/chat/message",
      SEND_MESSAGE: "/api/chat/sendmessage",
      BY_ID: (chatId: string) => `/api/chat/${chatId}`,
      CHATTABLE_USERS: "/api/chat/chattable-users",
      GET_UNREAD_CHATS: "/api/chat/unread-chats",
    },
    VIMEO: {
      CREATE_URI: "/api/vimeo/create-video",
      CREATE_URI_FROM_URL: "/api/vimeo/create-video-from-url",
      DELETE_URI: "/api/vimeo/delete-video",
    },
    CHECKOUT: {
      SEND_CREDITS: "/api/credits",
      ADD_CREDITS: "/api/checkout",
    },
    RECENT_SEARCH: {
      MAIN: "/api/search/recent-searches",
      GET_BY_ID: (id: string) => `/api/search/recent-searches/${id}`,
    },
    CONTENT: {
      NEW_CONTENT: "/api/content",
    },
    VIEWS: {
      ADD_COUNT: "/api/views",
    },
    SUBSCRIPTION: {
      CANCEL_SUBSCRIPTION: (creatorId: String) => `/api/checkout/${creatorId}`,
    },
    FILESYSTEM: "/api/filesystem",
    FILES: {
      DELETE: (file_id: string) => `/api/filesystem/file/${file_id}`,
    },
  },
  // Reserved for BE urls -> serverFetchService
  RESOURCES: {
    COURSE_BY_ID: (courseId: string) => `/courses/${courseId}`,
    COURSE_ANALYTICS_BY_ID: (courseId: string) =>
      `/courses/${courseId}/analytics`,
    COURSE_RATING_BY_ID: (courseId: string) => `/courses/${courseId}/rating`,
    COURSE_DELETE: (courseId: string) => `/courses/${courseId}`,
    COURSES: "/courses",
    CHECKOUT: {
      SEND_CREDITS: "/credits",
      CREATE_PENDING_TRANSACTION: "/credits/pending-transaction",
    },
    DASHBOARD: {
      LIVES: "/lives",
      LIVE_STATUS: "/lives/status",
    },
    VIMEO: {
      CREATE: "/vimeo/create-video",
      CREATE_FROM_URL: "/vimeo/create-video-from-url",
      DELETE: "/vimeo/delete-video",
    },

    CONTENT: {
      NEW_CONTENT: "/content/",
    },
    ADDVIEW: {
      ADDCOUNT: (id: string) => `/courses/addView/${id}`,
    },
    SHOP: {
      DASHBOARD: {
        MAIN: "/shop/dashboard",
        ORDERS: "/shop/dashboard/orders",
      },
      ITEMS: {
        GET_BY_ROUTED_TITLE: (routedTitle: string) =>
          `/shop/items/${routedTitle}`,
        MAIN: "/shop/items",
        ITEMS_BY_ID: (itemId: string) => `/shop/items/${itemId}`,
        GET_ITEM_LIKED_BY_ID: (itemID: string) =>
          `/shop/items/like/get/${itemID}`,
        GET_ITEM_LIKED_BY_USER: () => `/shop/items/like/get`,
      },
      COLLECTIONS: {
        GET_BY_SLUG: (slug: string) => `/shop/collections/${slug}`,
      },
      CART: {
        GET_CART: "/shop/carts",
        ADD_TO_CART: "/shop/carts/items",
        REMOVE_FROM_CART: "/shop/carts/items",
        EMPTY_CART: "/shop/carts",
      },
      CHECKOUT: {
        START_CHECKOUT: "/shop/checkout",
      },
      ORDERS: {
        MAIN: "/shop/orders",
        SENT_ORDER_BY_ID: (orderId: string) =>
          `/shop/orders/${orderId}/sent-order`,
      },
    },
    STRIPE: {
      CREATE_AND_CONNECT: "/stripe/create-and-connect-stripe-account",
      CONNECT: "/stripe/connect-stripe-account",
      AUTH: "/stripe/auth-stripe-account",
      CREATE_CHECKOUT_SESSION: "/stripe/create-checkout-session",
    },
  },
  // Reserved for intra links in the browser
  MAIN: "/",
  CATEGORIES: "/categories",
  SEARCH_BY_TOPICID: (topicId: string) =>
    `/search?query=${topicId}&topicId=${topicId}`,
  SEARCH_BY_CATEGORY: (listId: string) => `/list/${listId}`,
  SEARCH_BY_QUERY: (query: string) => `/search?query=${query}`,
  SEARCH_FOR_KEYWORDS: (keywords: string) => `/search/${keywords}`,
  GET_RECENT_SEARCHES: "/recent-searches",
  RECENT_SEARCH_BY_ID: (id: string) => `/recent-searches/${id}`,
  REMOVE_ALL_RECENT_SEARCHES: "/recent-searches",
  SEARCH_BY_QUERY_AND_TOPICID: (query: string, topicId: string) =>
    `/search?query=${query}&topicId=${topicId}`,
  API_SEARCH_BY_QUERY_AND_TOPICID: (query: string) => `/api/search/${query}`,
  CHAT: "/chat",
  CHAT_BY_ID: (chatId: string) => `/chat/${chatId}`,
  CHAT_CREATOR_BY_ID: (chatId: string) => `/dashboard/chat/${chatId}`,
  EXPLORE: "/explore",
  MY_LIBRARY: "/my-library",
  ALL_LIVES: "/all-lives",
  PROFILE: "/profile",
  PROFILE_API: {
    ADDRESS: "/api/shop/addresses",
  },
  PROFILE_SETTINGS: {
    MY_PROFILE: "/profile/my",
    MY_ADDRESSES: "/profile/addresses",
    MY_ORDERS: "/profile/orders",
    ORDER_DETAIL: "/profile/orders/",
    NEW_ADDRESS: "/profile/addresses/new",
    EDIT_ADDRESS: "/profile/addresses/edit",
    LIKED_ITEMS: "/liked-products",
    CATALOG_BY_CREATOR: (username: string) => `/creators/${username}/catalog`,
  },
  CREATORS: "/creators",
  FAQS: "/faqs",
  ABOUT: "/about",
  CONTACT: "/contact",
  BECOME_CREATOR: "/become-creator",
  TERMS_AND_CONDITIONS: "/terms-and-conditions",
  COOKIE_POLICY: "/cookie-policy",
  COURSE_BY_ID: (courseId: string) => `/courses/${courseId}`,
  COURSES_UPLOAD_THUMBNAIL: "/courses/upload-thumbnail",
  CREATOR_BY_USERNAME: (creatorId: string) => `/creators/${creatorId}`,
  SHOP: {
    MAIN: "/shop",
    PRODUCT_BY_ROUTED_TITLE: (routedTitle: string) =>
      `/shop/products/${routedTitle}`,
    CART: "/shop/cart",
    CHECKOUT: "/shop/checkout",
    SEARCH_BY_DEPARTMENT: (departmentLabel: string) =>
      `shop/item-departments/${departmentLabel}`,
    SEARCH_BY_COLLECTION: (collectionLabel: string) =>
      `shop/collections/${collectionLabel}`,
  },
  FLOW: {
    BUSINESSES_LOGIN: "/flow/businesses/login",
    ONBOARDING: "/flow/onboarding",
    PROFILER: "/flow/onboarding/profiler",
    SIGN_UP: "/flow/signup",
    CREATE_ACCOUNT: "/flow/signup/create-account",
    CONFIRM: "/flow/confirm",
    JOIN_US: "/flow/join-us",
    LOG_IN: "/flow/login",
    SOCIAL_LOG_IN: "/flow/social-login",
    RESTORE: "/flow/restore",
    COMPLETE_RESTORE: "/flow/complete-restore",
    CHECK_EMAIL: "/flow/check-email",
  },
  CREATOR_FLOW: {
    LOG_IN: "/creator-flow/login",
    RESTORE: "/creator-flow/restore",
    CHECK_EMAIL: "/creator-flow/check-email",
    COMPLETE_RESTORE: "/creator-flow/complete-restore",
    SIGN_UP: "/creator-flow/signup",
    CONFIRM: "/creator-flow/confirm",
    ONBOARDING: "/creator-flow/onboarding",
    COMPLETE_PROFILE: "/creator-flow/complete-profile",
    EDIT_PROFILE: "/creator-flow/edit-profile",
    PENDING_APPROVAL: "/creator-flow/pending-approval",
  },
  LIVES: {
    PREVIEW_BY_ID: (liveId: string) => `/lives/${liveId}`,
    PLAY_BY_ID: (liveId: string) => `/lives/${liveId}/play`,
    END_USER_LIVE: (liveId: string) => `/lives/streaming/${liveId}`,
    CREATOR_LIVE: (liveId: string) =>
      `/dashboard/content/live/streaming/${liveId}`,
    LIVE_STATUS: (liveId: string) => `/lives/${liveId}/status`,
  },
  // Back Office Links
  OFFICE: {
    HOME: "/office",
    LIVES: "/office/lives",
    COURSES: "/office/courses",
  },
  // Dashboard links
  DASHBOARD: {
    HOME: "/dashboard",
    WALLET: "/dashboard/wallet",
    CONTENT: "/dashboard/content",
    SHOP: {
      MAIN: "/dashboard/shop",
      PRODUCTS: {
        MAIN: "/dashboard/shop/products",
        PRODUCT_STATISTICS_BY_ID: (itemId: string) =>
          `/dashboard/shop/products/${itemId}`,
        NEW: "/dashboard/shop/products/new",
      },
      ORDERS: {
        MAIN: "/dashboard/shop/orders",
        ORDER_BY_ID: (id: string) => `/dashboard/shop/orders/${id}`,
      },
    },
    ALL_POSTS: "/dashboard/content/courses",
    CREATED_POST: "/dashboard/content/courses?createdCourse=true",
    NEW: "/dashboard/content/new",
    LIVE_PREVIEW: (liveId: string) => `/dashboard/content/live/${liveId}`,
    LIVE_ANALYTICS: (liveId: string) =>
      `/dashboard/content/live/analytics/${liveId}`,
    CREATED_LIVE: (liveId: string) =>
      `/dashboard/content/created-live/${liveId}`,
    EDITED_LIVE: (liveId: string) => `/dashboard/content/edited-live/${liveId}`,
    EDIT_LIVE: (liveId: string) => `/dashboard/content/edit-live/${liveId}`,
    CONTENT_BY_ID: (courseId: string) => `/dashboard/content/${courseId}`,
    CONTENT_VIEW_ALL: (orderBy: string, userTier: string) =>
      `/dashboard/content?orderBy=${orderBy}&userTier=${userTier}`,
    NEW_POST: {
      MAIN: "/dashboard/content/new-post",
      SUCCESS: "/dashboard/content/new-post/success",
    },
    INSIGHTS: "/dashboard/insights",
    CHAT: "/dashboard/chat",
    NOTIFICATIONS: "/dashboard/notifications",
    PROFILE: {
      MAIN: "/dashboard/profile",
      EDIT: "/dashboard/profile/edit",
      API_UPLOAD_COVER_BG: "/api/creators/cover-background",
      API_UPLOAD_PROFILE_PICTURE: "/api/creators/profile-picture",
      BACKEND_UPLOAD_COVER_BG: "/creators/cover-background",
      BACKEND_UPLOAD_PROFILE_PICTURE: "/creators/profile-picture",
      SETTINGS: "/dashboard/profile/settings",
    },
  },

  // extras
  404: "/404",
  CHECKOUT: {
    MAIN: "/checkout",
    STRIPE_STATUS: (status: string, creatorName: string) =>
      `/checkout/${creatorName}?status=${status}`,
  },
  FILESYSTEM: {
    ADD_FILE: "/filesystem/file",
    UPDATE_FILE_STATUS: "/filesystem/filestatus",
  },
};
