import { FunctionComponent } from "react";

export const KumuIconDesktop: FunctionComponent<IconProps> = ({
  className,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={115}
    height={25}
    fill="none"
    className={className}
  >
    <g clipPath="url(#a)">
      <g clipPath="url(#b)">
        <path
          fill="#202421"
          d="M113.996 0h-8.611c-.44 0-.843.238-1.049.623L103.08 3.72c-.309.751-1.396.751-1.705 0L100.12.623A1.176 1.176 0 0 0 99.07 0h-8.61a1.03 1.03 0 0 0-1.03 1.017v14.569c0 5.214 4.319 9.447 9.66 9.447h6.295c5.331 0 9.66-4.224 9.66-9.447V1.017A1.04 1.04 0 0 0 113.996 0Z"
        />
      </g>
      <g clipPath="url(#c)">
        <path
          fill="#202421"
          d="M84.129 0h-2.7c-.384 0-.758.128-1.058.367l-7.077 5.985c-.272.22-.609.33-.937.321-.328 0-.665-.11-.937-.32L64.344.366A1.704 1.704 0 0 0 63.284 0h-2.699c-.581 0-1.04.458-1.04 1.017v22.998c0 .56.468 1.018 1.04 1.018h5.446c.571 0 1.04-.458 1.04-1.018l.01-2.383c0-.852 1.105-1.219 1.64-.55l2.558 3.328c.319.375.544.623 1.05.623h.047c.506 0 .73-.248 1.05-.623l2.558-3.328c.535-.669 1.631-.302 1.64.55l.01 2.383c0 .56.468 1.018 1.04 1.018h5.446c.58 0 1.04-.458 1.04-1.018V1.017C85.17.458 84.71 0 84.129 0Z"
        />
      </g>
      <g clipPath="url(#d)">
        <path
          fill="#202421"
          d="M54.123 0h-8.614c-.44 0-.843.238-1.05.623L43.204 3.72c-.31.751-1.397.751-1.706 0L40.25.623A1.176 1.176 0 0 0 39.201 0h-8.613c-.572 0-1.04.458-1.04 1.017v14.569c0 5.214 4.32 9.447 9.663 9.447h6.298c5.333 0 9.654-4.224 9.654-9.447V1.017c0-.559-.46-1.017-1.04-1.017Z"
        />
      </g>
      <g clipPath="url(#e)">
        <path
          fill="#202421"
          d="M24.575 0h-8.107c-.46 0-.9.192-1.21.54l-3.055 3.447c-.553.633-1.612.257-1.612-.577V1.027C10.591.458 10.123 0 9.541 0h-8.5C.468 0 0 .458 0 1.017v22.99c0 .558.469 1.017 1.04 1.017h8.501c.582 0 1.04-.459 1.04-1.018v-2.374c0-.834 1.06-1.219 1.613-.586l3.055 3.446c.3.34.74.54 1.21.54h8.107c.572 0 1.04-.457 1.04-1.017v-2.2c0-.375-.14-.742-.394-1.026l-6.092-7.214a1.624 1.624 0 0 1-.01-2.044l6.112-7.305c.253-.285.393-.651.393-1.027V1.017c0-.559-.459-1.017-1.04-1.017Z"
        />
      </g>
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h115v25H0z" />
      </clipPath>
      <clipPath id="b">
        <path fill="#fff" d="M89.43 0h25.615v25.033H89.43z" />
      </clipPath>
      <clipPath id="c">
        <path fill="#fff" d="M59.545 0H85.16v25.033H59.545z" />
      </clipPath>
      <clipPath id="d">
        <path fill="#fff" d="M29.548 0h25.616v25.033H29.548z" />
      </clipPath>
      <clipPath id="e">
        <path fill="#fff" d="M0 0h25.616v25.033H0z" />
      </clipPath>
    </defs>
  </svg>
);
