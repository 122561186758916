import { FunctionComponent } from "react";

const MyLibraryIcon: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    width={16}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path d="M2.363 1.429A2.572 2.572 0 0 1 4.667 0h6.62a4.429 4.429 0 0 1 4.428 4.429v10.524a2.572 2.572 0 0 1-1.429 2.305V4.428a3 3 0 0 0-3-3H2.363Zm.209 1.428A2.572 2.572 0 0 0 0 5.43v13.857a.714.714 0 0 0 1.034.638l5.395-2.695 5.396 2.696a.715.715 0 0 0 1.033-.639V5.43a2.571 2.571 0 0 0-2.572-2.572H2.572ZM1.429 5.43a1.143 1.143 0 0 1 1.143-1.143h7.714a1.143 1.143 0 0 1 1.143 1.143v12.7l-4.68-2.338a.714.714 0 0 0-.64 0l-4.68 2.338V5.43Z" />
  </svg>
);

export { MyLibraryIcon };
