import useTranslation from "next-translate/useTranslation";
import { Label } from "@/components/common/label";
import { FunctionComponent, useContext, useState } from "react";
import { Button } from "@/components/common/button";
import { Input } from "@/components/common/input";

type DonateProps = {
  handleClick: (amount?: number) => void;
  price: string;
};

export const DonateContent: FunctionComponent<DonateProps> = ({
  handleClick,
  price,
}) => {
  const { t } = useTranslation();
  const [amount, setAmount] = useState(price);

  const handleDonateClick = () => {
    handleClick(Number(amount));
  };

  return (
    <>
      <div className="flex justify-center items-center gap-x-2.5 mt-4">
        <Label
          textColor="green-dark"
          fontWeight="medium"
          textAlignment="center"
        >
          {t("common:common.donate")}:
        </Label>
        <Input
          className="w-[90px] h-[44px] border-[1px] border-solid border-[#33878E]"
          aria-label="amount"
          name="amount"
          type="number"
          required
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
        />
      </div>
      <Button
        disabled={Number(amount) <= 0}
        modifier="primary"
        className="mt-[26px]"
        onClick={() => handleDonateClick()}
      >
        {t("common:checkout.btnPay")}
      </Button>
    </>
  );
};
