import { FunctionComponent } from "react";

export const PlusTenIcon: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="41"
    height="46"
    viewBox="0 0 41 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.1909 32.9604V22.8964H13.8789V21.6004H16.6149V32.9604H15.1909ZM20.4435 22.3844C20.7422 22.0431 21.1262 21.7711 21.5955 21.5684C22.0755 21.3658 22.6675 21.2644 23.3715 21.2644C24.0862 21.2644 24.7102 21.4138 25.2435 21.7124C25.7875 22.0004 26.2035 22.4218 26.4915 22.9764C27.0142 24.0004 27.2755 25.3764 27.2755 27.1044C27.2755 29.3231 26.8808 30.8964 26.0915 31.8244C25.4408 32.5711 24.4968 32.9444 23.2595 32.9444C20.9875 32.9444 19.6808 31.6164 19.3395 28.9604C19.2755 28.4058 19.2435 27.7871 19.2435 27.1044C19.2435 26.4111 19.2755 25.7924 19.3395 25.2484C19.4142 24.6938 19.5422 24.1604 19.7235 23.6484C19.9155 23.1364 20.1555 22.7151 20.4435 22.3844ZM25.4355 23.9844C25.2755 23.5151 25.0088 23.1631 24.6355 22.9284C24.2728 22.6831 23.8408 22.5604 23.3395 22.5604C22.8382 22.5604 22.4222 22.6458 22.0915 22.8164C21.7715 22.9871 21.5208 23.2058 21.3395 23.4724C21.1582 23.7391 21.0142 24.0858 20.9075 24.5124C20.8008 24.9284 20.7315 25.3338 20.6995 25.7284C20.6782 26.1124 20.6675 26.5711 20.6675 27.1044C20.6675 27.6378 20.6782 28.1018 20.6995 28.4964C20.7315 28.8804 20.8008 29.2858 20.9075 29.7124C21.0142 30.1284 21.1582 30.4698 21.3395 30.7364C21.5208 31.0031 21.7715 31.2218 22.0915 31.3924C22.4222 31.5631 22.8115 31.6484 23.2595 31.6484C23.7075 31.6484 24.0915 31.5631 24.4115 31.3924C24.7422 31.2218 24.9982 31.0031 25.1795 30.7364C25.3715 30.4698 25.5155 30.1231 25.6115 29.6964C25.7822 29.0138 25.8675 28.2404 25.8675 27.3764C25.8675 26.5124 25.8355 25.8458 25.7715 25.3764C25.7182 24.9071 25.6062 24.4431 25.4355 23.9844Z"
      fill="white"
    />
    <path
      d="M25.1743 8.81347C25.4796 9.2737 25.354 9.89429 24.8938 10.1996L17.394 15.1748C16.9337 15.4801 16.3131 15.3545 16.0078 14.8943C15.7025 14.434 15.8281 13.8134 16.2884 13.5081L22.9549 9.08576L18.5325 2.41924C18.2272 1.95901 18.3528 1.33843 18.813 1.03312C19.2732 0.72782 19.8938 0.853411 20.1991 1.31364L25.1743 8.81347ZM13.6986 10.668L13.2699 9.76454L13.6986 10.668ZM4.21479 28.6677L3.22722 28.8249L3.22722 28.8249L4.21479 28.6677ZM9.15856 38.1816L9.85482 37.4638L9.15856 38.1816ZM29.3391 40.7665L28.8462 39.8964L29.3391 40.7665ZM36.5212 32.8059L37.4372 33.2069L36.5212 32.8059ZM36.5246 22.3569C36.3986 21.8192 36.7324 21.2812 37.2701 21.1552C37.8079 21.0292 38.3459 21.363 38.4719 21.9007L36.5246 22.3569ZM24.1426 10.3464C20.7596 9.66175 17.2457 10.0915 14.1274 11.5714L13.2699 9.76454C16.7787 8.09936 20.7326 7.61575 24.5394 8.38614L24.1426 10.3464ZM14.1274 11.5714C11.009 13.0513 8.45407 15.5015 6.8451 18.5553L5.07568 17.623C6.88613 14.1868 9.76107 11.4297 13.2699 9.76454L14.1274 11.5714ZM6.8451 18.5553C5.23613 21.609 4.65977 25.1018 5.20235 28.5105L3.22722 28.8249C2.61669 24.9893 3.26522 21.0591 5.07568 17.623L6.8451 18.5553ZM5.20235 28.5105C5.74494 31.9193 7.37725 35.0605 9.85482 37.4638L8.46231 38.8994C5.67447 36.1952 3.83775 32.6605 3.22722 28.8249L5.20235 28.5105ZM9.85482 37.4638C12.3324 39.867 15.5219 41.4029 18.9456 41.8415L18.6915 43.8253C14.839 43.3318 11.2501 41.6036 8.46231 38.8994L9.85482 37.4638ZM18.9456 41.8415C22.3693 42.28 25.8429 41.5976 28.8462 39.8964L29.8319 41.6366C26.4525 43.5509 22.5439 44.3187 18.6915 43.8253L18.9456 41.8415ZM28.8462 39.8964C31.8495 38.1952 34.2209 35.5668 35.6051 32.4049L37.4372 33.2069C35.8797 36.7648 33.2114 39.7224 29.8319 41.6366L28.8462 39.8964ZM35.6051 32.4049C36.9893 29.2429 37.312 25.7176 36.5246 22.3569L38.4719 21.9007C39.3578 25.6822 38.9948 29.649 37.4372 33.2069L35.6051 32.4049Z"
      fill="white"
    />
  </svg>
);
