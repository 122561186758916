import { FunctionComponent } from "react";

const SwitchIcon: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    className={className}
  >
    <g fill="currentColor">
      <path d="M8 1.333a6.687 6.687 0 0 1 4.748 2H10v1.334h3.429a1.24 1.24 0 0 0 1.238-1.238V0h-1.334v2.052A7.988 7.988 0 0 0 0 8h1.333A6.674 6.674 0 0 1 8 1.333ZM14.666 8a6.657 6.657 0 0 1-11.414 4.667H6v-1.334H2.571a1.24 1.24 0 0 0-1.238 1.238V16h1.334v-2.052A7.99 7.99 0 0 0 16 8h-1.334Z" />
    </g>
  </svg>
);

export { SwitchIcon };
