import { FunctionComponent } from "react";

export const PauseIcon: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="36"
    height="40"
    viewBox="0 0 36 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.66667 0C8.43478 0 10.1305 0.702379 11.3807 1.95262C12.631 3.20286 13.3333 4.89856 13.3333 6.66667V33.3333C13.3333 35.1014 12.631 36.7971 11.3807 38.0474C10.1305 39.2976 8.43478 40 6.66667 40C4.89856 40 3.20286 39.2976 1.95262 38.0474C0.702378 36.7971 3.72601e-08 35.1014 0 33.3333V6.66667C0 4.89856 0.702378 3.20286 1.95262 1.95262C3.20286 0.702379 4.89856 0 6.66667 0ZM28.8889 0C30.657 0 32.3527 0.702379 33.6029 1.95262C34.8532 3.20286 35.5556 4.89856 35.5556 6.66667V33.3333C35.5556 35.1014 34.8532 36.7971 33.6029 38.0474C32.3527 39.2976 30.657 40 28.8889 40C27.1208 40 25.4251 39.2976 24.1748 38.0474C22.9246 36.7971 22.2222 35.1014 22.2222 33.3333V6.66667C22.2222 4.89856 22.9246 3.20286 24.1748 1.95262C25.4251 0.702379 27.1208 0 28.8889 0Z"
      fill="white"
    />
  </svg>
);
