import { FunctionComponent } from "react";

const BalanceIcon: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.761 12.2084C18.4991 11.0509 18.8901 9.70613 18.8877 8.33335C18.8877 4.34445 15.6543 1.11111 11.6654 1.11111C10.2393 1.11111 8.90987 1.52445 7.79042 2.23723C7.04542 2.27901 6.30803 2.40919 5.59375 2.625C6.13669 2.04781 6.75882 1.55063 7.44153 1.14834C8.71315 0.399034 10.1617 0.00261922 11.6377 0H11.6654C16.2677 0 19.9988 3.73112 19.9988 8.33335C19.9988 8.40835 19.9977 8.48279 19.996 8.55724L19.9949 8.5839C19.9552 9.98376 19.5614 11.3507 18.8504 12.5572C18.4482 13.24 17.951 13.8621 17.3738 14.405C17.5854 13.7034 17.7182 12.9678 17.761 12.2084Z"
      fill="#8C6FF0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.9836 9.25988C10.8303 8.82656 10.5465 8.4514 10.1712 8.18605C9.79596 7.92071 9.34765 7.77822 8.88803 7.77821V7.22266H7.77691V7.77821C7.18754 7.77821 6.62231 8.01234 6.20556 8.42909C5.78881 8.84584 5.55469 9.41107 5.55469 10.0004C5.55469 10.5898 5.78881 11.155 6.20556 11.5718C6.62231 11.9885 7.18754 12.2227 7.77691 12.2227V14.4449C7.29358 14.4449 6.88191 14.1366 6.72858 13.7043C6.70583 13.6336 6.66913 13.5682 6.62065 13.5119C6.57217 13.4556 6.5129 13.4096 6.44633 13.3767C6.37977 13.3437 6.30727 13.3244 6.23312 13.3199C6.15897 13.3155 6.08468 13.3259 6.01464 13.3507C5.9446 13.3754 5.88024 13.4139 5.82535 13.464C5.77047 13.5141 5.72618 13.5746 5.6951 13.6421C5.66403 13.7095 5.6468 13.7826 5.64444 13.8568C5.64208 13.9311 5.65463 14.005 5.68135 14.0743C5.8346 14.5077 6.1184 14.8828 6.49369 15.1482C6.86898 15.4135 7.31729 15.556 7.77691 15.556V16.1116H8.88803V15.556C9.4774 15.556 10.0426 15.3219 10.4594 14.9051C10.8761 14.4884 11.1103 13.9231 11.1103 13.3338C11.1103 12.7444 10.8761 12.1792 10.4594 11.7624C10.0426 11.3457 9.4774 11.1116 8.88803 11.1116V8.88933C9.37136 8.88933 9.78303 9.19766 9.93636 9.62988C9.95911 9.7006 9.99581 9.76603 10.0443 9.82231C10.0928 9.8786 10.152 9.92459 10.2186 9.95756C10.2852 9.99054 10.3577 10.0098 10.4318 10.0143C10.506 10.0188 10.5803 10.0083 10.6503 9.98357C10.7203 9.95882 10.7847 9.92028 10.8396 9.87022C10.8945 9.82017 10.9388 9.75961 10.9698 9.69214C11.0009 9.62467 11.0181 9.55165 11.0205 9.4774C11.0229 9.40316 11.0103 9.32919 10.9836 9.25988ZM7.77691 8.88933C7.48223 8.88933 7.19961 9.00639 6.99124 9.21476C6.78286 9.42314 6.6658 9.70575 6.6658 10.0004C6.6658 10.2951 6.78286 10.5777 6.99124 10.7861C7.19961 10.9945 7.48223 11.1116 7.77691 11.1116V8.88933ZM8.88803 14.4449C9.18271 14.4449 9.46533 14.3278 9.6737 14.1195C9.88208 13.9111 9.99914 13.6285 9.99914 13.3338C9.99914 13.0391 9.88208 12.7565 9.6737 12.5481C9.46533 12.3397 9.18271 12.2227 8.88803 12.2227V14.4449Z"
      fill="#8C6FF0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.6667 11.6664C16.6667 16.2686 12.9356 19.9997 8.33335 19.9997C3.73112 19.9997 0 16.2686 0 11.6664C0 7.06412 3.73112 3.33301 8.33335 3.33301C12.9356 3.33301 16.6667 7.06412 16.6667 11.6664ZM15.5556 11.6664C15.5556 15.6553 12.3222 18.8886 8.33335 18.8886C4.34445 18.8886 1.11111 15.6553 1.11111 11.6664C1.11111 7.67746 4.34445 4.44412 8.33335 4.44412C12.3222 4.44412 15.5556 7.67746 15.5556 11.6664Z"
      fill="#8C6FF0"
    />
  </svg>
);

export { BalanceIcon };
