import { FunctionComponent } from "react";

export const KumuArrowRight: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <mask id="mask0_212_3603" maskUnits="userSpaceOnUse" width="24" height="24">
      <rect width="24" height="24" fill="white" />
    </mask>
    <g mask="url(#mask0_212_3603)">
      <path d="M14.9898 14.8321C15.6197 14.2021 15.1736 13.125 14.2827 13.125H4C3.44772 13.125 3 12.6773 3 12.125V11.875C3 11.3227 3.44772 10.875 4 10.875H14.2827C15.1736 10.875 15.6197 9.79786 14.9898 9.16789L11.1103 5.28839C10.7173 4.89542 10.7201 4.25743 11.1166 3.86795L11.2929 3.69465C11.6845 3.31001 12.3128 3.31279 12.7009 3.70088L20.2929 11.2929C20.6834 11.6834 20.6834 12.3166 20.2929 12.7071L12.7009 20.2991C12.3128 20.6872 11.6845 20.69 11.2929 20.3054L11.1166 20.1321C10.7201 19.7426 10.7173 19.1046 11.1103 18.7116L14.9898 14.8321Z" />
    </g>
  </svg>
);
