import { FunctionComponent } from "react";

export const EmailIcon: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="50"
    height="50"
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 25C0 38.8071 11.1929 50 25 50C38.8071 50 50 38.8071 50 25C50 11.1929 38.8071 0 25 0C11.1929 0 0 11.1929 0 25Z"
      fill="#33878E"
    />
    <path
      d="M35.9375 15.625H14.0625C13.6481 15.625 13.2507 15.7896 12.9576 16.0826C12.6646 16.3757 12.5 16.7731 12.5 17.1875V32.8125C12.5 33.2269 12.6646 33.6243 12.9576 33.9174C13.2507 34.2104 13.6481 34.375 14.0625 34.375H35.9375C36.3519 34.375 36.7493 34.2104 37.0424 33.9174C37.3354 33.6243 37.5 33.2269 37.5 32.8125V17.1875C37.5 16.7731 37.3354 16.3757 37.0424 16.0826C36.7493 15.7896 36.3519 15.625 35.9375 15.625ZM34.7344 32.8125H15.3594L20.8281 27.1562L19.7031 26.0703L14.0625 31.9062V18.375L23.7734 28.0391C24.0662 28.3301 24.4622 28.4934 24.875 28.4934C25.2878 28.4934 25.6838 28.3301 25.9766 28.0391L35.9375 18.1328V31.8047L30.1875 26.0547L29.0859 27.1562L34.7344 32.8125ZM15.0859 17.1875H34.6719L24.875 26.9297L15.0859 17.1875Z"
      fill="white"
    />
  </svg>
);
