import { FunctionComponent } from "react";

const SixtyXGreen: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    width={16}
    height={16}
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M13.52 15.981c-.508.009-.897-.185-1.238-.481-.462-.4-.874-.853-1.307-1.284-.962-.958-1.921-1.92-2.88-2.884-.075-.076-.119-.085-.2-.003-1.25 1.255-2.504 2.506-3.754 3.76-.223.225-.448.443-.715.616-.507.328-1.046.393-1.593.142a3.263 3.263 0 0 1-1.705-1.761c-.263-.635-.103-1.222.314-1.747.247-.311.55-.571.825-.855.225-.232.457-.458.685-.687l2.688-2.7c.11-.11.11-.11-.006-.226C3.411 6.647 2.187 5.423.965 4.198a3.449 3.449 0 0 1-.605-.785c-.407-.75-.37-1.47.135-2.162a3.63 3.63 0 0 1 .853-.854C2.147-.16 2.944-.13 3.703.478c.117.094.226.197.332.303l11.163 11.172c.198.198.38.408.529.646.31.497.364 1.024.121 1.552a3.328 3.328 0 0 1-1.822 1.74c-.173.071-.357.1-.506.09Z"
      fill="#014157"
    />
    <path
      d="M8.66 2.09c.045-.789.418-1.517 1.286-1.91.774-.351 1.581-.162 2.182.54.05.058.097.12.142.181.04.056.068.052.108-.002.116-.156.243-.3.388-.431.736-.666 1.823-.55 2.494.068.884.816.976 2.248.21 3.168-.289.348-.633.647-.956.964-.283.277-.573.547-.86.82-.382.366-.768.73-1.17 1.074-.158.136-.162.141-.322.002-.256-.223-.51-.447-.757-.681-.521-.496-1.035-1-1.555-1.497-.222-.212-.451-.417-.65-.652-.368-.435-.538-.941-.54-1.643Z"
      fill="#03BD93"
    />
  </svg>
);
export { SixtyXGreen };
