import { FunctionComponent } from "react";

const TopicYoga: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    width={50}
    height={32}
    viewBox="0 0 50 32"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path d="M24.996 27.155c2.619-2.306 11.112-11.395 0-24.243-11.107 12.848-2.613 21.937 0 24.243Zm0 2.772C22.105 31.016 6.4 35.847.053 15.254a1.164 1.164 0 0 1 .771-1.46l.101-.026c1.67-.39 3.368-.655 5.078-.793a25.842 25.842 0 0 1 3.231-.053c-.08-2.807.217-5.612.882-8.34a1.165 1.165 0 0 1 1.393-.881l.088.022A34.718 34.718 0 0 1 16.406 5.6c.976.462 1.921.986 2.83 1.57A31.588 31.588 0 0 1 24.131.4l.027-.027.062-.062a1.172 1.172 0 0 1 1.652.062 31.404 31.404 0 0 1 4.92 6.797 26.927 26.927 0 0 1 2.803-1.57A34.297 34.297 0 0 1 38.4 3.74l.084-.022a1.164 1.164 0 0 1 1.398.882c.664 2.728.96 5.533.881 8.34 1.079-.05 2.16-.033 3.235.052 1.709.138 3.405.403 5.074.794a.378.378 0 0 1 .101.026 1.168 1.168 0 0 1 .776 1.46C43.593 35.872 27.879 31.028 25 29.944l-.005-.018ZM9.463 15.297a23.586 23.586 0 0 0-3.288.031 31.46 31.46 0 0 0-3.487.477c4.36 12.557 12.726 13.567 17.746 12.884-4.095-1.583-9.732-5.215-10.97-13.391Zm8.776-5.976a23.126 23.126 0 0 0-2.856-1.614 30.897 30.897 0 0 0-3.262-1.322c-2.503 13.056 4.236 18.116 8.926 20.034-2.76-3.421-5.818-9.385-2.808-17.085V9.32Zm11.328 19.367c5.02.684 13.387-.326 17.746-12.883a31.672 31.672 0 0 0-3.491-.507 23.523 23.523 0 0 0-3.284-.031c-1.238 8.176-6.876 11.808-10.97 13.39v.032Zm-.613-2.283c4.69-1.917 11.425-6.977 8.926-20.033a31 31 0 0 0-3.266 1.322 23.132 23.132 0 0 0-2.856 1.613c3.015 7.7-.049 13.665-2.808 17.085l.005.013Z" />
  </svg>
);

export { TopicYoga };
