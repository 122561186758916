import { FunctionComponent } from "react";
import { classNames } from "@/utils/classNames";

export const ButtonClose: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    width={30}
    height={30}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g opacity={0.8}>
      <rect
        x={29.5}
        y={29.5}
        width={29}
        height={29}
        rx={14.5}
        transform="rotate(-180 29.5 29.5)"
        stroke="#CDD0D0"
      />
      <mask id="button-close-mask" fill="#fff">
        <path d="M21.414 9.414 20 8l-5.293 5.293L9.414 8 8 9.414l5.293 5.293L8 20l1.414 1.414 5.293-5.293L20 21.414 21.414 20l-5.293-5.293 5.293-5.293Z" />
      </mask>
      <path
        d="M21.414 9.414 20 8l-5.293 5.293L9.414 8 8 9.414l5.293 5.293L8 20l1.414 1.414 5.293-5.293L20 21.414 21.414 20l-5.293-5.293 5.293-5.293Z"
        fill="#CDD0D0"
      />
      <path
        d="m21.414 9.414 15.085 15.085L51.584 9.414 36.499-5.671 21.414 9.414ZM20 8 35.085-7.085 20-22.17 4.915-7.085 20 8Zm-5.293 5.293L-.378 28.378l15.085 15.085 15.085-15.085-15.085-15.085ZM9.414 8 24.499-7.085 9.414-22.17-5.671-7.085 9.414 8ZM8 9.414-7.085-5.671-22.17 9.414l15.085 15.085L8 9.414Zm5.293 5.293 15.085 15.085 15.085-15.085L28.378-.378 13.293 14.707ZM8 20-7.085 4.915-22.17 20l15.085 15.085L8 20Zm1.414 1.414L-5.671 36.499 9.414 51.584l15.085-15.085L9.414 21.414Zm5.293-5.293L29.792 1.036 14.707-14.049-.378 1.036l15.085 15.085ZM20 21.414 4.915 36.499 20 51.584l15.085-15.085L20 21.414ZM21.414 20l15.085 15.085L51.584 20 36.499 4.915 21.414 20Zm-5.293-5.293L1.036-.378l-15.085 15.085L1.036 29.792l15.085-15.085ZM36.499-5.671l-1.414-1.414-30.17 30.17 1.414 1.414L36.5-5.671ZM4.915-7.085-.378-1.792l30.17 30.17 5.293-5.293-30.17-30.17Zm24.877 5.293-5.293-5.293-30.17 30.17 5.293 5.293 30.17-30.17ZM-5.671-7.085-7.085-5.67l30.17 30.17 1.414-1.414-30.17-30.17ZM-7.085 24.5l5.293 5.293 30.17-30.17-5.293-5.293-30.17 30.17ZM-1.792-.378l-5.293 5.293 30.17 30.17 5.293-5.293-30.17-30.17Zm-5.293 35.463 1.414 1.414 30.17-30.17-1.414-1.414-30.17 30.17Zm31.584 1.414 5.293-5.293-30.17-30.17L-5.67 6.33 24.5 36.5ZM-.378 31.206l5.293 5.293 30.17-30.17-5.293-5.293-30.17 30.17Zm35.463 5.293 1.414-1.414-30.17-30.17L4.915 6.33l30.17 30.17Zm1.414-31.584L31.206-.378l-30.17 30.17 5.293 5.293L36.5 4.915Zm-5.293 24.877 5.293-5.293-30.17-30.17L1.036-.378l30.17 30.17Z"
        fill="#CDD0D0"
        mask="url(#button-close-mask)"
      />
    </g>
  </svg>
);
