import { FunctionComponent } from "react";

const SixtyLogoWhiteBigIcon: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    className={className}
    viewBox="0 0 80 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M80.0009 9.16693C78.4489 12.595 76.8913 16.0203 75.3471 19.4518C74.0388 22.359 72.7545 25.2768 71.4429 28.1823C70.8141 29.5752 70.0641 30.078 68.8564 29.99C67.3958 29.8834 66.5968 28.7144 67.1524 27.3549C67.7391 25.9194 68.4093 24.5156 69.1034 23.1279C69.4125 22.5098 69.442 22.0023 69.1463 21.3546C67.4307 17.5976 65.7647 13.8181 64.082 10.0461C64.0481 9.97022 64.0178 9.89262 63.9846 9.81636C63.3149 8.28185 63.5519 7.25481 64.6888 6.76526C66.2381 6.09806 67.6481 6.61577 68.2446 8.10386C69.3276 10.805 70.3891 13.5151 71.4633 16.2197C71.5621 16.4688 71.5975 16.7542 71.8898 16.9969C72.0371 16.6855 72.1829 16.4061 72.304 16.1163C73.4058 13.4827 74.5104 10.8499 75.5984 8.21062C75.9665 7.31766 76.4299 6.57588 77.5286 6.52376C78.6174 6.47214 79.486 6.81286 80.0009 7.83353C80.0009 8.27799 80.0009 8.72246 80.0009 9.16693Z"
      fill="#F3F9F8"
    />
    <path
      d="M24.0067 0.000168783C25.5657 0.80564 26.0416 1.56586 25.7996 2.86423C25.591 3.98294 24.7297 4.66841 23.4781 4.71148C22.1206 4.75824 21.181 4.12087 20.9077 2.96747C20.6339 1.81306 21.1585 0.718153 22.229 0.209496C22.3769 0.139273 22.5252 0.0698879 22.6735 0C23.1179 0.000167597 23.5623 0.000168783 24.0067 0.000168783Z"
      fill="#F3F9F8"
    />
    <path
      d="M7.96767 23.7068C5.68777 23.7654 3.38482 23.2432 1.27626 21.9971C0.0474588 21.2709 -0.296401 20.1569 0.255038 18.9495C0.869103 17.6054 2.14583 17.1702 3.40803 17.9359C4.66572 18.6988 5.87264 19.5455 7.3845 19.7508C8.49473 19.9015 9.60161 19.9355 10.6886 19.6382C11.6701 19.3697 12.3718 18.7965 12.5068 17.713C12.6656 16.4371 12.2456 15.5587 11.1853 14.9984C9.80786 14.2706 8.25892 14.1789 6.77578 13.8482C5.58005 13.5816 4.42006 13.2305 3.32736 12.6633C-1.04691 10.3925 -0.54306 4.40699 2.65084 2.17476C3.88415 1.31281 5.24454 0.801644 6.72384 0.605891C9.2459 0.272205 11.7082 0.479185 14.0617 1.51426C14.8294 1.8518 15.5414 2.28722 16.0906 2.93566C16.8386 3.81872 16.8132 4.95537 16.0484 5.81882C15.3238 6.63703 14.2274 6.76843 13.2842 6.00938C11.6642 4.70581 9.86898 4.09995 7.79382 4.32135C7.26326 4.37799 6.7529 4.48995 6.26893 4.70531C5.37297 5.10385 4.9077 5.80961 4.84023 6.7815C4.77527 7.71703 5.23185 8.37099 5.98603 8.85082C7.14419 9.58758 8.50257 9.67003 9.78515 10.0004C10.6703 10.2283 11.5691 10.4184 12.4323 10.712C15.7251 11.8322 17.1887 14.013 17.0106 17.4589C16.8605 20.3649 14.8602 22.5985 11.7609 23.3335C10.5654 23.6169 9.35395 23.7291 7.96767 23.7068Z"
      fill="#F3F9F8"
    />
    <path
      d="M52.1691 15.0794C52.1691 13.636 52.1447 12.192 52.1812 10.7495C52.1956 10.1842 52.0593 9.94336 51.4519 9.99398C50.8833 10.0414 50.2659 10.1214 49.9755 9.39247C49.6707 8.62756 49.6255 7.87488 50.0428 7.15053C50.3285 6.65427 50.8593 6.70505 51.3165 6.75315C52.0223 6.82723 52.2469 6.56494 52.1817 5.87344C52.1223 5.24059 52.1654 4.59769 52.1701 3.95932C52.1786 2.81899 52.6108 2.22 53.6851 1.85296C54.1637 1.68939 54.6575 1.63458 55.1614 1.65419C56.0634 1.68939 56.486 2.0752 56.5156 2.98357C56.549 4.0096 56.5492 5.0378 56.5233 6.064C56.5108 6.55858 56.6609 6.77428 57.1806 6.74712C57.7895 6.71545 58.4022 6.72265 59.0118 6.74846C59.9465 6.78785 60.3296 7.28159 60.3189 8.38253C60.308 9.49219 59.9168 9.97035 58.9904 10.0039C58.8795 10.0079 58.7684 10.0049 58.6574 10.0054C56.5316 10.0148 56.5316 10.0148 56.5318 12.1023C56.5318 14.1842 56.5235 16.2661 56.535 18.3478C56.543 19.7674 57.1616 20.1844 58.5119 19.7082C58.6948 19.6437 58.875 19.5716 59.0544 19.4979C60.1115 19.0638 60.7489 19.186 61.1624 19.9043C61.7449 20.9163 61.5752 22.1846 60.7783 22.7764C59.2282 23.9276 55.5542 24.0381 53.9282 22.9888C52.654 22.1665 52.2293 20.9069 52.1864 19.492C52.1418 18.0222 52.1764 16.55 52.1764 15.0789C52.1739 15.0794 52.1714 15.0794 52.1691 15.0794Z"
      fill="#F3F9F8"
    />
    <path
      d="M25.5099 15.0232C25.5102 17.162 25.5176 19.3007 25.5077 21.4394C25.5012 22.8481 24.8796 23.3943 23.3253 23.395C21.7108 23.3956 21.071 22.8503 21.0678 21.4143C21.0586 17.1924 21.0638 12.9703 21.0653 8.74818C21.0656 7.93231 21.219 7.16036 22.0488 6.79148C22.9835 6.37601 23.9499 6.36947 24.8457 6.91198C25.3985 7.24684 25.5144 7.82505 25.5124 8.43996C25.5052 10.6345 25.5096 12.8288 25.5099 15.0232Z"
      fill="#F3F9F8"
    />
    <path
      d="M43.9271 23.7876C43.3704 23.797 42.9431 23.5845 42.5693 23.2609C42.0634 22.823 41.6119 22.3281 41.1379 21.8569C40.0828 20.808 39.0316 19.7552 37.9818 18.7011C37.8986 18.6175 37.8507 18.6076 37.761 18.6976C36.392 20.0712 35.0181 21.4401 33.6479 22.8125C33.4035 23.0573 33.1564 23.2965 32.8638 23.4854C32.3081 23.8441 31.7179 23.9155 31.1179 23.6409C30.2499 23.2437 29.6142 22.5945 29.2495 21.7138C28.9618 21.0193 29.137 20.3768 29.594 19.8025C29.8649 19.462 30.1955 19.1778 30.498 18.867C30.745 18.6132 30.9988 18.366 31.249 18.1152C32.231 17.1311 33.2129 16.1468 34.1947 15.1626C34.3153 15.0417 34.3153 15.0413 34.1883 14.9143C32.8478 13.5747 31.5066 12.2359 30.1672 10.8952C29.9099 10.6376 29.6788 10.359 29.5036 10.0367C29.0576 9.2164 29.0974 8.42833 29.6518 7.67116C29.9153 7.31139 30.2193 6.99159 30.5867 6.73657C31.4623 6.12863 32.3357 6.16063 33.1681 6.82624C33.2963 6.92879 33.4154 7.04087 33.5314 7.15672C37.6094 11.2314 41.6875 15.3061 45.7661 19.3801C45.983 19.5968 46.1819 19.827 46.3449 20.0876C46.6851 20.6311 46.7442 21.2073 46.478 21.7856C46.0673 22.6779 45.3955 23.3158 44.4815 23.6892C44.2914 23.7668 44.0895 23.7989 43.9271 23.7876Z"
      fill="#F3F9F8"
    />
    <path
      d="M38.6016 8.58934C38.6509 7.72541 39.0599 6.92887 40.011 6.49844C40.8588 6.11477 41.7435 6.32202 42.4018 7.08957C42.4565 7.15332 42.5079 7.22013 42.5572 7.28813C42.6011 7.34862 42.632 7.3449 42.6764 7.2851C42.8031 7.11479 42.9423 6.9573 43.1016 6.81349C43.9082 6.08564 45.0992 6.21169 45.8345 6.8889C46.8032 7.78104 46.9048 9.34813 46.0655 10.3551C45.7482 10.7356 45.3714 11.0628 45.0171 11.4095C44.7072 11.7128 44.3888 12.0075 44.075 12.3069C43.6554 12.7071 43.233 13.1042 42.7925 13.4816C42.6191 13.6302 42.6143 13.6358 42.4395 13.4837C42.159 13.2398 41.8795 12.9942 41.61 12.7384C41.0386 12.196 40.4756 11.6447 39.9058 11.1007C39.6625 10.8684 39.4109 10.6447 39.1931 10.3875C38.7899 9.91132 38.6033 9.3574 38.6016 8.58934Z"
      fill="#03BD93"
    />
  </svg>
);

export { SixtyLogoWhiteBigIcon };
