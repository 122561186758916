import { FunctionComponent } from "react";

export const LinkedInIcon: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    width="14"
    height="13"
    viewBox="0 0 14 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.490898 2.57275C0.163044 2.28625 0 1.93162 0 1.50967C0 1.08772 0.163916 0.717478 0.490898 0.430158C0.818751 0.143659 1.24078 0 1.75784 0C2.27491 0 2.68037 0.143659 3.00736 0.430158C3.33521 0.716657 3.49825 1.07705 3.49825 1.50967C3.49825 1.94229 3.33434 2.28625 3.00736 2.57275C2.6795 2.85925 2.26358 3.00291 1.75784 3.00291C1.25211 3.00291 0.818751 2.85925 0.490898 2.57275ZM3.22272 4.21622V13H0.274656V4.21622H3.22272Z"
      fill="#202421"
    />
    <path
      d="M13.0365 5.08373C13.6791 5.74046 14 6.64184 14 7.78948V12.8447H11.2002V8.14574C11.2002 7.567 11.0406 7.11714 10.7224 6.79699C10.4041 6.47683 9.97511 6.31592 9.43799 6.31592C8.90087 6.31592 8.47185 6.47601 8.15358 6.79699C7.83532 7.11714 7.67576 7.567 7.67576 8.14574V12.8447H4.85938V4.19141H7.67576V5.33904C7.96088 4.9565 8.34543 4.6544 8.82849 4.43193C9.31155 4.20946 9.85477 4.09863 10.459 4.09863C11.535 4.09863 12.3947 4.427 13.0365 5.08373Z"
      fill="#202421"
    />
  </svg>
);
