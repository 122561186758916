import { FunctionComponent } from "react";

export const AlertIcon: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g clipPath="url(#clip0_7178_28457)">
      <path
        d="M6.00112 11.5706C9.07813 11.5706 11.5725 9.07618 11.5725 5.99916C11.5725 2.92215 9.07813 0.427734 6.00112 0.427734C2.9241 0.427734 0.429688 2.92215 0.429688 5.99916C0.429688 9.07618 2.9241 11.5706 6.00112 11.5706Z"
        strokeWidth="1.14286"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 3V5.57143"
        strokeWidth="1.14286"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.00084 8.57003C6.23753 8.57003 6.42941 8.37816 6.42941 8.14146C6.42941 7.90477 6.23753 7.71289 6.00084 7.71289C5.76414 7.71289 5.57227 7.90477 5.57227 8.14146C5.57227 8.37816 5.76414 8.57003 6.00084 8.57003Z"
        strokeWidth="1.14286"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_7178_28457">
        <rect width="12" height="12" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
