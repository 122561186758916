import { FunctionComponent, useContext, useState } from "react";
import useTranslation from "next-translate/useTranslation";
//Components
import { Label } from "@/components/common/label";
import { SuccessContent } from "./success-content";
import { Modal } from "@/components/common/modal";
import { GetIcon } from "@/components/common/icon";
import { FailedContent } from "./failed-content";
import { TransactionContent } from "./transaction-content";

//Models

//Context
import CheckoutContext from "@/context/checkout";
import { CheckoutService } from "@/services/checkout";
import { getEncodedBackUrl } from "@/utils/checkout";
import { useRouter } from "next/router";

type Props = {};

export const SupportModal: FunctionComponent<Props> = () => {
  const { checkoutData, status, onClose } = useContext(CheckoutContext);
  const { t } = useTranslation();
  const { pathname, query } = useRouter();

  // States
  const [isLoading, setIsLoading] = useState(false);

  // Query params

  // Donate is the only use case that sets the amount on the modal itself
  const handleBuyCredits = async (amount?: number) => {
    if (!checkoutData) return;

    checkoutData.price = amount ? amount : checkoutData.price;
    localStorage.setItem("checkoutContext", JSON.stringify(checkoutData));

    setIsLoading(true);

    const stripeName = t("common:common.sixtypremium");
    const description = `${t("common:content.unlockcontent")} ${t(
      "common:common.to"
    )} ${checkoutData.creator?.name}`;

    const url = (status: StatusType) =>
      `${getEncodedBackUrl(query, pathname)}?status=${status}`;

    const stripeOperation = {
      description,
      contentId: checkoutData.contentId,
      price: checkoutData.price,
      amount: checkoutData.price,
      priceId: checkoutData.priceId,
      type: checkoutData.transactionType,
      productName: stripeName,
      creatorId: checkoutData.creator?.id || "",
      image: checkoutData.creator?.profileImage?.url || "",
      successUrl: `${url("SUCCESS")}`,
      cancelUrl: `${url("CANCELLED")}`,
    };
    await CheckoutService.handleBuyCredits(stripeOperation);
  };

  const title = (
    <Label
      fontWeight="semibold"
      fontSize="lg"
      textColor="green-dark"
      className="m-auto mt-2"
    >
      {t(
        `common:common.${
          checkoutData?.transactionType === "DONATION"
            ? "tip"
            : checkoutData?.transactionType === "SUBSCRIPTION"
            ? "becomeMember"
            : "checkout"
        }`
      )}
    </Label>
  );

  const successLabelContent = (): string => {
    if (!checkoutData) return "";

    const transactionOptions = {
      subscription: checkoutData.transactionType === "SUBSCRIPTION",
      donation: checkoutData.transactionType === "DONATION",
      // NOTE: Use content type as reference for label
      // Transaction type of digital product is being set to "PPV_COURSE"
      digitalProduct: checkoutData.contentType === "DIGITAL_PRODUCT",
      // digitalProduct: checkoutData.transactionType === "PPV_PRODUCT",
      series: checkoutData.transactionType === "PPV_COURSE",
      livestream: checkoutData.transactionType === "PPV_LIVE",
    };

    const donateString = `${t("common:common.donated")} $${checkoutData.price}`;

    const labelContent = transactionOptions.donation
      ? `${t("common:subscription.youjust")} ${donateString}`
      : transactionOptions.subscription
      ? `${t("common:subscription.subscribeto")} ${
          checkoutData.creator?.name
        } ${checkoutData.creator?.lastName}!`
      : transactionOptions.digitalProduct
      ? t("common:subscription.digitalproductready")
      : transactionOptions.series
      ? t("common:subscription.seriesready")
      : transactionOptions.livestream
      ? t("common:subscription.livestreamready")
      : "";

    return labelContent;
  };

  const failedLabelContent = (): { labelContent: string, title?: string} => {
    if (!checkoutData) return { labelContent : "" };

    switch (checkoutData.transactionType) {
      case "SUBSCRIPTION":
        return { labelContent: t("common:subscription.therewasaproblem") };
      case "DONATION":
        return { labelContent: t("common:subscription.donationnotsent") };
      case "PPV_PRODUCT":
      case "PPV_COURSE":
      case "PPV_LIVE":
        return {
          title: t("common:subscription.checkoutdidnotgothrough"),
          labelContent: t("common:subscription.detailsarenotright")
        };
      default:
        return { labelContent : "" };
    }
  };

  return (
    <Modal showModal onClose={onClose} title={title} modifier="kumu">
      {!isLoading && checkoutData && (
        <>
          {status === "IN_PROGRESS" && (
            <>
              <div className="w-full flex flex-col mt-8 align-start">
                <TransactionContent
                  handleClick={handleBuyCredits}
                  price={checkoutData.price}
                  transactionType={checkoutData.transactionType}
                />
              </div>
            </>
          )}
          {status === "SUCCESS" && (
            <SuccessContent
              headline={t("common:common.purchasecomplete")}
              labelContent={successLabelContent()}
            />
          )}
          {status === "FAILED" ||
            (status === "CANCELLED" && (
              <FailedContent {...failedLabelContent()}/>
            ))}
        </>
      )}
      {isLoading && (
        <div className="w-full h-[167px]">
          <GetIcon
            iconName="loading"
            className="animate-spin fill-current text-light-4 m-auto mt-[145px] w-[50px] h-[50px]"
          />
        </div>
      )}
    </Modal>
  );
};
