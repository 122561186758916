import { FunctionComponent } from "react";

const ChatIcon: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M20.5 13.3333V20H13.8333C12.6642 19.9988 11.516 19.6902 10.5039 19.1051C9.49172 18.5201 8.65121 17.6791 8.06667 16.6667C8.69489 16.6622 9.32098 16.5929 9.935 16.46C10.4026 17.0447 10.9958 17.5167 11.6707 17.841C12.3455 18.1653 13.0846 18.3335 13.8333 18.3333H18.8333V13.3333C18.8331 12.5844 18.6643 11.845 18.3395 11.1702C18.0146 10.4954 17.542 9.90229 16.9567 9.435C17.0907 8.8211 17.1611 8.19501 17.1667 7.56667C18.1791 8.15121 19.0201 8.99172 19.6051 10.0039C20.1902 11.016 20.4988 12.1642 20.5 13.3333ZM15.5 7.5C15.5 5.51088 14.7098 3.60322 13.3033 2.1967C11.8968 0.790176 9.98912 0 8 0C6.01088 0 4.10322 0.790176 2.6967 2.1967C1.29018 3.60322 0.5 5.51088 0.5 7.5L0.5 15H8C9.98845 14.9978 11.8948 14.2069 13.3009 12.8009C14.7069 11.3948 15.4978 9.48845 15.5 7.5ZM2.16667 7.5C2.16667 6.34628 2.50879 5.21846 3.14976 4.25917C3.79074 3.29989 4.70178 2.55221 5.76768 2.1107C6.83358 1.66919 8.00647 1.55367 9.13803 1.77875C10.2696 2.00383 11.309 2.5594 12.1248 3.37521C12.9406 4.19102 13.4962 5.23042 13.7212 6.36197C13.9463 7.49353 13.8308 8.66642 13.3893 9.73232C12.9478 10.7982 12.2001 11.7093 11.2408 12.3502C10.2815 12.9912 9.15372 13.3333 8 13.3333H2.16667V7.5Z" />
    <circle cx="5.375" cy="7.875" r="0.875" />
    <circle cx="8" cy="7.875" r="0.875" />
    <circle cx="10.625" cy="7.875" r="0.875" />
  </svg>
);

export { ChatIcon };
