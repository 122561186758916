import useTranslation from "next-translate/useTranslation";
import { GetIcon } from "@/components/common/icon";
import { Label } from "@/components/common/label";
import { FunctionComponent } from "react";

type FailedProps = {
  labelContent: string;
  title?: string;
};

export const FailedContent: FunctionComponent<FailedProps> = ({
  labelContent,
  title = "common:subscription.something",
}) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center pb-8 mt-8">
      <div className="bg-[#F7F0DB] rounded-full w-[90px] h-[90px] relative">
        <GetIcon
          iconName="exclamation"
          className="fill-current text-[#D9B24D] w-11 h-11 stroke-current stroke-1 absolute bottom-5 left-[29px]"
        />
      </div>
      <Label
        className="mt-4"
        textColor="black"
        fontSize="[20px]"
        fontWeight="semibold"
      >
        {t(title)}
      </Label>
      <Label textColor="black" fontWeight="medium" className="mt-4 w-48">
        {labelContent}
      </Label>
      <Label textColor="black" fontSize="sm" className="mt-8">
        {t("common:subscription.contact")}
      </Label>
    </div>
  );
};
