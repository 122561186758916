import { FunctionComponent } from "react";

export const CameraIcon: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g id="Icon">
      <path
        id="Vector"
        d="M4.9 4C2.75684 4 1 5.75684 1 7.9V18.1C1 20.2432 2.75684 22 4.9 22H15.1C17.2432 22 19 20.2432 19 18.1V17.2902L23.6371 20.0723C23.7737 20.1542 23.9296 20.1983 24.0889 20.2003C24.2481 20.2022 24.4051 20.1619 24.5436 20.0834C24.6822 20.0049 24.7975 19.891 24.8777 19.7533C24.9578 19.6157 25 19.4593 25 19.3V6.7C25 6.57951 24.9759 6.46024 24.929 6.34926C24.8821 6.23828 24.8134 6.13784 24.7269 6.05391C24.6405 5.96998 24.538 5.90426 24.4257 5.86064C24.3134 5.81702 24.1935 5.7964 24.073 5.8C23.9192 5.80461 23.7691 5.84858 23.6371 5.92773L19 8.70977V7.9C19 5.75684 17.2432 4 15.1 4H4.9ZM4.9 5.8H15.1C16.27 5.8 17.2 6.72996 17.2 7.9V10.2719V15.6895V18.1C17.2 19.27 16.27 20.2 15.1 20.2H4.9C3.72996 20.2 2.8 19.27 2.8 18.1V7.9C2.8 6.72996 3.72996 5.8 4.9 5.8ZM23.2 8.29023V17.7098L19 15.1902V10.8098L23.2 8.29023Z"
        fill="white"
      />
    </g>
  </svg>
);
