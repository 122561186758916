import { FunctionComponent } from "react";

export const IncreaseIcon: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M15.8905 8.38H8.90047V15.4H7.58047V8.38H0.620469V7.12H7.58047V0.0999984H8.90047V7.12H15.8905V8.38Z" />
  </svg>
);
