import { FunctionComponent } from "react";

const ShareIcon: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="20"
    height="22"
    viewBox="0 0 20 22"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M3.50638 13.9801C4.38104 13.9775 5.22261 13.6454 5.86338 13.0501L12.1234 16.6271C11.9136 17.4467 12.0061 18.3144 12.3838 19.0714C12.7616 19.8284 13.3993 20.424 14.1803 20.7493C14.9614 21.0745 15.8334 21.1075 16.6368 20.8424C17.4401 20.5772 18.1212 20.0316 18.5551 19.3053C18.9891 18.5791 19.147 17.7209 18.9999 16.8877C18.8528 16.0546 18.4105 15.3023 17.7541 14.7686C17.0977 14.2349 16.271 13.9554 15.4254 13.9814C14.5798 14.0074 13.7718 14.3371 13.1494 14.9101L6.88938 11.3331C6.95538 11.0831 6.99138 10.8241 6.99738 10.5651L13.1474 7.05007C13.7396 7.58884 14.499 7.90757 15.2983 7.95278C16.0976 7.998 16.8882 7.76694 17.5374 7.29838C18.1865 6.82982 18.6548 6.15227 18.8636 5.37939C19.0724 4.60651 19.0091 3.78532 18.6842 3.0536C18.3594 2.32188 17.7927 1.72414 17.0794 1.36066C16.3661 0.997186 15.5494 0.890085 14.7665 1.05733C13.9836 1.22457 13.282 1.65598 12.7794 2.27919C12.2769 2.90239 12.0039 3.67948 12.0064 4.48007C12.0104 4.76807 12.0494 5.05507 12.1234 5.33307L6.43938 8.58007C6.10976 8.07013 5.65315 7.65472 5.1144 7.37464C4.57566 7.09457 3.97332 6.95947 3.36656 6.98262C2.7598 7.00577 2.16951 7.18637 1.65367 7.50668C1.13783 7.82699 0.714197 8.27599 0.424389 8.80956C0.13458 9.34313 -0.011432 9.94292 0.000698694 10.55C0.0128294 11.1571 0.182685 11.7505 0.493578 12.2721C0.80447 12.7937 1.2457 13.2254 1.77393 13.5249C2.30215 13.8243 2.89919 13.9812 3.50638 13.9801Z" />
  </svg>
);

export { ShareIcon };
