import { classNames } from "@/utils/classNames";
import { FunctionComponent } from "react";

const CheckMarkIcon: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="25"
    fill="none"
    viewBox="0 0 24 25"
    className={classNames(className, "fill-current")}
  >
    <g>
      <mask
        id="mask0_7950_40785"
        style={{ maskType: "alpha" }}
        width="24"
        height="25"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path d="M0 0.5H24V24.5H0z"></path>
      </mask>
      <g mask="url(#mask0_7950_40785)">
        <path d="M9.55 15.65l8.475-8.475c.2-.2.437-.3.712-.3.275 0 .513.1.713.3.2.2.3.438.3.713 0 .274-.1.512-.3.712l-9.2 9.2c-.2.2-.434.3-.7.3a.96.96 0 01-.7-.3l-4.3-4.3a.93.93 0 01-.288-.713 1.02 1.02 0 01.313-.712c.2-.2.437-.3.712-.3.275 0 .513.1.713.3l3.55 3.575z"></path>
      </g>
    </g>
  </svg>
);

export { CheckMarkIcon };
