import { FunctionComponent } from "react";

export const KumuWalletIcon: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    className={className}
  >
    <path
      d="M14.2617 16.3438H9.26172"
      stroke="#292D32"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.66 2.86189L12.63 2.93189L9.72996 9.66189H6.87996C6.19996 9.66189 5.54996 9.80189 4.95996 10.0519L6.70996 5.87189L6.74996 5.77189L6.81996 5.61189C6.83996 5.55189 6.85996 5.49189 6.88996 5.44189C8.19996 2.41189 9.67996 1.72189 12.66 2.86189Z"
      stroke="#292D32"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.05 9.86328C17.6 9.72328 17.12 9.66328 16.64 9.66328H9.72998L12.63 2.93328L12.66 2.86328C12.81 2.91328 12.95 2.98328 13.1 3.04328L15.31 3.97328C16.54 4.48328 17.4 5.01328 17.92 5.65328C18.02 5.77328 18.1 5.88328 18.17 6.01328C18.26 6.15328 18.33 6.29328 18.37 6.44328C18.41 6.53328 18.44 6.62328 18.46 6.70328C18.73 7.54328 18.57 8.57328 18.05 9.86328Z"
      stroke="#292D32"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.5217 14.5441V16.4941C21.5217 16.6941 21.5117 16.8941 21.5017 17.0941C21.3117 20.5841 19.3617 22.3441 15.6617 22.3441H7.86172C7.62172 22.3441 7.38172 22.3241 7.15172 22.2941C3.97172 22.0841 2.27172 20.3841 2.06172 17.2041C2.03172 16.9741 2.01172 16.7341 2.01172 16.4941V14.5441C2.01172 12.5341 3.23172 10.8041 4.97172 10.0541C5.57172 9.80406 6.21172 9.66406 6.89172 9.66406H16.6517C17.1417 9.66406 17.6217 9.73406 18.0617 9.86406C20.0517 10.4741 21.5217 12.3341 21.5217 14.5441Z"
      stroke="#292D32"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.71 5.87109L4.96 10.0511C3.22 10.8011 2 12.5311 2 14.5411V11.6111C2 8.77109 4.02 6.40109 6.71 5.87109Z"
      stroke="#292D32"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.5186 11.6114V14.5414C21.5186 12.3414 20.0586 10.4714 18.0586 9.87141C18.5786 8.57141 18.7286 7.55141 18.4786 6.70141C18.4586 6.61141 18.4286 6.52141 18.3886 6.44141C20.2486 7.40141 21.5186 9.37141 21.5186 11.6114Z"
      stroke="#292D32"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
