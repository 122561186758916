import { FunctionComponent } from "react";

const SendMessage: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    width={16}
    height={16}
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path d="M.967 4.432A1.333 1.333 0 0 0 .39 6.657l2.275 2.272v4.39H7.06l2.29 2.287a1.325 1.325 0 0 0 1.279.348 1.319 1.319 0 0 0 .947-.92L15.998 0 .967 4.432Zm.37 1.282L12.69 2.367 4 11.043V8.377L1.338 5.714Zm8.96 8.95-2.684-2.678H4.946l8.689-8.681-3.337 11.358Z" />
  </svg>
);

export { SendMessage };
