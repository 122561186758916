import { FunctionComponent } from "react";

const SixtyXWhiteGreen: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    width={27}
    height={27}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M22.889 26.97c-.842.014-1.489-.304-2.054-.788-.765-.654-1.448-1.393-2.166-2.098-1.596-1.567-3.186-3.14-4.774-4.715-.126-.125-.198-.14-.334-.005-2.071 2.052-4.15 4.098-6.222 6.148-.37.366-.744.724-1.187 1.006-.84.536-1.733.642-2.64.232-1.314-.593-2.276-1.563-2.827-2.88-.436-1.037-.17-1.997.52-2.855.41-.509.91-.934 1.368-1.398.374-.38.758-.748 1.137-1.123l4.456-4.412c.182-.18.182-.181-.01-.371C6.128 11.709 4.1 9.709 2.073 7.706c-.39-.385-.739-.802-1.004-1.283-.675-1.226-.614-2.403.224-3.535a5.978 5.978 0 0 1 1.415-1.396c1.324-.908 2.646-.86 3.905.134.194.153.374.32.55.494L25.67 20.384c.328.324.63.668.876 1.057.514.812.604 1.673.201 2.537-.621 1.333-1.638 2.286-3.02 2.844a1.973 1.973 0 0 1-.839.147Z"
      fill="#fff"
    />
    <path
      d="M14.832 4.257c.075-1.291.693-2.482 2.132-3.125 1.283-.573 2.621-.263 3.617.884.083.095.16.195.235.296.067.09.113.085.18-.004a4.81 4.81 0 0 1 .644-.705c1.22-1.087 3.022-.9 4.134.113 1.465 1.333 1.62 3.674.35 5.179-.48.568-1.05 1.057-1.586 1.575-.47.454-.951.894-1.426 1.341a48.985 48.985 0 0 1-1.94 1.755c-.262.223-.27.23-.534.004a38.679 38.679 0 0 1-1.255-1.114c-.864-.81-1.716-1.634-2.578-2.447-.368-.347-.749-.681-1.078-1.066-.61-.711-.892-1.539-.895-2.686Z"
      fill="#03BD93"
    />
  </svg>
);
export { SixtyXWhiteGreen };
