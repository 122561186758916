import React, { FunctionComponent } from 'react';

interface OwnProps {
    className: string
}

type Props = OwnProps;

export const KumuChevronDown: FunctionComponent<Props> = ({ className }) => {
  return (
      <svg width="16" height="11" viewBox="0 0 16 11" fill="#1C1B1F" xmlns="http://www.w3.org/2000/svg" className={className}>
          <path d="M14.1333 0.499849L16 2.36652L8 10.3665L0 2.36652L1.86667 0.499849L8 6.63318L14.1333 0.499849Z" />
      </svg>
  );
};
