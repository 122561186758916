const { URL } = require("url");

type FetchDebug = Record<FetchServiceContext, boolean>;
const FETCH_DEBUG: FetchDebug = {
  PRIVATE: true,
  PUBLIC: true,
};

const isClient = typeof window !== "undefined";

export type ExtendedRequestInit = RequestInit & {
  search?: any;
};

const fetchService = <T>({
  context,
  path,
  options,
}: {
  context: FetchServiceContext;
  path: string;
  options?: ExtendedRequestInit;
}): Promise<T> => {
  if (FETCH_DEBUG[context]) {
    console.log(
      `[FETCH ${context} - CALL ] - [${path}] - [${options?.method}]`,
      options?.body,
      options?.headers
    );
  }
  const baseUrl = `${
    context === "PUBLIC" ? window.location.origin : process.env.API_URL
  }${path}`;
  let url;
  try {
    url = isClient ? new window.URL(baseUrl) : new URL(baseUrl);

    url.search = options?.search
      ? new URLSearchParams(options?.search).toString()
      : "";
  } catch (e) {
    console.log("error constructing fetch url ", e);
  }

  return fetch(url, {
    ...options,
    credentials: "include",
    headers: {
      ...options?.headers,
      Cookie: (options?.headers as any)?.cookie,
    },
  })
    .then(async (r) => {
      let response;
      try {
        response = await r.json();
      } catch (err) {
        console.log(
          `[FETCH ${context} - WARNING] - ${context} - [${path}] - error parsing the response `,
          r
        );
        response = r;
      }

      if (FETCH_DEBUG[context]) {
        console.log(
          `[FETCH ${context} - SUCCESS] - ${context} - [${path}]`,
          response
        );
      }

      return response;
    })
    .catch((err) => {
      console.log(`[FETCH ${context} - ERR] - ${context} - [${path}]`, err);
      throw new Error(err);
    });
};

/**
 * Requests that belong to the Sixty API (BE)
 * @param path
 * @param options
 * @returns
 */
const serverFetchService = <T>(path: string, options?: ExtendedRequestInit) =>
  fetchService<T>({ context: "PRIVATE", path, options });

/**
 * Requests that belong to the Sixty FEServer (FE Serverside)
 * @param path
 * @param options
 * @returns
 */
const clientFetchService = <T>(path: string, options?: ExtendedRequestInit) =>
  fetchService<T>({ context: "PUBLIC", path, options });

export { clientFetchService, serverFetchService };
