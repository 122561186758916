import { FunctionComponent } from "react";

export const SearchHeaderIcon: FunctionComponent<IconProps> = ({
  className,
}) => (
  <svg
    width={25}
    height={24}
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m17.548 15.478 6.456 6.456a1.21 1.21 0 0 1-1.713 1.711l-6.455-6.456a9.6 9.6 0 1 1 1.71-1.71h.002ZM9.958 16.8a7.2 7.2 0 1 0 0-14.4 7.2 7.2 0 0 0 0 14.4Z"
    />
  </svg>
);
