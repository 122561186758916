import { FunctionComponent } from "react";

export const FacebookIcon: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    width="10"
    height="18"
    viewBox="0 0 10 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.70267 4.05595V6.59367H9.99914L9.47716 10.0121H6.70267V17.8882C6.1464 17.9616 5.57727 18 4.99957 18C4.33273 18 3.6779 17.9494 3.0402 17.8514V10.0121H0V6.59367H3.0402V3.48866C3.0402 1.56231 4.67987 0 6.70352 0V0.0016341C6.70952 0.0016341 6.71467 0 6.72067 0H10V2.95646H7.8572C7.22037 2.95646 6.70352 3.44866 6.70352 4.05514L6.70267 4.05595Z"
      fill="#202421"
    />
  </svg>
);
