import * as React from "react";
import { FunctionComponent } from "react";

export const Vector: FunctionComponent<IconProps> = ({
  className,
  mainColor = "#014157",
}) => (
  <svg
    width={7}
    height={12}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M5.9 10.602.998 5.722 5.9.84"
      stroke={mainColor}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Vector;
