import { FunctionComponent } from "react";

const ShowPasswordIcon: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="25"
    fill="none"
    viewBox="0 0 24 25"
    className={className}
  >
    <mask
      id="mask0_156_528"
      style={{ maskType: "alpha" }}
      width="24"
      height="25"
      x="0"
      y="0"
      maskUnits="userSpaceOnUse"
    >
      <path fill="#D9D9D9" d="M0 0.5H24V24.5H0z"></path>
    </mask>
    <g mask="url(#mask0_156_528)">
      <path
        fill="#212421"
        d="M12 16.5c1.25 0 2.313-.438 3.188-1.313.874-.874 1.312-1.937 1.312-3.187 0-1.25-.438-2.313-1.313-3.188C14.313 7.939 13.25 7.5 12 7.5c-1.25 0-2.313.438-3.188 1.313C7.939 9.687 7.5 10.75 7.5 12c0 1.25.438 2.313 1.313 3.188.874.874 1.937 1.312 3.187 1.312zm0-1.8c-.75 0-1.387-.262-1.912-.787A2.604 2.604 0 019.3 12c0-.75.262-1.387.787-1.912A2.604 2.604 0 0112 9.3c.75 0 1.387.262 1.912.787.525.526.788 1.163.788 1.913s-.262 1.387-.787 1.912A2.604 2.604 0 0112 14.7zm0 4.8c-2.433 0-4.65-.68-6.65-2.038C3.35 16.104 1.9 14.283 1 12c.9-2.283 2.35-4.104 4.35-5.463C7.35 5.18 9.567 4.5 12 4.5c2.433 0 4.65.68 6.65 2.037 2 1.359 3.45 3.18 4.35 5.463-.9 2.283-2.35 4.104-4.35 5.462-2 1.359-4.217 2.038-6.65 2.038zm0-2a9.544 9.544 0 005.188-1.488A9.773 9.773 0 0020.8 12a9.773 9.773 0 00-3.613-4.013A9.545 9.545 0 0012 6.5a9.545 9.545 0 00-5.188 1.487A9.773 9.773 0 003.2 12a9.773 9.773 0 003.612 4.012A9.544 9.544 0 0012 17.5z"
      ></path>
    </g>
  </svg>
);

const HidePasswordIcon: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    className={className}
  >
    <mask
      id="mask0_9720_24832"
      style={{ maskType: "alpha" }}
      width="24"
      height="24"
      x="0"
      y="0"
      maskUnits="userSpaceOnUse"
    >
      <path fill="#D9D9D9" d="M0 0H24V24H0z"></path>
    </mask>
    <g mask="url(#mask0_9720_24832)">
      <path
        fill="#202421"
        d="M16.1 13.302l-1.45-1.45c.183-.884-.046-1.638-.688-2.263-.641-.625-1.412-.87-2.312-.737l-1.45-1.45a4.178 4.178 0 011.8-.4c1.25 0 2.313.437 3.188 1.312.874.875 1.312 1.938 1.312 3.188a4.183 4.183 0 01-.4 1.8zm3.2 3.15l-1.45-1.4c.633-.484 1.2-1.021 1.7-1.613.5-.592.917-1.237 1.25-1.937a9.773 9.773 0 00-3.613-4.013A9.545 9.545 0 0012 6.002c-.483 0-.954.033-1.412.1-.459.066-.921.166-1.388.3l-1.55-1.55a11.51 11.51 0 014.35-.85c2.283 0 4.37.6 6.262 1.8 1.892 1.2 3.338 2.816 4.338 4.85a1.88 1.88 0 01.2.85c0 .15-.012.295-.038.437-.024.142-.07.28-.137.413-.367.816-.833 1.57-1.4 2.262a10.87 10.87 0 01-1.925 1.838zm-7.3 2.55c-2.233 0-4.275-.605-6.125-1.813A12.996 12.996 0 011.5 12.427a1.579 1.579 0 01-.188-.438 1.908 1.908 0 01-.062-.487c0-.167.017-.325.05-.475.033-.15.092-.3.175-.45a13.61 13.61 0 011.163-1.913c.441-.608.945-1.162 1.512-1.662l-2.075-2.1a.973.973 0 01-.263-.713.977.977 0 01.288-.687.948.948 0 01.7-.275c.283 0 .517.091.7.275l17 17c.183.183.28.412.288.687a.93.93 0 01-.288.713.948.948 0 01-.7.275.948.948 0 01-.7-.275l-3.5-3.45a11.92 11.92 0 01-3.6.55zm-6.45-10.6a10.74 10.74 0 00-1.325 1.425A9.014 9.014 0 003.2 11.502a9.773 9.773 0 003.612 4.012A9.545 9.545 0 0012 17.002c.333 0 .658-.021.975-.063.317-.042.642-.087.975-.137l-.9-.95c-.183.05-.358.087-.525.112a3.539 3.539 0 01-.525.038c-1.25 0-2.313-.438-3.188-1.313-.874-.875-1.312-1.937-1.312-3.187 0-.184.013-.359.037-.525.026-.167.063-.342.113-.525l-2.1-2.05z"
      ></path>
    </g>
  </svg>
);

export { ShowPasswordIcon, HidePasswordIcon };
