import { FunctionComponent } from "react";

export const KumuLoaderIcon: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="4" cy="4" r="2" fill="#202421" />
    <circle cx="4" cy="16" r="4" fill="#202421" />
    <circle cx="4" cy="28" r="4" fill="#202421" />
    <circle cx="16" cy="4" r="4" fill="#202421" />
    <circle cx="16" cy="16" r="4" fill="#202421" />
    <circle cx="16" cy="28" r="2" fill="#202421" />
    <circle cx="28" cy="4" r="4" fill="#202421" />
    <circle cx="28" cy="16" r="2" fill="#202421" />
    <circle cx="28" cy="28" r="4" fill="#202421" />
  </svg>
);
