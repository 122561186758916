import { FunctionComponent } from "react";

const PaymentIcon: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="12"
    fill="none"
    viewBox="0 0 16 12"
    className={className}
  >
    <path d="M14 .814H2a2 2 0 00-2 2v8.666h16V2.814a2 2 0 00-2-2zm.667 9.333H1.333V2.814A.667.667 0 012 2.147h12a.667.667 0 01.667.667v7.333zM8 3.481a2.667 2.667 0 100 5.333A2.667 2.667 0 008 3.48zm0 4a1.333 1.333 0 110-2.667A1.333 1.333 0 018 7.48zM4 4.147a.667.667 0 11-1.333 0 .667.667 0 011.333 0zm9.333 0a.667.667 0 11-1.334 0 .667.667 0 011.334 0zM4 8.147a.667.667 0 11-1.333 0 .667.667 0 011.333 0zm9.333 0a.667.667 0 11-1.334 0 .667.667 0 011.334 0z"></path>
  </svg>
);

export { PaymentIcon };
