import { FunctionComponent } from "react";

export const LikeIcon: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="20"
    height="18"
    viewBox="0 0 20 18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M14.5815 0C13.6435 0.0140802 12.726 0.266775 11.9215 0.732566C11.1171 1.19836 10.4543 1.86075 10 2.65285C9.54572 1.86075 8.8829 1.19836 8.07847 0.732566C7.27405 0.266775 6.35651 0.0140802 5.41852 0C3.92324 0.0626963 2.51444 0.693965 1.49991 1.75589C0.485388 2.81782 -0.0524034 4.22408 0.00403263 5.66746C0.00403263 11.1138 9.13035 17.4043 9.51853 17.6712L10 18L10.4815 17.6712C10.8696 17.4059 19.996 11.1138 19.996 5.66746C20.0524 4.22408 19.5146 2.81782 18.5001 1.75589C17.4856 0.693965 16.0768 0.0626963 14.5815 0ZM10 16.0208C7.29026 14.0673 1.67003 9.2681 1.67003 5.66746C1.61308 4.6503 1.97521 3.65268 2.67742 2.89217C3.37964 2.13166 4.36497 1.66997 5.41852 1.60779C6.47206 1.66997 7.45739 2.13166 8.15961 2.89217C8.86182 3.65268 9.22395 4.6503 9.167 5.66746H10.833C10.7761 4.6503 11.1382 3.65268 11.8404 2.89217C12.5426 2.13166 13.5279 1.66997 14.5815 1.60779C15.635 1.66997 16.6204 2.13166 17.3226 2.89217C18.0248 3.65268 18.3869 4.6503 18.33 5.66746C18.33 9.26971 12.7097 14.0673 10 16.0208Z" />
  </svg>
);
