import { FunctionComponent } from "react";

const BreathingIcon: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="69"
    height="73"
    viewBox="0 0 69 73"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M55.0229 64.037C60.7018 64.037 65.3055 59.4333 65.3055 53.7543C65.3055 48.0754 60.7018 43.4717 55.0229 43.4717C49.3439 43.4717 44.7402 48.0754 44.7402 53.7543C44.7402 59.4333 49.3439 64.037 55.0229 64.037Z"
      fill="#8E97FD"
    />
    <path
      d="M54.9307 72.3504L55.0149 53.6982"
      stroke="#3F3D56"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M55.0147 56.3359C56.4714 56.3359 57.6524 55.155 57.6524 53.6982C57.6524 52.2415 56.4714 51.0605 55.0147 51.0605C53.5579 51.0605 52.377 52.2415 52.377 53.6982C52.377 55.155 53.5579 56.3359 55.0147 56.3359Z"
      fill="#3F3D56"
    />
    <path
      d="M54.9626 65.1946C54.9626 65.1946 54.6224 57.0885 46.8936 57.9956L54.9626 65.1946Z"
      fill="#3F3D56"
    />
    <path
      d="M11.9487 43.3925C17.6276 43.3925 22.2313 38.7887 22.2313 33.1098C22.2313 27.4308 17.6276 22.8271 11.9487 22.8271C6.26972 22.8271 1.66602 27.4308 1.66602 33.1098C1.66602 38.7887 6.26972 43.3925 11.9487 43.3925Z"
      fill="#8E97FD"
    />
    <path
      d="M11.3662 71.0719L11.958 33.0996"
      stroke="#3F3D56"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M11.957 35.7373C13.4138 35.7373 14.5947 34.5564 14.5947 33.0996C14.5947 31.6429 13.4138 30.4619 11.957 30.4619C10.5003 30.4619 9.31934 31.6429 9.31934 33.0996C9.31934 34.5564 10.5003 35.7373 11.957 35.7373Z"
      fill="#3F3D56"
    />
    <path
      d="M11.7907 43.8394C11.7907 43.8394 11.5402 35.7301 3.80176 36.5516L11.7907 43.8394Z"
      fill="#3F3D56"
    />
    <path
      d="M11.3772 70.3467C11.3772 70.3467 13.3155 54.79 3.53125 53.8838"
      stroke="#3F3D56"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M3.53126 56.5215C4.98802 56.5215 6.16896 55.3406 6.16896 53.8838C6.16896 52.427 4.98802 51.2461 3.53126 51.2461C2.0745 51.2461 0.893555 52.427 0.893555 53.8838C0.893555 55.3406 2.0745 56.5215 3.53126 56.5215Z"
      fill="#3F3D56"
    />
    <path
      d="M34.0619 20.5653C39.7409 20.5653 44.3446 15.9616 44.3446 10.2827C44.3446 4.6037 39.7409 0 34.0619 0C28.383 0 23.7793 4.6037 23.7793 10.2827C23.7793 15.9616 28.383 20.5653 34.0619 20.5653Z"
      fill="#8E97FD"
    />
    <path
      d="M34.0674 72.5358V10.3203"
      stroke="#3F3D56"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M34.0674 12.9287C35.5242 12.9287 36.7051 11.7478 36.7051 10.291C36.7051 8.83426 35.5242 7.65332 34.0674 7.65332C32.6106 7.65332 31.4297 8.83426 31.4297 10.291C31.4297 11.7478 32.6106 12.9287 34.0674 12.9287Z"
      fill="#3F3D56"
    />
    <path
      d="M25.9649 57.9424C27.4216 57.9424 28.6026 56.7615 28.6026 55.3047C28.6026 53.8479 27.4216 52.667 25.9649 52.667C24.5081 52.667 23.3271 53.8479 23.3271 55.3047C23.3271 56.7615 24.5081 57.9424 25.9649 57.9424Z"
      fill="#3F3D56"
    />
    <path
      d="M34.0683 23.764C34.0683 23.764 33.6915 15.6595 25.9668 16.6016L34.0683 23.764Z"
      fill="#3F3D56"
    />
    <path
      d="M34.0683 46.7386C34.0683 46.7386 33.6915 38.6342 25.9668 39.5762L34.0683 46.7386Z"
      fill="#3F3D56"
    />
    <path
      d="M34.0683 71.8113C34.0683 71.8113 35.764 56.0583 25.9668 55.3047"
      stroke="#3F3D56"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M42.3301 33.1992C43.7869 33.1992 44.9678 32.0183 44.9678 30.5615C44.9678 29.1048 43.7869 27.9238 42.3301 27.9238C40.8733 27.9238 39.6924 29.1048 39.6924 30.5615C39.6924 32.0183 40.8733 33.1992 42.3301 33.1992Z"
      fill="#3F3D56"
    />
    <path
      d="M34.2293 46.2279C34.2293 46.2279 32.5336 31.3152 42.3308 30.5615"
      stroke="#3F3D56"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M56.0595 24.6035C55.3283 24.6035 54.6135 24.3866 54.0055 23.9804C53.3975 23.5741 52.9236 22.9967 52.6437 22.3211C52.3639 21.6455 52.2907 20.9021 52.4333 20.1849C52.576 19.4677 52.9281 18.809 53.4452 18.2919C53.9623 17.7748 54.6211 17.4227 55.3383 17.28C56.0554 17.1374 56.7988 17.2106 57.4744 17.4904C58.15 17.7703 58.7274 18.2441 59.1337 18.8521C59.5399 19.4602 59.7568 20.175 59.7568 20.9062C59.7557 21.8865 59.3658 22.8262 58.6727 23.5193C57.9795 24.2125 57.0398 24.6024 56.0595 24.6035ZM56.0595 17.5451C55.3948 17.5451 54.7449 17.7422 54.1922 18.1116C53.6395 18.4809 53.2087 19.0058 52.9543 19.62C52.6999 20.2341 52.6333 20.91 52.763 21.562C52.8927 22.2139 53.2128 22.8128 53.6829 23.2829C54.1529 23.753 54.7518 24.0731 55.4038 24.2028C56.0558 24.3325 56.7316 24.2659 57.3458 24.0115C57.96 23.7571 58.4849 23.3263 58.8542 22.7736C59.2236 22.2208 59.4207 21.571 59.4207 20.9062C59.4197 20.0151 59.0652 19.1608 58.4351 18.5307C57.805 17.9006 56.9507 17.5461 56.0595 17.5451Z"
      fill="#3F3D56"
    />
    <path
      d="M66.8156 32.6703C68.0222 32.6703 69.0003 31.6921 69.0003 30.4855C69.0003 29.2789 68.0222 28.3008 66.8156 28.3008C65.609 28.3008 64.6309 29.2789 64.6309 30.4855C64.6309 31.6921 65.609 32.6703 66.8156 32.6703Z"
      fill="#3F3D56"
    />
  </svg>
);

export { BreathingIcon };
export default BreathingIcon;
