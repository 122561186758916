import { FunctionComponent } from "react";

const userIcon: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    width={12}
    height={16}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    className={className}
  >
    <path d="M12 16h-1.334V12.64a1.973 1.973 0 0 0-1.971-1.972h-5.39a1.973 1.973 0 0 0-1.972 1.972V16H0V12.64a3.309 3.309 0 0 1 3.305-3.305h5.39a3.309 3.309 0 0 1 3.304 3.305V16ZM6 8a4 4 0 1 1 0-8 4 4 0 0 1 0 8Zm0-6.667a2.667 2.667 0 1 0 0 5.333 2.667 2.667 0 0 0 0-5.333Z" />
  </svg>
);

export { userIcon };
