import { FunctionComponent } from "react";

const StretchingIcon: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="80"
    height="54"
    viewBox="0 0 80 54"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M46.3387 8.80251C41.2257 8.62387 36.3564 6.97457 31.7093 5.16808C27.0622 3.3616 22.4532 1.35986 17.4627 0.402957C14.2493 -0.21259 10.5783 -0.299141 7.99201 1.42287C5.50336 3.0791 4.69897 5.93873 4.267 8.59133C3.94164 10.5875 3.75058 12.689 4.64151 14.5571C5.26038 15.8547 6.35899 16.9452 7.11908 18.1881C9.76487 22.5114 7.89579 27.8436 5.02917 32.0659C3.68481 34.0462 2.12586 35.9358 1.08887 38.0441C0.0518701 40.1525 -0.427861 42.5683 0.479683 44.7189C1.37961 46.8501 3.52213 48.4517 5.84395 49.5775C10.5596 51.8624 16.1163 52.5195 21.5346 52.8907C33.5292 53.7112 45.5876 53.356 57.6141 53.0001C62.0646 52.8685 66.5352 52.7349 70.913 52.0466C73.3448 51.6644 75.855 51.0579 77.6202 49.5927C79.861 47.7336 80.4162 44.5853 78.9147 42.2533C76.3963 38.3426 69.4309 37.3718 67.6718 33.1745C66.7027 30.8646 67.6981 28.2909 69.1062 26.1486C72.1272 21.5524 77.1903 17.5206 77.4575 12.2666C77.6403 8.65849 75.2063 5.04553 71.4425 3.3346C67.4967 1.54542 62.0279 1.77046 59.117 4.73256C56.1202 7.78606 50.8535 8.95969 46.3387 8.80251Z"
      fill="#8E97FD"
    />
    <path
      d="M34.7002 20.0078L27.2225 20.162L26 21.8404V27.6397L27.5264 28.708H34.7002L36.2266 27.0296V21.5342L34.7002 20.0078Z"
      fill="#393939"
    />
    <path
      d="M34.5422 20.923C34.5422 20.923 27.6746 21.9912 27.0645 20.923L27.2187 20.1609L34.5422 20V20.923Z"
      fill="#A492E2"
    />
    <path
      d="M35.3116 36.7952L36.5318 38.0177V43.8282L35.3116 45.0305C35.3116 45.0305 30.8866 46.5569 27.9858 45.3344L26.6113 43.9623V38.1517L27.8338 36.7773L35.3116 36.7952Z"
      fill="#393939"
    />
    <path
      d="M35.0036 38.1713C35.0036 38.1713 29.6624 39.3915 27.832 38.1713V36.7969H35.3098L35.0036 38.1713Z"
      fill="#A492E2"
    />
    <path
      d="M32.4088 28.6664V36.9218C32.3915 37.2487 32.2454 37.5555 32.0025 37.7749C31.7595 37.9944 31.4396 38.1087 31.1126 38.0929C30.7856 38.1087 30.4657 37.9944 30.2228 37.7749C29.9799 37.5555 29.8338 37.2487 29.8164 36.9218V28.6664C29.8164 27.9647 30.4869 27.4216 31.2646 27.5021C31.5654 27.5205 31.8488 27.6497 32.0601 27.8647C32.2714 28.0797 32.3956 28.3653 32.4088 28.6664Z"
      fill="#393939"
    />
    <path
      d="M46.6758 20.2432L39.198 20.3951L37.9756 22.0735V27.8729L39.502 28.9411H46.6758L48.2022 27.2627V21.7673L46.6758 20.2432Z"
      fill="#393939"
    />
    <path
      d="M46.5236 21.1572C46.5236 21.1572 39.656 22.2255 39.0459 21.1572L39.1979 20.3951L46.5236 20.2432V21.1572Z"
      fill="#A492E2"
    />
    <path
      d="M47.2832 37.0303L48.5034 38.2527V44.0633L47.2832 45.2723C47.2832 45.2723 42.856 46.7987 39.9574 45.5762L38.583 44.204V38.4047L39.8055 37.0303H47.2832Z"
      fill="#393939"
    />
    <path
      d="M46.9812 38.4047C46.9812 38.4047 41.6399 39.6249 39.8096 38.4047V37.0303H47.2829L46.9812 38.4047Z"
      fill="#A492E2"
    />
    <path
      d="M44.3788 28.8998V37.1552C44.3609 37.4819 44.2146 37.7883 43.9718 38.0077C43.7291 38.227 43.4095 38.3415 43.0826 38.3263C42.7554 38.3421 42.4352 38.2279 42.192 38.0085C41.9487 37.7891 41.8021 37.4823 41.7842 37.1552V28.8998C41.7842 28.1981 42.4546 27.655 43.2346 27.7355C43.5355 27.7539 43.8188 27.8831 44.0301 28.0981C44.2414 28.3131 44.3656 28.5987 44.3788 28.8998Z"
      fill="#393939"
    />
    <path
      d="M61.9234 24.6572L55.7664 24.7846L54.7607 26.1657V30.9393L56.0167 31.8198H61.9234L63.1794 30.4364V25.9132L61.9234 24.6572Z"
      fill="#393939"
    />
    <path
      d="M61.7954 25.4125C61.7954 25.4125 56.1435 26.2908 55.6406 25.4125L55.7658 24.7846L61.7999 24.6572L61.7954 25.4125Z"
      fill="#A492E2"
    />
    <path
      d="M62.4237 38.4785L63.4294 39.4842V44.2578L62.4237 45.2635C62.4237 45.2635 58.7809 46.5195 56.3896 45.5138L55.2588 44.3829V39.6093L56.2645 38.4785H62.4237Z"
      fill="#393939"
    />
    <path
      d="M62.173 39.6093C62.173 39.6093 57.7748 40.615 56.2686 39.6093V38.4785H62.4233L62.173 39.6093Z"
      fill="#A492E2"
    />
    <path
      d="M60.0366 31.7857V38.5819C60.0222 38.8511 59.9017 39.1036 59.7015 39.2842C59.5012 39.4647 59.2376 39.5585 58.9684 39.5451C58.6993 39.5585 58.436 39.4646 58.2361 39.284C58.0363 39.1034 57.9162 38.8509 57.9023 38.5819V31.7857C57.9109 31.642 57.9494 31.5016 58.0154 31.3736C58.0814 31.2457 58.1735 31.1329 58.2856 31.0426C58.3978 30.9523 58.5276 30.8865 58.6668 30.8494C58.8059 30.8122 58.9513 30.8046 59.0935 30.827C59.3413 30.8422 59.5748 30.9485 59.7489 31.1256C59.923 31.3026 60.0255 31.5377 60.0366 31.7857Z"
      fill="#393939"
    />
    <path
      d="M71.7815 24.8516L65.6245 24.9767L64.6211 26.3579V31.1338L65.8771 32.0121H71.7815L73.0375 30.631V26.1076L71.7815 24.8516Z"
      fill="#393939"
    />
    <path
      d="M71.656 25.6047C71.656 25.6047 66.0019 26.4853 65.499 25.6047L65.6242 24.9767L71.6582 24.8516L71.656 25.6047Z"
      fill="#A492E2"
    />
    <path
      d="M72.2818 38.6729L73.2853 39.6763V44.4499L72.2818 45.4556C72.2818 45.4556 68.6368 46.7116 66.2477 45.7082L65.1191 44.584V39.8037L66.1248 38.6729H72.2818Z"
      fill="#393939"
    />
    <path
      d="M72.0333 39.8037C72.0333 39.8037 67.6374 40.8071 66.1289 39.8037V38.6729H72.2859L72.0333 39.8037Z"
      fill="#A492E2"
    />
    <path
      d="M69.8973 31.9788V38.7727C69.8828 39.0419 69.7623 39.2944 69.5621 39.4749C69.3619 39.6555 69.0983 39.7493 68.829 39.7359C68.5597 39.7493 68.2961 39.6555 68.0959 39.4749C67.8957 39.2944 67.7752 39.0419 67.7607 38.7727V31.9788C67.7607 31.4022 68.3194 30.9552 68.9541 31.0223C69.2018 31.037 69.4352 31.1428 69.6094 31.3195C69.7835 31.4961 69.8861 31.731 69.8973 31.9788Z"
      fill="#393939"
    />
    <path
      d="M16.8804 24.8865C16.8804 24.8865 17.2103 24.4202 16.4882 24.3251L16.4146 23.623L16.8387 23.5786C16.7489 22.7304 16.3775 21.938 15.7866 21.334C15.1956 20.7299 14.421 20.3508 13.5922 20.2599L13.503 19.4083L13.6137 19.3967L13.4977 18.2892L10.7718 18.5748L10.8878 19.6823L10.9655 19.6741L11.0558 20.5354C10.2723 20.8038 9.60286 21.3379 9.15784 22.0496C8.71282 22.7614 8.51876 23.6082 8.60767 24.4507L8.98229 24.4114L9.05281 25.0846C9.05281 25.0846 8.20512 25.61 9.1792 26.2909C10.1533 26.9718 10.0729 27.5461 10.0729 27.5461C10.0729 27.5461 9.84997 29.033 9.11811 29.2999L10.5243 42.7217C10.565 43.1135 10.6808 43.4937 10.8649 43.8404C11.0491 44.1872 11.298 44.4938 11.5975 44.7426C11.8969 44.9914 12.2411 45.1776 12.6103 45.2906C12.9795 45.4036 13.3664 45.4411 13.749 45.4011L16.0509 45.1599C16.8238 45.0789 17.5322 44.6868 18.0201 44.0698C18.508 43.4529 18.7356 42.6616 18.6526 41.8701L17.2465 28.4483C17.2465 28.4483 15.8235 27.5754 15.9937 26.9258C16.2397 26.2244 16.5361 25.5427 16.8804 24.8865Z"
      fill="#EBBD46"
    />
  </svg>
);

export { StretchingIcon };
export default StretchingIcon;
