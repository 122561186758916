import { FunctionComponent } from "react";

const ArrowBackLive: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="arrow">
      <circle
        id="Ellipse 774"
        cx="12"
        cy="12"
        r="12"
        transform="matrix(-1 0 0 1 24 0)"
        fill="white"
        fillOpacity="0.1"
      />
      <path
        id="Vector"
        d="M13.5603 8.3061L10.1992 11.6528L13.5603 15"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export { ArrowBackLive };
