import { FunctionComponent } from "react";

const CreatorsIcon: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M11.7079 4.67003L0.688238 15.6913C0.235462 16.1768 -0.0111458 16.8192 0.000387014 17.4829C0.0119198 18.1467 0.280693 18.78 0.75006 19.2495C1.21943 19.719 1.85273 19.9879 2.51649 19.9996C3.18025 20.0113 3.82262 19.7648 4.30822 19.3122L15.3304 8.29253L11.7079 4.67003ZM12.9661 8.29253L10.8041 10.4545L9.5501 9.19627L11.7121 7.03431L12.9661 8.29253ZM3.12609 18.1317C2.95708 18.2928 2.73255 18.3827 2.49907 18.3827C2.26558 18.3827 2.04105 18.2928 1.87205 18.1317C1.70603 17.9653 1.61279 17.7398 1.61279 17.5047C1.61279 17.2696 1.70603 17.0441 1.87205 16.8777L8.36712 10.3818L9.62534 11.64L3.12609 18.1317ZM17.7707 12.2294L20.0004 13.3446L17.7707 14.4632L16.6563 16.6887L15.5419 14.4632L13.3122 13.3446L15.5419 12.2294L16.6563 10.0005L17.7707 12.2294ZM5.50959 4.45852L3.27991 3.3441L5.50959 2.22968L6.62401 0L7.73843 2.22968L9.96811 3.3441L7.73843 4.45852L6.62401 6.6882L5.50959 4.45852ZM16.0987 3.90173L14.1482 2.92609L16.0987 1.95045L17.0743 0L18.05 1.95045L20.0004 2.92609L18.05 3.90173L17.0743 5.85217L16.0987 3.90173Z" />
  </svg>
);

export { CreatorsIcon };
