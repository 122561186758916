import { FunctionComponent } from "react";

const BookmarkIcon: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    width={12}
    height={16}
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path d="M10.168.5H1.835C.918.5.176 1.25.176 2.167L.168 15.5 6.001 13l5.834 2.5V2.167c0-.917-.75-1.667-1.667-1.667Zm0 12.5-4.167-1.817L1.835 13V2.167h8.333V13Z" />
  </svg>
);

export { BookmarkIcon };
