import { FunctionComponent } from "react";

export const TimeIcon: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width={14}
    height={14}
  >
    <g fill="#8CBCC0" fillRule="evenodd" clipRule="evenodd">
      <path d="M7 1.273a5.727 5.727 0 1 0 0 11.454A5.727 5.727 0 0 0 7 1.273ZM0 7a7 7 0 1 1 14 0A7 7 0 0 1 0 7Z" />
      <path d="M6.999 2.545c.351 0 .636.285.636.637v3.425l2.194 1.096a.636.636 0 0 1-.57 1.139L6.714 7.569A.636.636 0 0 1 6.362 7V3.183c0-.352.285-.637.637-.637Z" />
    </g>
  </svg>
);
