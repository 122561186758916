import { FunctionComponent } from "react";

const NutritionalIcon: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="83"
    height="46"
    viewBox="0 0 83 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M35.6239 25.3747C35.3311 24.9395 35.1782 24.4251 35.1858 23.9006C35.1934 23.3761 35.361 22.8664 35.6663 22.4398C36.2954 21.5976 37.1328 20.9335 38.0962 20.5129C40.0851 19.5007 42.2273 18.8237 44.4367 18.5092C46.1156 18.2709 47.9482 18.1948 49.2505 17.1088C50.5442 16.0298 51.2656 14.0124 52.9418 13.844C54.5625 13.6812 55.8083 15.4551 57.4334 15.5652C59.4364 15.7009 60.7479 13.3566 62.697 12.8758C64.0017 12.5539 65.3433 13.1219 66.5671 13.6771L73.9113 17.009L77.6757 18.7168C78.9906 19.3134 80.4735 20.1167 80.7217 21.5391C81.0628 23.4935 78.8894 24.8725 77.0308 25.5663C63.7599 30.464 49.177 30.4658 35.9049 25.5715"
      fill="#6C63FF"
    />
    <path
      d="M60.4735 45.1456H54.9594C49.0305 45.1389 43.3464 42.7807 39.154 38.5883C34.9616 34.396 32.6034 28.7118 32.5967 22.7829V22.4766H82.8363V22.7829C82.8296 28.7118 80.4714 34.396 76.279 38.5883C72.0866 42.7807 66.4025 45.1389 60.4735 45.1456Z"
      fill="#F5F6FD"
    />
    <path
      d="M54.9594 14.511C54.9594 16.0337 52.2024 17.2681 52.2024 17.2681C52.2024 17.2681 49.4453 16.0337 49.4453 14.511C49.4453 12.9883 52.2024 11.7539 52.2024 11.7539C52.2024 11.7539 54.9594 12.9883 54.9594 14.511Z"
      fill="#3F3D56"
    />
    <path
      d="M71.5014 14.511C71.5014 16.0337 68.7444 17.2681 68.7444 17.2681C68.7444 17.2681 65.9873 16.0337 65.9873 14.511C65.9873 12.9883 68.7444 11.7539 68.7444 11.7539C68.7444 11.7539 71.5014 12.9883 71.5014 14.511Z"
      fill="#3F3D56"
    />
    <path
      d="M60.4731 20.0256C60.4731 21.5483 57.716 22.7827 57.716 22.7827C57.716 22.7827 54.959 21.5483 54.959 20.0256C54.959 18.5029 57.716 17.2686 57.716 17.2686C57.716 17.2686 60.4731 18.5029 60.4731 20.0256Z"
      fill="#3F3D56"
    />
    <path
      d="M41.174 8.69183C42.6967 8.69183 43.9311 7.45746 43.9311 5.93478C43.9311 4.41211 42.6967 3.17773 41.174 3.17773C39.6514 3.17773 38.417 4.41211 38.417 5.93478C38.417 7.45746 39.6514 8.69183 41.174 8.69183Z"
      fill="#3F3D56"
    />
    <path
      d="M1.0768 0.00892029L44.5602 6.01438C44.9536 6.06867 45.3319 6.20212 45.6722 6.4067C46.0126 6.61127 46.3079 6.88272 46.5405 7.20464C46.773 7.52655 46.9379 7.89226 47.0251 8.27967C47.1123 8.66708 47.1202 9.06816 47.0481 9.45867C46.976 9.84918 46.8255 10.221 46.6056 10.5517C46.3858 10.8825 46.1013 11.1652 45.7691 11.3829C45.437 11.6006 45.0642 11.7486 44.6732 11.8182C44.2822 11.8878 43.8812 11.8774 43.4944 11.7876L0.733272 1.86984C0.495784 1.81479 0.288683 1.67028 0.155035 1.4664C0.0213857 1.26251 -0.0285174 1.01494 0.0157385 0.775204C0.0599944 0.535469 0.194988 0.32204 0.392623 0.179312C0.590258 0.0365831 0.835312 -0.0244552 1.0768 0.00892029Z"
      fill="#F5F6FD"
    />
  </svg>
);

export { NutritionalIcon };
export default NutritionalIcon;
