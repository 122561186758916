import { classNames } from "@/utils/classNames";
import { FunctionComponent } from "react";

export const ArrowUp: FunctionComponent<IconProps> = ({ className }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        fill="none"
        viewBox="0 0 24 24"
        stroke="#fff"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={classNames("feather feather-chevron-up w-4 h-4", className)}
    >
        <polyline points="18 15 12 9 6 15"></polyline>
    </svg>
);