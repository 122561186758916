import { FunctionComponent } from "react";

const CashIcon: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="7"
    height="12"
    viewBox="0 0 7 12"
    className={className}
  >
    <path d="M7 4v-.466A2.537 2.537 0 0 0 4.466 1H4V0H3v1h-.466a2.534 2.534 0 0 0-.8 4.938L3 6.36V10h-.466A1.535 1.535 0 0 1 1 8.466V8H0v.466A2.537 2.537 0 0 0 2.534 11H3v1h1v-1h.466a2.534 2.534 0 0 0 .8-4.938L4 5.64V2h.466A1.535 1.535 0 0 1 6 3.534V4h1ZM4.95 7.01A1.534 1.534 0 0 1 4.466 10H4V6.694l.95.317ZM3 5.307l-.95-.317A1.534 1.534 0 0 1 2.534 2H3v3.306Z" />
  </svg>
);

export { CashIcon };
