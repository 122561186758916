import { classNames } from "@/utils/classNames";
import { FunctionComponent } from "react";

export const TrashIcon: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    className={classNames(className, "fill-current")}
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M13.333 2.667H10V1.333A1.333 1.333 0 0 0 8.667 0h-4a1.333 1.333 0 0 0-1.334 1.333v1.334H0V4h1.333v10a2 2 0 0 0 2 2H10a2 2 0 0 0 2-2V4h1.333V2.667ZM4.667 1.333h4v1.334h-4V1.333Zm6 12.667a.667.667 0 0 1-.667.667H3.333A.667.667 0 0 1 2.667 14V4h8v10Z" />
    <path d="M6 6.668H4.667v5.333H6V6.668ZM8.666 6.668H7.333v5.333h1.333V6.668Z" />
  </svg>
);
