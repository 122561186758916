import { FunctionComponent } from "react";

const TopicFitness: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    width={60}
    height={22}
    viewBox="0 0 60 22"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path d="M58.023 3.573h-3.365V1.357A1.357 1.357 0 0 0 53.278 0h-7.266a1.356 1.356 0 0 0-1.38 1.357v5.426H14.725V1.357A1.357 1.357 0 0 0 13.367 0h-7.29a1.357 1.357 0 0 0-1.356 1.357v2.216H1.357A1.384 1.384 0 0 0 0 4.952v12.087a1.384 1.384 0 0 0 1.357 1.357H4.72v2.225A1.384 1.384 0 0 0 6.078 22h7.267a1.356 1.356 0 0 0 1.356-1.38v-5.426h29.914v5.427A1.355 1.355 0 0 0 45.97 22h7.272a1.357 1.357 0 0 0 1.356-1.38V18.4h3.365a1.356 1.356 0 0 0 1.379-1.356V4.952a1.384 1.384 0 0 0-1.32-1.38ZM2.709 15.646V6.332h1.99v9.316h-1.99Zm9.26 3.618H7.449V2.732h4.522v16.532Zm2.832-6.81V9.542h29.845v2.907l-29.845.005Zm37.08 6.81H47.36V2.732h4.522v16.532Zm4.744-3.617h-1.99V6.33h1.99v9.316Z" />
  </svg>
);

export { TopicFitness };
