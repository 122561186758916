import { FunctionComponent } from "react";

export const InfoIcon: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.0013 13.166C9.23741 13.166 9.43533 13.0862 9.59505 12.9264C9.75478 12.7667 9.83464 12.5688 9.83464 12.3327V8.99935C9.83464 8.76324 9.75478 8.56532 9.59505 8.4056C9.43533 8.24588 9.23741 8.16602 9.0013 8.16602C8.76519 8.16602 8.56728 8.24588 8.40755 8.4056C8.24783 8.56532 8.16797 8.76324 8.16797 8.99935V12.3327C8.16797 12.5688 8.24783 12.7667 8.40755 12.9264C8.56728 13.0862 8.76519 13.166 9.0013 13.166ZM9.0013 6.49935C9.23741 6.49935 9.43533 6.41949 9.59505 6.25977C9.75478 6.10004 9.83464 5.90213 9.83464 5.66602C9.83464 5.4299 9.75478 5.23199 9.59505 5.07227C9.43533 4.91254 9.23741 4.83268 9.0013 4.83268C8.76519 4.83268 8.56728 4.91254 8.40755 5.07227C8.24783 5.23199 8.16797 5.4299 8.16797 5.66602C8.16797 5.90213 8.24783 6.10004 8.40755 6.25977C8.56728 6.41949 8.76519 6.49935 9.0013 6.49935ZM9.0013 17.3327C7.84852 17.3327 6.76519 17.1139 5.7513 16.6764C4.73741 16.2389 3.85547 15.6452 3.10547 14.8952C2.35547 14.1452 1.76172 13.2632 1.32422 12.2493C0.886719 11.2355 0.667969 10.1521 0.667969 8.99935C0.667969 7.84657 0.886719 6.76324 1.32422 5.74935C1.76172 4.73546 2.35547 3.85352 3.10547 3.10352C3.85547 2.35352 4.73741 1.75977 5.7513 1.32227C6.76519 0.884766 7.84852 0.666016 9.0013 0.666016C10.1541 0.666016 11.2374 0.884766 12.2513 1.32227C13.2652 1.75977 14.1471 2.35352 14.8971 3.10352C15.6471 3.85352 16.2409 4.73546 16.6784 5.74935C17.1159 6.76324 17.3346 7.84657 17.3346 8.99935C17.3346 10.1521 17.1159 11.2355 16.6784 12.2493C16.2409 13.2632 15.6471 14.1452 14.8971 14.8952C14.1471 15.6452 13.2652 16.2389 12.2513 16.6764C11.2374 17.1139 10.1541 17.3327 9.0013 17.3327ZM9.0013 15.666C10.8624 15.666 12.4388 15.0202 13.7305 13.7285C15.0221 12.4368 15.668 10.8605 15.668 8.99935C15.668 7.13824 15.0221 5.56185 13.7305 4.27018C12.4388 2.97852 10.8624 2.33268 9.0013 2.33268C7.14019 2.33268 5.5638 2.97852 4.27214 4.27018C2.98047 5.56185 2.33464 7.13824 2.33464 8.99935C2.33464 10.8605 2.98047 12.4368 4.27214 13.7285C5.5638 15.0202 7.14019 15.666 9.0013 15.666Z"
      fill="#202421"
    />
  </svg>
);
