import { FunctionComponent } from "react";

export const CategoriesIcon: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M5.83333 0H3.33333C2.44928 0 1.60143 0.35119 0.976311 0.976311C0.35119 1.60143 0 2.44928 0 3.33333L0 5.83333C0 6.71739 0.35119 7.56524 0.976311 8.19036C1.60143 8.81548 2.44928 9.16667 3.33333 9.16667H5.83333C6.71739 9.16667 7.56524 8.81548 8.19036 8.19036C8.81548 7.56524 9.16667 6.71739 9.16667 5.83333V3.33333C9.16667 2.44928 8.81548 1.60143 8.19036 0.976311C7.56524 0.35119 6.71739 0 5.83333 0V0ZM7.5 5.83333C7.5 6.27536 7.32441 6.69928 7.01185 7.01185C6.69928 7.32441 6.27536 7.5 5.83333 7.5H3.33333C2.89131 7.5 2.46738 7.32441 2.15482 7.01185C1.84226 6.69928 1.66667 6.27536 1.66667 5.83333V3.33333C1.66667 2.89131 1.84226 2.46738 2.15482 2.15482C2.46738 1.84226 2.89131 1.66667 3.33333 1.66667H5.83333C6.27536 1.66667 6.69928 1.84226 7.01185 2.15482C7.32441 2.46738 7.5 2.89131 7.5 3.33333V5.83333Z" />
    <path d="M16.6663 0H14.1663C13.2823 0 12.4344 0.35119 11.8093 0.976311C11.1842 1.60143 10.833 2.44928 10.833 3.33334V5.83334C10.833 6.71739 11.1842 7.56524 11.8093 8.19036C12.4344 8.81548 13.2823 9.16667 14.1663 9.16667H16.6663C17.5504 9.16667 18.3982 8.81548 19.0234 8.19036C19.6485 7.56524 19.9997 6.71739 19.9997 5.83334V3.33334C19.9997 2.44928 19.6485 1.60143 19.0234 0.976311C18.3982 0.35119 17.5504 0 16.6663 0V0ZM18.333 5.83334C18.333 6.27536 18.1574 6.69929 17.8448 7.01185C17.5323 7.32441 17.1084 7.5 16.6663 7.5H14.1663C13.7243 7.5 13.3004 7.32441 12.9878 7.01185C12.6753 6.69929 12.4997 6.27536 12.4997 5.83334V3.33334C12.4997 2.89131 12.6753 2.46738 12.9878 2.15482C13.3004 1.84226 13.7243 1.66667 14.1663 1.66667H16.6663C17.1084 1.66667 17.5323 1.84226 17.8448 2.15482C18.1574 2.46738 18.333 2.89131 18.333 3.33334V5.83334Z" />
    <path d="M5.83333 10.833H3.33333C2.44928 10.833 1.60143 11.1842 0.976311 11.8093C0.35119 12.4344 0 13.2823 0 14.1663L0 16.6663C0 17.5504 0.35119 18.3983 0.976311 19.0234C1.60143 19.6485 2.44928 19.9997 3.33333 19.9997H5.83333C6.71739 19.9997 7.56524 19.6485 8.19036 19.0234C8.81548 18.3983 9.16667 17.5504 9.16667 16.6663V14.1663C9.16667 13.2823 8.81548 12.4344 8.19036 11.8093C7.56524 11.1842 6.71739 10.833 5.83333 10.833ZM7.5 16.6663C7.5 17.1084 7.32441 17.5323 7.01185 17.8449C6.69928 18.1574 6.27536 18.333 5.83333 18.333H3.33333C2.89131 18.333 2.46738 18.1574 2.15482 17.8449C1.84226 17.5323 1.66667 17.1084 1.66667 16.6663V14.1663C1.66667 13.7243 1.84226 13.3004 2.15482 12.9878C2.46738 12.6753 2.89131 12.4997 3.33333 12.4997H5.83333C6.27536 12.4997 6.69928 12.6753 7.01185 12.9878C7.32441 13.3004 7.5 13.7243 7.5 14.1663V16.6663Z" />
    <path d="M16.6663 10.833H14.1663C13.2823 10.833 12.4344 11.1842 11.8093 11.8093C11.1842 12.4344 10.833 13.2823 10.833 14.1663V16.6663C10.833 17.5504 11.1842 18.3983 11.8093 19.0234C12.4344 19.6485 13.2823 19.9997 14.1663 19.9997H16.6663C17.5504 19.9997 18.3982 19.6485 19.0234 19.0234C19.6485 18.3983 19.9997 17.5504 19.9997 16.6663V14.1663C19.9997 13.2823 19.6485 12.4344 19.0234 11.8093C18.3982 11.1842 17.5504 10.833 16.6663 10.833ZM18.333 16.6663C18.333 17.1084 18.1574 17.5323 17.8448 17.8449C17.5323 18.1574 17.1084 18.333 16.6663 18.333H14.1663C13.7243 18.333 13.3004 18.1574 12.9878 17.8449C12.6753 17.5323 12.4997 17.1084 12.4997 16.6663V14.1663C12.4997 13.7243 12.6753 13.3004 12.9878 12.9878C13.3004 12.6753 13.7243 12.4997 14.1663 12.4997H16.6663C17.1084 12.4997 17.5323 12.6753 17.8448 12.9878C18.1574 13.3004 18.333 13.7243 18.333 14.1663V16.6663Z" />
  </svg>
);
