import { FunctionComponent } from "react";

const ComplainMessage: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Icon message">
      <path
        id="Vector"
        d="M18.5 12.5V18.5H12.5C11.4478 18.4989 10.4144 18.2212 9.50349 17.6946C8.59255 17.1681 7.83609 16.4112 7.31 15.5C7.8754 15.496 8.43888 15.4336 8.9915 15.314C9.41236 15.8402 9.94625 16.265 10.5536 16.5569C11.1609 16.8487 11.8262 17.0002 12.5 17H17V12.5C16.9998 11.8259 16.8479 11.1605 16.5555 10.5532C16.2631 9.94582 15.8378 9.41206 15.311 8.9915C15.4316 8.43899 15.495 7.87551 15.5 7.31C16.4112 7.83609 17.1681 8.59255 17.6946 9.50349C18.2212 10.4144 18.4989 11.4478 18.5 12.5ZM14 7.25C14 5.45979 13.2888 3.7429 12.023 2.47703C10.7571 1.21116 9.04021 0.5 7.25 0.5C5.45979 0.5 3.7429 1.21116 2.47703 2.47703C1.21116 3.7429 0.5 5.45979 0.5 7.25L0.5 14H7.25C9.0396 13.998 10.7553 13.2862 12.0208 12.0208C13.2862 10.7553 13.998 9.0396 14 7.25ZM2 7.25C2 6.21165 2.30791 5.19661 2.88478 4.33326C3.46166 3.4699 4.2816 2.79699 5.24091 2.39963C6.20022 2.00227 7.25582 1.8983 8.27422 2.10088C9.29262 2.30345 10.2281 2.80346 10.9623 3.53769C11.6965 4.27191 12.1965 5.20738 12.3991 6.22578C12.6017 7.24418 12.4977 8.29978 12.1004 9.25909C11.703 10.2184 11.0301 11.0383 10.1667 11.6152C9.30338 12.1921 8.28835 12.5 7.25 12.5H2V7.25Z"
        fill="#014157"
      />
      <g id="Group 6856">
        <circle
          id="Ellipse 87"
          cx="4.89102"
          cy="7.5873"
          r="0.7875"
          fill="#014157"
        />
        <circle
          id="Ellipse 88"
          cx="7.25039"
          cy="7.5873"
          r="0.7875"
          fill="#014157"
        />
        <circle
          id="Ellipse 89"
          cx="9.61514"
          cy="7.5873"
          r="0.7875"
          fill="#014157"
        />
      </g>
    </g>
  </svg>
);

export { ComplainMessage };
