import { FunctionComponent } from "react";

const ToRightArrowIcon: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="6"
    height="10"
    viewBox="0 0 6 10"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1.62024 10L0.568359 8.94588L4.51125 5L0.568359 1.05412L1.62323 0L5.56313 3.94588C5.84265 4.22548 5.99967 4.60465 5.99967 5C5.99967 5.39535 5.84265 5.77452 5.56313 6.05412L1.62024 10Z" />
  </svg>
);

export { ToRightArrowIcon };
