import { classNames } from "@/utils/classNames";
import { FunctionComponent } from "react";

export const ToastCheckMobile: FunctionComponent<IconProps> = ({
  className,
  viewBox,
}) => (
  <svg
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g id="Group 215">
      <circle id="Ellipse 140" cx="12" cy="12" r="12" fill="white" />
      <path
        id="Vector 104"
        d="M8.00403 11.8643L11.0132 14.7284L17.032 9"
        stroke="#03BD93"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
