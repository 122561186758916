import { FunctionComponent } from "react";

export const ContentIcon: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    className={className}
    viewBox="0 0 17 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M4.16667 10.2856H12.5V11.9523H4.16667V10.2856ZM4.16667 15.2856H10V13.619H4.16667V15.2856ZM16.6667 6.60731V20.2856H0V2.78564C0 2.1226 0.263392 1.48672 0.732233 1.01788C1.20107 0.549037 1.83696 0.285645 2.5 0.285645L10.345 0.285645L16.6667 6.60731ZM10.8333 6.11898H13.8217L10.8333 3.13064V6.11898ZM15 18.619V7.78564H9.16667V1.95231H2.5C2.27899 1.95231 2.06702 2.04011 1.91074 2.19639C1.75446 2.35267 1.66667 2.56463 1.66667 2.78564V18.619H15Z" />
  </svg>
);
