import React, { FunctionComponent } from "react";
import { Label } from "../label";
import { classNames } from "@/utils/classNames";

type Props = {
  className?: string;
  text?: string;
};

const ErrorMessage: FunctionComponent<Props> = ({ className, text }) => (
  <Label
    textAlignment="left"
    fontSize="sm"
    className={classNames("mt-2 text-red-2", className)}
  >
    {text}
  </Label>
);

export { ErrorMessage };
