import { FunctionComponent } from "react";

export const SixtyLogoBig: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="50"
    height="50"
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M42.2513 49.9412C40.6641 49.968 39.4456 49.3609 38.3799 48.4368C36.9374 47.186 35.6499 45.7724 34.2982 44.4265C31.2898 41.4306 28.2924 38.4236 25.2989 35.4128C25.0616 35.1741 24.9251 35.1458 24.6694 35.4028C20.7657 39.3261 16.8484 43.236 12.9412 47.1559C12.2442 47.8553 11.5397 48.5384 10.7054 49.0779C9.12086 50.1026 7.43808 50.3064 5.72726 49.5221C3.25207 48.3875 1.4396 46.5334 0.399444 44.0177C-0.420792 42.034 0.0787017 40.1989 1.38183 38.5586C2.15431 37.5862 3.09705 36.7742 3.95943 35.8866C4.66376 35.1616 5.38757 34.4556 6.10099 33.7394C8.90117 30.9286 11.7007 28.1172 14.5004 25.3059C14.8442 24.9606 14.8444 24.9595 14.482 24.5967C10.6597 20.7706 6.83549 16.9465 3.01631 13.1172C2.28257 12.3815 1.62373 11.5856 1.12409 10.665C-0.147583 8.32215 -0.0341758 6.07122 1.54679 3.90857C2.29804 2.88098 3.1649 1.96753 4.2124 1.23914C6.70927 -0.497278 9.1997 -0.405888 11.573 1.49526C11.9387 1.78816 12.2783 2.10829 12.609 2.43919C24.2372 14.0775 35.8655 25.7157 47.4953 37.3523C48.1138 37.9712 48.6808 38.6286 49.1457 39.3729C50.1156 40.9254 50.2842 42.5712 49.5251 44.223C48.354 46.7715 46.4384 48.5935 43.8323 49.6599C43.2902 49.8817 42.7147 49.9733 42.2513 49.9412Z"
      fill="#014157"
    />
    <path
      d="M27.0684 6.53121C27.2091 4.06359 28.3752 1.78847 31.0872 0.559062C33.5046 -0.536787 36.0272 0.0551641 37.9045 2.24747C38.0604 2.42957 38.207 2.62039 38.3476 2.81462C38.4726 2.98739 38.5607 2.97677 38.6875 2.80597C39.0487 2.31952 39.4456 1.8697 39.8999 1.45893C42.1998 -0.619986 45.5957 -0.259962 47.6924 1.67433C50.4546 4.22248 50.7445 8.69848 48.3511 11.5745C47.4465 12.6616 46.372 13.5959 45.3618 14.5862C44.4781 15.4526 43.5702 16.2944 42.6753 17.1495C41.479 18.2926 40.2746 19.4269 39.0186 20.5048C38.524 20.9292 38.5102 20.945 38.0118 20.5107C37.2121 19.814 36.4151 19.1126 35.6467 18.382C34.0175 16.8327 32.4121 15.2582 30.7872 13.7042C30.0936 13.0408 29.3761 12.4017 28.7551 11.6671C27.6054 10.3071 27.0733 8.72495 27.0684 6.53121Z"
      fill="#03BD93"
    />
  </svg>
);
