import { FunctionComponent } from "react";

const CartIcon: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="16"
    fill="none"
    viewBox="0 0 17 16"
    className={className}
  >
    <path d="M16.5 2H3.328L3.3 1.766A2 2 0 001.315 0H.5v1.333h.815a.667.667 0 01.662.589L3.034 10.9a2 2 0 001.985 1.766h8.814v-1.333H5.02a.666.666 0 01-.662-.59L4.269 10h10.788L16.5 2zm-2.557 6.666h-9.83l-.628-5.333h11.42l-.962 5.333zM5.167 16a1.333 1.333 0 100-2.667 1.333 1.333 0 000 2.667zM11.833 16a1.333 1.333 0 100-2.667 1.333 1.333 0 000 2.667z"></path>
  </svg>
);

export { CartIcon };
