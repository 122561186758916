import { FunctionComponent } from "react";

const AddToCalendarWhite: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    className={className}
  >
    <g clipPath="url(#clip0_2892_682)">
      <path
        d="M8 9V13"
        stroke="white"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 11H10"
        stroke="white"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.5 2.5H1.5C0.947715 2.5 0.5 2.94772 0.5 3.5V14.5C0.5 15.0523 0.947715 15.5 1.5 15.5H14.5C15.0523 15.5 15.5 15.0523 15.5 14.5V3.5C15.5 2.94772 15.0523 2.5 14.5 2.5Z"
        stroke="white"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <path
        d="M0.5 6.5H15.5"
        stroke="white"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <path
        d="M4.5 4V0.5"
        stroke="white"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5 4V0.5"
        stroke="white"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2892_682">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export { AddToCalendarWhite };
