import { FunctionComponent } from "react";

export const TikTokIcon: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    width="12"
    height="14"
    viewBox="0 0 12 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 3.38988V5.80289C11.5828 5.7617 11.0407 5.6659 10.441 5.44385C9.65793 5.15375 9.07507 4.75711 8.69329 4.45V9.32707L8.68355 9.31184C8.68975 9.40854 8.69329 9.50703 8.69329 9.60642C8.69329 12.0284 6.74363 14 4.34664 14C1.94966 14 0 12.0284 0 9.60642C0 7.18444 1.94966 5.21196 4.34664 5.21196C4.58138 5.21196 4.8117 5.23075 5.03669 5.26746V7.64557C4.82055 7.56767 4.58847 7.52558 4.34664 7.52558C3.21193 7.52558 2.28804 8.45856 2.28804 9.60642C2.28804 10.7543 3.21193 11.6873 4.34664 11.6873C5.48136 11.6873 6.40525 10.7534 6.40525 9.60642C6.40525 9.56344 6.40437 9.52047 6.40171 9.47749V0H8.78718C8.79604 0.204144 8.80402 0.410089 8.81288 0.614233C8.82882 1.01625 8.97054 1.40216 9.21768 1.71823C9.50734 2.08981 9.93519 2.52136 10.5358 2.86608C11.0982 3.18751 11.6262 3.32631 12 3.39167V3.38988Z"
      fill="#202421"
    />
  </svg>
);
