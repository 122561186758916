import { FunctionComponent } from "react";

const TopicCoaching: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    width={50}
    height={37}
    viewBox="0 0 50 37"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path d="M46.563 26.404a1.979 1.979 0 0 0-2.632-.313 32.147 32.147 0 0 1-37.833 0 1.979 1.979 0 0 0-2.632.313 1.978 1.978 0 0 0 .173 2.794l.162.127a36.15 36.15 0 0 0 42.427 0 1.979 1.979 0 0 0 .335-2.921ZM16.765 9.64a1.979 1.979 0 0 1-1.909-1.487 5.68 5.68 0 0 0-10.962 0A1.984 1.984 0 0 1 1.985 9.64 1.984 1.984 0 0 1 .076 7.112a9.638 9.638 0 0 1 18.598 0 1.99 1.99 0 0 1-1.909 2.528ZM48.05 9.64a1.99 1.99 0 0 1-1.914-1.487 5.68 5.68 0 0 0-10.992 0 1.979 1.979 0 0 1-1.909 1.487 1.99 1.99 0 0 1-1.903-2.528 9.632 9.632 0 0 1 18.593 0 1.984 1.984 0 0 1-1.377 2.453 1.81 1.81 0 0 1-.498.075Z" />
  </svg>
);

export { TopicCoaching };
