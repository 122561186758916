import { FunctionComponent } from "react";

const TopicStress: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    width={60}
    height={39}
    viewBox="0 0 60 39"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path d="M.136 31.83v.027a.84.84 0 0 0-.13.31 1.102 1.102 0 0 0 0 .172.842.842 0 0 0 0 .189.9.9 0 0 0 .346.544.899.899 0 0 0 .207.13c18.453 7.81 27.675 4.92 31.343 2.737l-.4 2.539.994.112c4.852.54 9.002-3.344 9.191-3.506a.954.954 0 0 0 .036-1.35.9.9 0 0 0-.279-.207.95.95 0 0 0-1.094.202c-.036.032-3.105 2.88-6.665 3.03l.333-2.116a1.211 1.211 0 0 0-1.98-1.112c-1.738 1.481-8.061 5.338-22.923.815 16.252.689 28.404-6.877 36.114-13.952a70.944 70.944 0 0 0 6.234-6.527 14.875 14.875 0 0 1-3.538 14.93l-1.125-4.74-1.881.297c0 .032.198 3.088-2.53 7.161a.954.954 0 1 0 1.584 1.062 18.682 18.682 0 0 0 1.98-3.798l.87 3.65 1.192-1.063c8.552-7.65 5.784-16.855 4.861-19.231a64.726 64.726 0 0 0 7.03-10.77.954.954 0 0 0-.45-1.275.959.959 0 0 0-1.269.45A69.884 69.884 0 0 1 43.888 19.04C33.293 28.733 21.411 33.199 8.48 32.41a9.595 9.595 0 0 0 2.21-.9.954.954 0 0 0-1.026-1.612c-.027 0-2.646 1.598-8.75 1.521a.633.633 0 0 0-.13 0h-.04l-.095.027a.94.94 0 0 0-.324.176H.302l-.04.031a.9.9 0 0 0-.126.176Z" />
    <path d="M13.796 29.336c1.394-.74 2.72-1.6 3.965-2.57l9.002 1.93-4.996-4.833 5.086-4.145h9.001a1.12 1.12 0 0 0 .666-2.021l-4.127-3.056a17.103 17.103 0 0 1 15.541-4.317.954.954 0 1 0 .518-1.836 19.074 19.074 0 0 0-18.228 5.613l-.752.783 3.97 2.939h-7.264L18.9 23.737l1.755 1.701-3.371-.72-.351.27a28.111 28.111 0 0 1-3.943 2.61.955.955 0 1 0 .792 1.738h.014Z" />
  </svg>
);

export { TopicStress };
