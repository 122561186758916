import { FunctionComponent } from "react";

const StripeIcon: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="60"
    height="50"
    fill="none"
    className={className}
  >
    <g filter="url(#filter0_d_777_2458)">
      <rect width="50" height="50" x="6" fill="#6461FC" rx="5"></rect>
    </g>
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M32.272 19.168l-2.61.577v-2.179l2.61-.566v2.168zm5.428 1.207c-1.019 0-1.674.491-2.038.833l-.135-.662h-2.289V33l2.6-.566.011-3.023c.374.278.926.673 1.841.673 1.862 0 3.557-1.538 3.557-4.924-.01-3.097-1.726-4.785-3.547-4.785zm-.624 7.36c-.613 0-.977-.225-1.227-.503l-.01-3.962c.27-.31.644-.524 1.237-.524.947 0 1.602 1.09 1.602 2.489 0 1.431-.645 2.5-1.602 2.5zm12.368-2.468c0-2.734-1.29-4.892-3.755-4.892-2.476 0-3.974 2.158-3.974 4.87 0 3.216 1.769 4.84 4.307 4.84 1.237 0 2.174-.29 2.88-.695v-2.136c-.706.363-1.518.587-2.548.587-1.008 0-1.903-.363-2.017-1.623h5.086c0-.059.003-.19.008-.343v-.004c.006-.21.013-.456.013-.604zm-5.139-1.015c0-1.207.718-1.709 1.373-1.709.635 0 1.31.502 1.31 1.71h-2.683zm-14.643-3.695h2.61v9.346h-2.61v-9.346zm-2.965 0l.167.79c.613-1.153 1.83-.918 2.163-.79v2.456c-.322-.117-1.363-.267-1.976.556v6.334h-2.6v-9.346h2.246zm-5.034-2.318l-2.538.555-.01 8.556c0 1.58 1.154 2.745 2.694 2.745.852 0 1.476-.16 1.82-.353v-2.168c-.333.139-1.977.63-1.977-.95v-3.792h1.977v-2.275h-1.977l.01-2.318zm-6.147 4.454c-.552 0-.884.16-.884.577 0 .454.572.655 1.283.903 1.159.404 2.683.937 2.69 2.91 0 1.912-1.488 3.012-3.651 3.012a7.049 7.049 0 01-2.84-.609v-2.542c.874.491 1.977.854 2.84.854.582 0 .998-.16.998-.651 0-.504-.62-.734-1.37-1.012C13.44 25.71 12 25.177 12 23.398c0-1.89 1.404-3.023 3.516-3.023a6.7 6.7 0 012.58.491v2.51c-.791-.437-1.79-.683-2.58-.683z"
      clipRule="evenodd"
    ></path>
    <defs>
      <filter
        id="filter0_d_777_2458"
        width="62"
        height="66"
        x="0"
        y="0"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feMorphology
          in="SourceAlpha"
          radius="6"
          result="effect1_dropShadow_777_2458"
        ></feMorphology>
        <feOffset dy="10"></feOffset>
        <feGaussianBlur stdDeviation="6"></feGaussianBlur>
        <feComposite in2="hardAlpha" operator="out"></feComposite>
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0"></feColorMatrix>
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_777_2458"
        ></feBlend>
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_777_2458"
          result="shape"
        ></feBlend>
      </filter>
    </defs>
  </svg>
);

export { StripeIcon };
