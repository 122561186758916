import { FunctionComponent } from "react";

const PlayVimeo: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M21 5a11.254 11.254 0 0 1 0 13.5M18.916 7.252a8.257 8.257 0 0 1 0 9M3.001 5a11.254 11.254 0 0 0 0 13.5M5.084 7.252a8.257 8.257 0 0 0 0 9M10.886 7.931a.75.75 0 0 0-1.136.643v6.351a.749.749 0 0 0 1.136.643l5.25-3.175a.75.75 0 0 0 0-1.286l-5.25-3.176Z"
      stroke="#fff"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export { PlayVimeo };
