import { FunctionComponent } from "react";

const RelaxingIcon: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="93"
    height="63"
    viewBox="0 0 93 63"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M93 62.1649C92.762 57.6164 90.7875 53.3328 87.4838 50.1974C84.1801 47.062 79.7991 45.314 75.2445 45.3139C74.7041 45.3139 74.1705 45.3421 73.6424 45.3893C71.5654 41.816 68.5872 38.8502 65.0053 36.7881C61.4234 34.7259 57.3632 33.6397 53.2301 33.6377C49.097 33.6357 45.0358 34.7182 41.4519 36.7769C37.8681 38.8357 34.8871 41.7987 32.8067 45.37C32.3612 45.3365 31.9127 45.3139 31.4586 45.3139C26.9039 45.314 22.523 47.062 19.2193 50.1974C15.9156 53.3328 13.9411 57.6164 13.7031 62.1649"
      fill="#F2F2F2"
    />
    <path
      d="M89.2969 60.7967C89.0588 56.2482 87.0844 51.9646 83.7807 48.8292C80.477 45.6938 76.096 43.9459 71.5413 43.9458C71.001 43.9458 70.4674 43.974 69.9393 44.0211C67.8622 40.4479 64.8841 37.4821 61.3022 35.4199C57.7203 33.3578 53.6601 32.2715 49.527 32.2695C45.3939 32.2676 41.3326 33.35 37.7488 35.4088C34.165 37.4675 31.184 40.4305 29.1036 44.0018C28.6581 43.9684 28.2096 43.9458 27.7555 43.9458C23.2008 43.9459 18.8199 45.6938 15.5162 48.8292C12.2125 51.9646 10.238 56.2482 9.99994 60.7967"
      stroke="#3F3D56"
      strokeMiterlimit="10"
    />
    <path
      d="M54.499 20.2696C54.3456 17.3378 53.0729 14.5767 50.9434 12.5557C48.814 10.5347 45.9901 9.40804 43.0543 9.40799C42.706 9.40799 42.3621 9.42615 42.0217 9.45658C40.6829 7.15335 38.7632 5.24167 36.4545 3.91248C34.1457 2.58329 31.5286 1.8831 28.8645 1.88184C26.2005 1.88058 23.5827 2.5783 21.2727 3.9053C18.9626 5.23231 17.0412 7.14217 15.7002 9.44413C15.4131 9.42256 15.1239 9.40799 14.8313 9.40799C11.8955 9.40804 9.07162 10.5347 6.94216 12.5557C4.81269 14.5767 3.54 17.3378 3.38657 20.2696"
      fill="#F2F2F2"
    />
    <path
      d="M52.1123 19.3877C51.9589 16.4559 50.6862 13.6948 48.5567 11.6738C46.4273 9.65288 43.6034 8.52618 40.6676 8.52613C40.3193 8.52613 39.9754 8.54429 39.635 8.57471C38.2962 6.27149 36.3765 4.35983 34.0678 3.03064C31.759 1.70145 29.1419 1.00126 26.4778 1C23.8138 0.998742 21.196 1.69645 18.886 3.02346C16.5759 4.35046 14.6545 6.26031 13.3135 8.56227C13.0263 8.5407 12.7372 8.52613 12.4446 8.52613C9.50875 8.52618 6.68492 9.65288 4.55545 11.6738C2.42599 13.6948 1.1533 16.4559 0.999862 19.3877"
      stroke="#3F3D56"
      strokeMiterlimit="10"
    />
  </svg>
);

export { RelaxingIcon };
export default RelaxingIcon;
