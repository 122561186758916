import { FunctionComponent } from "react";

export const LikeGreenIcon: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="16"
    fill="none"
    viewBox="0 0 18 16"
  >
    <path d="M12.93 0A4.573 4.573 0 009 2.358 4.573 4.573 0 005.07 0 4.86 4.86 0 00.425 5.038c0 4.841 7.83 10.432 8.162 10.67L9 16l.413-.292c.333-.236 8.162-5.829 8.162-10.67A4.86 4.86 0 0012.93 0zM9 14.24c-2.324-1.736-7.146-6.002-7.146-9.202A3.43 3.43 0 015.07 1.429a3.43 3.43 0 013.216 3.609h1.429a3.43 3.43 0 013.215-3.609 3.43 3.43 0 013.216 3.609c0 3.202-4.821 7.466-7.146 9.203z"></path>
  </svg>
);
