import { FunctionComponent } from "react";

export const ArrowLeftIcon: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="13"
    height="22"
    viewBox="0 0 13 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12.049 2.507C12.6283 1.93349 12.6283 1.00365 12.049 0.430134C11.4696 -0.143378 10.5304 -0.143378 9.95101 0.430134L0.434506 9.85087C-0.144835 10.4244 -0.144835 11.3542 0.434506 11.9277L9.95101 21.3485C10.5304 21.922 11.4696 21.922 12.049 21.3485C12.6283 20.775 12.6283 19.8451 12.049 19.2716L3.58148 10.8893L12.049 2.507Z" />
  </svg>
);
