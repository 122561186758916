import { SIXTY_URLS } from "@/utils/urls";

// Services
import { clientFetchService } from "../fetch";
import getStripe from "@/utils/checkout";
import { KUMU_URLS } from "@/utils/urls/kumu";

const CheckoutService = {
  handleBuyCredits: async (stripeOperation: any) => {
    try {
      const { sessionId } = await clientFetchService<{ sessionId: string }>(
        KUMU_URLS.API.STRIPE.CUSTOMER.CHECKOUT,
        {
          method: "POST",
          body: JSON.stringify({ stripeOperation }),
        }
      );

      // Redirect to Checkout.
      const stripe = await getStripe();
      const { error } = await stripe!.redirectToCheckout({
        // Make the id field from the Checkout Session creation API response
        // available to this file, so you can provide it as parameter here
        // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
        sessionId: sessionId,
      });
    } catch (error) {
      console.log(error);
      return false; // return false? it should be throw new Error(error)
    }
  },
};

export { CheckoutService };
