import { FunctionComponent } from "react";

export const NotificationIcon: FunctionComponent<IconProps> = ({
  className,
}) => (
  <svg
    className={className}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M15.1711 10.7986L13.4384 4.55366C13.0684 3.21778 12.2622 2.04404 11.1481 1.21925C10.0341 0.394458 8.6761 -0.0339856 7.29039 0.00210722C5.90469 0.0382 4.57088 0.536755 3.50124 1.41842C2.4316 2.30009 1.6876 3.51421 1.38765 4.86754L0.0474907 10.8986C-0.0173562 11.1908 -0.0157745 11.4938 0.0521192 11.7853C0.120013 12.0768 0.252486 12.3493 0.439765 12.5828C0.627044 12.8162 0.86435 13.0047 1.13417 13.1342C1.404 13.2637 1.69946 13.3309 1.99875 13.331H4.40251C4.55546 14.0842 4.96411 14.7614 5.55923 15.2479C6.15435 15.7343 6.89932 16 7.66794 16C8.43655 16 9.18153 15.7343 9.77664 15.2479C10.3718 14.7614 10.7804 14.0842 10.9334 13.331H13.2458C13.5538 13.3309 13.8575 13.2596 14.1334 13.1227C14.4093 12.9859 14.6498 12.7871 14.8362 12.542C15.0227 12.2969 15.1499 12.012 15.2081 11.7096C15.2663 11.4072 15.2539 11.0954 15.1718 10.7986H15.1711ZM7.66794 14.6638C7.25592 14.6621 6.85449 14.5332 6.51857 14.2946C6.18265 14.056 5.92865 13.7195 5.79131 13.331H9.54456C9.40722 13.7195 9.15322 14.056 8.8173 14.2946C8.48138 14.5332 8.07995 14.6621 7.66794 14.6638ZM13.7756 11.7349C13.7135 11.8174 13.6329 11.8841 13.5403 11.9298C13.4477 11.9755 13.3457 11.9989 13.2425 11.9982H1.99875C1.89896 11.9982 1.80045 11.9757 1.71049 11.9325C1.62053 11.8894 1.54141 11.8265 1.47898 11.7487C1.41655 11.6708 1.3724 11.58 1.34979 11.4828C1.32718 11.3856 1.32668 11.2846 1.34833 11.1871L2.68849 5.1561C2.92464 4.09397 3.50905 3.14126 4.34883 2.44942C5.18861 1.75758 6.23556 1.36632 7.32325 1.33782C8.41094 1.30933 9.47694 1.64524 10.3518 2.29216C11.2266 2.93909 11.8601 3.85989 12.1516 4.90819L13.8842 11.1532C13.9125 11.2519 13.9175 11.3559 13.8986 11.4569C13.8797 11.5578 13.8376 11.6531 13.7756 11.7349Z" />
  </svg>
);
