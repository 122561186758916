import { FunctionComponent } from "react";

export const KumuArrowIcon: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 17"
    fill="none"
    className={className}
  >
    <mask
      id="mask0_450_14013"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="17"
    >
      <rect
        width="16"
        height="16"
        transform="matrix(1 0 0 -1 0 16.5)"
        fill="#D9D9D9"
      />
    </mask>
    <g mask="url(#mask0_450_14013)">
      <path
        d="M5.54289 9.92414C6.17286 10.5541 7.25 10.1079 7.25 9.21704L7.25 3.25C7.25 2.83579 7.58579 2.5 8 2.5C8.41421 2.5 8.75 2.83579 8.75 3.25L8.75 9.21704C8.75 10.1079 9.82714 10.5541 10.4571 9.92414L12.4156 7.96565C12.71 7.67128 13.1879 7.67339 13.4796 7.97035C13.7678 8.26362 13.7657 8.73431 13.475 9.02502L8.70711 13.7929C8.31658 14.1834 7.68342 14.1834 7.29289 13.7929L2.52502 9.02502C2.23431 8.73431 2.23223 8.26362 2.52035 7.97035C2.81211 7.67339 3.29002 7.67128 3.5844 7.96565L5.54289 9.92414Z"
        fill="#212421"
      />
    </g>
  </svg>
);
