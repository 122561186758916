import { FunctionComponent, useState, useEffect } from "react";
import { classNames } from "@/utils/classNames";
import { GetIcon } from "@/components/common/icon";
import { Label } from "@/components/common/label";
import { ModalProps } from ".";

// to do refactor to share common with desktop modal - ex: ModalContainer
const MobileModal: FunctionComponent<ModalProps> = ({
  children,
  className,
  onClose,
  showModal = false,
  withoutClose = false,
  title = "",
  modifier = "light",
  fullWidth = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => setIsOpen(true), []);
  const styles = {
    dark: modifier === "dark",
    light: modifier === "light",
    black: modifier === "black",
    profile: modifier === "profile",
  };
  return (
    <div
      className={classNames(
        "relative z-50",
        showModal ? "relative" : null,
        !showModal ? "hidden" : null
      )}
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      {showModal ? (
        <div
          className={classNames(
            styles.light ? "bg-gray-500 bg-opacity-75" : null,
            styles.dark ? "bg-[#043346] bg-opacity-80" : null,
            styles.black ? "bg-black bg-opacity-80" : null,
            "fixed inset-0 transition-opacity"
          )}
        >
          <div
            className={classNames(
              className,
              isOpen ? "bottom-0" : "bottom-[-40px]",
              "fixed z-50 overflow-y-auto w-full translate-all duration-300"
            )}
          >
            <div className="flex items-center justify-center min-h-full text-center sm:p-0 w-full">
              <div className="relative rounded-t-[20px] text-left overflow-hidden shadow-xl transform transition-all w-full">
                <div
                  className={classNames(
                    styles.dark
                      ? "bg-[#014157]"
                      : styles.black
                      ? "bg-black bg-opacity-0"
                      : "bg-white",
                    styles.profile
                      ? ""
                      : " px-4 pt-5 pb-4 sm:p-6 sm:pb-4 w-full "
                  )}
                >
                  <header
                    className={classNames(
                      title ? "justify-between w-full" : null,
                      !title ? "justify-end w-full" : null,

                      "flex items-start relative"
                    )}
                  >
                    {title && typeof title === "string" ? (
                      <div
                        className={classNames(
                          withoutClose ? "w-full" : null,
                          ""
                        )}
                      >
                        <Label
                          fontSize="xl"
                          textAlignment="left"
                          textColor={
                            styles.dark
                              ? styles.black
                                ? "black"
                                : "white"
                              : ""
                          }
                        >
                          {title}
                        </Label>
                      </div>
                    ) : null}
                    {title && typeof title !== "string" ? <>{title}</> : null}
                    {!withoutClose && (
                      <button
                        id="close-btn"
                        onClick={onClose}
                        className={classNames(
                          styles.black
                            ? "border-[1px] border-solid border-white"
                            : "border-[1px] border-solid border-[#33878E]",
                          styles.profile
                            ? "rounded-[6px] bg-black mt-4  p-2.5 absolute right-4 top-4 border-none"
                            : " rounded-full p-3 absolute right-0 top-0"
                        )}
                      >
                        <GetIcon
                          iconName="close"
                          className={classNames(
                            "fill-current text-[#33878E] h-2.5 w-2.5 stroke-current stroke-1",
                            styles.black ? "stroke-white" : null,
                            styles.profile && "text-white fill-current"
                          )}
                        />
                      </button>
                    )}
                  </header>
                  <div
                    className={classNames(
                      styles.black ? "bg-black bg-opacity-100" : null,
                      !styles.profile && "mt-4"
                    )}
                  >
                    {children}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};
export { MobileModal };
