import { TransactionType } from "@/model/transactionType";
import { FunctionComponent } from "react";
import { DonateContent } from "./donate-content";
import { SubscribeContent } from "./subscribe-content";
import { UnlockContent } from "./unlock-content";

type Props = {
  transactionType: TransactionType;
  price: number;
  handleClick: (amount?: number) => void;
};

export const TransactionContent: FunctionComponent<Props> = ({
  transactionType,
  price,
  handleClick,
}) => {
  const components = new Map<TransactionType, FunctionComponent<any>>();
  components.set(TransactionType.DONATION, DonateContent);
  components.set(TransactionType.SUBSCRIPTION, SubscribeContent);
  components.set(TransactionType.PPV_COURSE, UnlockContent);
  components.set(TransactionType.PPV_LIVE, UnlockContent);
  components.set(TransactionType.PPV_PRODUCT, UnlockContent);

  const Component = components.get(transactionType);

  return Component ? (
    <Component handleClick={handleClick} price={price} />
  ) : null;
};
