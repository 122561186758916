export const mapKumuTags = (tag: Tags): KumuTagLabel => ({
  id: tag.id,
  label: tag.tag,
});

export const getVideoDurationFromPresignedUrl = (presignedURL?: string) => {
  return new Promise((resolve, reject) => {
    if (presignedURL) {
      const video = document.createElement("video");
      video.preload = "metadata";
      video.onloadedmetadata = () => {
        window.URL.revokeObjectURL(video.src);
        resolve(video.duration);
      };
      video.onerror = (error) => {
        reject(error);
      };
      video.src = presignedURL;
    }
  });
};

export const getVideoResolutionFromPresignedUrl = (presignedURL?: string) => {
  return new Promise<{ width: number; height: number }>((resolve, reject) => {
    if (presignedURL) {
      const video = document.createElement("video");
      video.preload = "metadata";
      video.onloadedmetadata = () => {
        window.URL.revokeObjectURL(video.src);
        resolve({
          width: video.videoWidth,
          height: video.videoHeight,
        });
      };
      video.onerror = (error) => {
        reject(error);
      };
      video.src = presignedURL;
    } else {
      reject(new Error("No presigned URL provided"));
    }
  });
};

export const getVideoDurationFromFile = (file?: File) => {
  return new Promise<number>((resolve, reject) => {
    if (file) {
      const video = document.createElement("video");
      video.preload = "metadata";
      video.onloadedmetadata = () => {
        window.URL.revokeObjectURL(video.src);
        resolve(video.duration);
      };
      video.onerror = (error) => {
        reject(error);
      };
      video.src = URL.createObjectURL(file);
    } else {
      reject(new Error("No file provided"));
    }
  });
};

export const getVideoResolutionFromFile = (file?: File) => {
  return new Promise<{ width: number; height: number }>((resolve, reject) => {
    if (file) {
      const video = document.createElement("video");
      video.preload = "metadata";
      video.onloadedmetadata = () => {
        window.URL.revokeObjectURL(video.src);
        resolve({
          width: video.videoWidth,
          height: video.videoHeight,
        });
      };
      video.onerror = (error) => {
        reject(error);
      };
      video.src = URL.createObjectURL(file);
    } else {
      reject(new Error("No file provided"));
    }
  });
};
