import React, {
  createContext,
  FunctionComponent,
  ReactNode,
  useState,
  useEffect,
  useContext,
} from "react";
import { useRouter } from "next/router";

interface KumuModeContextType {
  kumuMode: string;
  setKumuMode: React.Dispatch<React.SetStateAction<string>>;
}

const KumuModeContext = createContext<KumuModeContextType | undefined>(
  undefined
);

type Props = {
  children: ReactNode;
};

export const KumuModeProvider: FunctionComponent<Props> = ({ children }) => {
  const [kumuMode, setKumuMode] = useState("");
  const router = useRouter();

  useEffect(() => {
    let modeLocal = localStorage.getItem("mode");
    modeLocal
      ? setKumuMode(modeLocal)
      : setKumuMode(router.asPath.includes("dashboard") ? "expert" : "member");
  }, [, router]);

  const value: KumuModeContextType = { kumuMode, setKumuMode };

  return (
    <KumuModeContext.Provider value={value}>
      {children}
    </KumuModeContext.Provider>
  );
};

export default KumuModeContext;

// UseKumuModeContext hook
export const useKumuModeContext = () => {
  const context = useContext(KumuModeContext);
  if (!context) {
    throw new Error("KumuModeContext must be used within a KumuModeProvider");
  }
  return context;
};
