import { FunctionComponent } from "react";

const TopicRelaxing: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    width={32}
    height={36}
    viewBox="0 0 32 36"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path d="M29.29 10.64h.17a.934.934 0 0 0 0-1.864h-.17a.934.934 0 0 0 0 1.864Z" />
    <path d="M29.461 11.14h-.17a1.434 1.434 0 0 1 0-2.863h.17a1.434 1.434 0 0 1 0 2.864Zm-.17-1.863a.435.435 0 0 0 0 .864h.17a.435.435 0 0 0 0-.864h-.17Z" />
    <path d="M31.194 15.546V9.157a1.106 1.106 0 0 0 0-.154.747.747 0 0 0-.022-.085 1.037 1.037 0 0 0-.03-.1l-.047-.084-.034-.066-.05-.059c-.02-.022-.038-.048-.06-.07a.855.855 0 0 0-.092-.077l-.059-.044a.792.792 0 0 0-.136-.07L11.4.43h-.048a.623.623 0 0 0-.118-.037H11.064a.735.735 0 0 0-.129 0 .906.906 0 0 0-.313.11l-.047.027-.07.058-.063.048a.824.824 0 0 0-.099.125v.022c-.03.045-.055.092-.077.14a.211.211 0 0 0 0 .052.58.58 0 0 0-.033.114v16.72a5.753 5.753 0 1 0 1.765 4.138V8.944l17.515 7.194v9.611a5.753 5.753 0 1 0 1.762 4.138V15.542l-.081.004ZM6.157 25.948a3.994 3.994 0 1 1 0-7.988 3.994 3.994 0 0 1 0 7.988Zm5.76-18.906v-4.49l17.515 7.197v4.491L11.917 7.042Zm13.52 26.828a3.995 3.995 0 1 1 .001-7.99 3.995 3.995 0 0 1 0 7.99Z" />
    <path d="M25.438 36a6.124 6.124 0 1 1 3.626-11.034V16.41l-16.78-6.892v12.454a6.142 6.142 0 1 1-2.5-4.929V1.264a.622.622 0 0 1 0-.092.68.68 0 0 1 .018-.162c.011-.052.026-.104.044-.154a.316.316 0 0 1 .026-.077V.742c.026-.059.057-.115.092-.17L9.986.54c.044-.06.092-.117.143-.17a.627.627 0 0 1 .074-.066 1.12 1.12 0 0 1 .114-.088l.07-.04c.137-.086.29-.145.449-.173.051-.004.103-.004.154 0h.272c.06.01.117.028.173.051a.228.228 0 0 1 .066 0h.033l19.277 7.923a.98.98 0 0 1 .166.092l.066.044c.05.037.098.077.143.121.026.026.048.055.074.085l.08.103.045.08a.797.797 0 0 1 .11.262c.014.04.025.083.033.125.011.063.019.127.022.191v20.756A6.13 6.13 0 0 1 25.437 36Zm0-11.512a5.388 5.388 0 1 0 5.388 5.388V9.154a.684.684 0 0 0 0-.089l-.133-.257a.22.22 0 0 0-.058-.051l.169-.335-.21.305-.077-.044h-.033L11.204.738a.37.37 0 0 0-.067-.022h-.173a.5.5 0 0 0-.18.063l-.103.077a.52.52 0 0 0-.11.158v.037a.658.658 0 0 0-.022.07V18.669l-.622-.603a5.384 5.384 0 1 0 1.652 3.873V8.385l18.25 7.5V26.59l-.625-.603a5.352 5.352 0 0 0-3.767-1.5Zm0 9.75a4.361 4.361 0 1 1 0-8.723 4.361 4.361 0 0 1 0 8.723Zm0-7.989a3.626 3.626 0 1 0 0 7.253 3.626 3.626 0 0 0 0-7.253Zm-19.28.067a4.362 4.362 0 1 1 0-8.725 4.362 4.362 0 0 1 0 8.725Zm0-7.985a3.627 3.627 0 1 0 0 7.253 3.627 3.627 0 0 0 0-7.253ZM29.8 14.789l-18.25-7.5V2.004l18.25 7.5v5.285ZM12.285 6.796l16.779 6.896v-3.678l-16.78-6.896v3.678Z" />
  </svg>
);

export { TopicRelaxing };
