import { classNames } from "@/utils/classNames";
import { FunctionComponent } from "react";

export const MinusIcon: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    className={classNames(className, "fill-current")}
    width="18"
    height="18"
    viewBox="0 0 18 18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M.5 0h13v2H.5z" />
  </svg>
);
