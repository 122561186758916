import { FunctionComponent } from "react";

export const KumuNotificationIcon: FunctionComponent<IconProps> = ({
  className,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 25 24"
    fill="none"
    className={className}
  >
    <mask
      id="mask0_859_4549"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="25"
      height="24"
    >
      <rect x="0.222168" width="24" height="24" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_859_4549)">
      <path
        d="M5.22217 19C4.93883 19 4.70133 18.9042 4.50967 18.7125C4.318 18.5208 4.22217 18.2833 4.22217 18C4.22217 17.7167 4.318 17.4792 4.50967 17.2875C4.70133 17.0958 4.93883 17 5.22217 17H6.22217V10C6.22217 8.61667 6.63883 7.3875 7.47217 6.3125C8.3055 5.2375 9.38883 4.53333 10.7222 4.2V3.5C10.7222 3.08333 10.868 2.72917 11.1597 2.4375C11.4513 2.14583 11.8055 2 12.2222 2C12.6388 2 12.993 2.14583 13.2847 2.4375C13.5763 2.72917 13.7222 3.08333 13.7222 3.5V4.2C15.0555 4.53333 16.1388 5.2375 16.9722 6.3125C17.8055 7.3875 18.2222 8.61667 18.2222 10V17H19.2222C19.5055 17 19.743 17.0958 19.9347 17.2875C20.1263 17.4792 20.2222 17.7167 20.2222 18C20.2222 18.2833 20.1263 18.5208 19.9347 18.7125C19.743 18.9042 19.5055 19 19.2222 19H5.22217ZM12.2222 22C11.6722 22 11.2013 21.8042 10.8097 21.4125C10.418 21.0208 10.2222 20.55 10.2222 20H14.2222C14.2222 20.55 14.0263 21.0208 13.6347 21.4125C13.243 21.8042 12.7722 22 12.2222 22ZM8.22217 17H16.2222V10C16.2222 8.9 15.8305 7.95833 15.0472 7.175C14.2638 6.39167 13.3222 6 12.2222 6C11.1222 6 10.1805 6.39167 9.39717 7.175C8.61383 7.95833 8.22217 8.9 8.22217 10V17Z"
        fill="#202421"
      />
    </g>
  </svg>
);
