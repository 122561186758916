import { FunctionComponent } from "react";

export const CheckedFormInputIcon: FunctionComponent<IconProps> = ({
  className,
}) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g id="Group 6798">
      <rect id="Rectangle 210" width="16" height="16" rx="4" fill="#2C9084" />
      <path
        id="Vector 104"
        d="M4 8.50004L6.62654 11L11.8799 6"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
