import { FunctionComponent } from "react";

const SaveIcon: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="11"
    height="14"
    viewBox="0 0 11 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.61101 12.9175C1.5357 12.9679 1.44812 12.9969 1.35763 13.0013C1.26714 13.0058 1.17714 12.9856 1.09724 12.9429C1.01735 12.9002 0.95056 12.8365 0.904019 12.7588C0.857479 12.6811 0.832934 12.5921 0.833008 12.5015V3.00954C0.833007 2.4798 1.04317 1.9717 1.41738 1.59674C1.79159 1.22178 2.29927 1.0106 2.82901 1.00954L8.82901 0.998539C9.09165 0.998013 9.35183 1.04922 9.59468 1.14925C9.83753 1.24927 10.0583 1.39615 10.2444 1.5815C10.4305 1.76684 10.5782 1.98703 10.6792 2.22948C10.7802 2.47193 10.8325 2.7319 10.833 2.99454V12.5005C10.8331 12.5911 10.8085 12.6801 10.762 12.7578C10.7155 12.8355 10.6487 12.8992 10.5688 12.9419C10.4889 12.9846 10.3989 13.0048 10.3084 13.0003C10.2179 12.9959 10.1303 12.9669 10.055 12.9165L5.83301 10.0965L1.61101 12.9165V12.9175ZM9.83301 2.99954L9.82601 2.88154C9.79708 2.63813 9.67976 2.41382 9.49633 2.25122C9.3129 2.08862 9.07613 1.99905 8.83101 1.99954L2.83101 2.00954C2.56614 2.01007 2.3123 2.11566 2.12519 2.30314C1.93809 2.49062 1.83301 2.74467 1.83301 3.00954V11.5665L5.55501 9.08054C5.63728 9.02551 5.73403 8.99613 5.83301 8.99613C5.93199 8.99613 6.02874 9.02551 6.11101 9.08054L9.83301 11.5665V2.99954Z"
      fill="#8C6FF0"
    />
  </svg>
);

export { SaveIcon };
