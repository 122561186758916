import { FunctionComponent } from "react";

export const ChatVerticalDots: FunctionComponent<IconProps> = ({
  className,
}) => (
  <svg
    width="3"
    height="12"
    viewBox="0 0 3 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="3-vertical-dots-icon 1" clipPath="url(#clip0_2852_1334)">
      <path
        id="Vector"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.53516 12C1.24544 12 0.962225 11.9141 0.721333 11.7531C0.48044 11.5922 0.292687 11.3634 0.181817 11.0957C0.0709466 10.8281 0.0419377 10.5335 0.0984592 10.2494C0.15498 9.96523 0.294493 9.70422 0.499355 9.49935C0.704217 9.29449 0.965228 9.15498 1.24938 9.09846C1.53353 9.04194 1.82806 9.07095 2.09573 9.18182C2.36339 9.29269 2.59217 9.48044 2.75313 9.72133C2.91409 9.96222 3 10.2454 3 10.5352C3 10.9237 2.84567 11.2962 2.57096 11.571C2.29625 11.8457 1.92366 12 1.53516 12ZM1.53516 2.9248C1.24544 2.9248 0.962226 2.83889 0.721333 2.67793C0.480441 2.51697 0.292688 2.2882 0.181818 2.02053C0.0709474 1.75287 0.0419385 1.45833 0.09846 1.17418C0.154981 0.890032 0.294494 0.629021 0.499356 0.424159C0.704218 0.219297 0.965228 0.0797842 1.24938 0.0232628C1.53353 -0.0332586 1.82806 -0.00424968 2.09573 0.106621C2.36339 0.217491 2.59217 0.405245 2.75313 0.646137C2.91409 0.88703 3 1.17024 3 1.45996C3 1.84846 2.84567 2.22105 2.57096 2.49576C2.29625 2.77047 1.92366 2.9248 1.53516 2.9248ZM1.53516 7.46289C1.24544 7.46289 0.962225 7.37698 0.721333 7.21602C0.480441 7.05506 0.292688 6.82628 0.181817 6.55862C0.070947 6.29095 0.0419381 5.99642 0.0984596 5.71227C0.154981 5.42812 0.294494 5.16711 0.499356 4.96224C0.704218 4.75738 0.965228 4.61787 1.24938 4.56135C1.53353 4.50483 1.82806 4.53384 2.09573 4.64471C2.36339 4.75558 2.59217 4.94333 2.75313 5.18422C2.91409 5.42511 3 5.70833 3 5.99805C3 6.38655 2.84567 6.75914 2.57096 7.03385C2.29625 7.30856 1.92366 7.46289 1.53516 7.46289Z"
        fill="white"
        fillOpacity="0.6"
      />
    </g>
    <defs>
      <clipPath id="clip0_2852_1334">
        <rect
          width="2.92578"
          height="12"
          fill="white"
          transform="translate(3 12) rotate(-180)"
        />
      </clipPath>
    </defs>
  </svg>
);
