import React, {
  Reducer,
  createContext,
  FunctionComponent,
  useReducer,
} from "react";

// Types

type State = {
  color: ThemeColor;
  mode: ThemeMode;
};

type Action = {};

type Dispatch = (action: Action) => void;

type OwnProps = {
  color?: ThemeColor;
};
const ThemeContext = createContext<
  | {
      state: State;
      dispatch: Dispatch;
    }
  | undefined
>(undefined);

const themeReducer: Reducer<State, Action> = (
  state: State,
  action: Action
): State => {
  switch (action) {
    default: {
      throw new Error();
    }
  }
};

const themeInitialState: State = {
  color: "purple",
  mode: "light",
};

const selectedColor = (color: ThemeColor) => {
  return { ...themeInitialState, color };
};

type ProviderProps = React.PropsWithChildren<OwnProps>;

export const ThemeProvider: FunctionComponent<ProviderProps> = ({
  children,
  color,
}) => {
  const themeState = () => (color ? selectedColor(color) : themeInitialState);
  const [state, dispatch] = useReducer(themeReducer, themeState());
  const value = { state, dispatch };
  return (
    <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
  );
};

export const useTheme = () => {
  const context = React.useContext(ThemeContext);
  if (context === undefined) {
    throw new Error("useTheme must be used within a ThemeProvider");
  }
  return context;
};
