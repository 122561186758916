import { FunctionComponent } from "react";

const Calendar: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    width={16}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    stroke="#65B0B7"
    className={className}
  >
    <g clipPath="url(#a)" stroke="current">
      <path
        d="M14.5 2.5h-13a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h13a1 1 0 0 0 1-1v-11a1 1 0 0 0-1-1ZM.5 6.5h15M4.5 4V.5M11.5 4V.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M3.75 9.5a.25.25 0 0 1 0-.5M3.75 9.5a.25.25 0 0 0 0-.5M3.75 13a.25.25 0 1 1 0-.5M3.75 13a.25.25 0 1 0 0-.5M8 9.5A.25.25 0 0 1 8 9M8 9.5A.25.25 0 0 0 8 9M8 13a.25.25 0 1 1 0-.5M8 13a.25.25 0 1 0 0-.5M12.25 9.5a.25.25 0 1 1 0-.5M12.25 9.5a.25.25 0 1 0 0-.5M12.25 13a.25.25 0 1 1 0-.5M12.25 13a.25.25 0 1 0 0-.5" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);

export { Calendar };
