import { FunctionComponent } from "react";

export const CheckBgGreen: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <circle
      transform="matrix(.00135 -1 1 .00135 12 12)"
      fill="#03BD93"
      r={11.984}
    />
    <path
      d="M16.563 7.314a.598.598 0 0 0-.838.136l-5.623 7.832-1.88-1.877a.602.602 0 0 0-.85.849L9.75 16.63a.614.614 0 0 0 .914-.076l6.04-8.404a.597.597 0 0 0-.14-.837Z"
      fill="#fff"
    />
  </svg>
);
