import { FunctionComponent } from "react";

const HomeIcon: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="18"
    height="22"
    fill="none"
    viewBox="0 0 18 22"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m1.348 7.586 7.343-6.3 7.343 6.3v9.9c0 .477-.171.935-.477 1.272a1.559 1.559 0 0 1-1.154.528H2.98c-.433 0-.848-.19-1.154-.528a1.898 1.898 0 0 1-.478-1.272v-9.9Z"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.242 19.286v-9h4.896v9"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export { HomeIcon };
