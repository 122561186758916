import { FunctionComponent } from "react";

export const CheckMarkFullIcon: FunctionComponent<IconProps> = ({
  className,
}) => (
  <svg
    className={className}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.45898 10.625L8.33398 12.7083L13.5423 7.29163"
      className="stroke-current"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
