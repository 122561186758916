import { FunctionComponent } from "react";
import dynamic from "next/dynamic";
// Icons
import { SixtyLogo } from "./sixty-logo";

// KUMU
import { KumuBackArrow } from "./kumu/back-arrow";
import { KumuAddIcon } from "./kumu/add-icon";
import { KumuArrowRight } from "./kumu/arrow-right";
import { KumuChevronUp } from "./kumu/chevron-up";
import { KumuChevronDown } from "./kumu/chevron-down";
import { KumuLockIcon } from "./kumu-lock";
// - Basics
import { AlertIcon } from "./alert";
import { SixtyIcon } from "./sixty";
import { CheckMarkIcon } from "./checkmark";
import { UpdateIcon } from "./update";
import { ToRightArrowIcon } from "./to-right-arrow";
import { RatingIcon } from "./rating";
import { VideoIcon } from "./video";
import { HomeIcon } from "./home";
import { ExploreIcon } from "./explore";
import { ProfileIcon } from "./profile";
import { ChatIcon } from "./chat";
import { CloseIcon } from "./close";
import { TrashIcon } from "./trash";
import { CopyIcon } from "./copy";
import { DecreaseIcon } from "./decrease";
import { IncreaseIcon } from "./increase";
import { ExitIcon } from "./exit";
import { ArrowBack } from "./arrow-back";
import { ExploreByTopic } from "./explore-by-topic";
import { InfoIcon } from "./info";
// Monetary
import { BalanceIcon } from "./balance";
import { WithdrawIcon } from "./withdraw";
import { TipIcon } from "./tip";
import { SubscribeIcon } from "./susbcribe";
// - Topics
import { StretchingIcon } from "./stretching";
import { YogaIcon } from "./yoga";
import { MeditationIcon } from "./meditation";
import { BreathingIcon } from "./breathing";
import { NutritionalIcon } from "./nutritional";
import { RelaxingIcon } from "./relaxing";
import { ViewsIcon } from "./views";
import { SupportCreatorIcon } from "./support-creator";
import { SupportCreatorActivityIcon } from "./support-creator-activity";
import { SaveIcon } from "./save";
import { ShareIcon } from "./share";
import { CreatorsIcon } from "./creators";
import { SearchIcon } from "./search";
import { SixtyLogoWhiteIcon } from "./sixty-logo-white";
import { SixtyLogoWhiteFooter } from "./sixty-logo-white-footer";
import { HidePasswordIcon, ShowPasswordIcon } from "./toggle-password";
import { LockIcon } from "./lock";
import { LockCardIcon } from "./lock-card";
import { LoadingSpinnerIcon } from "./loading-spinner";
import { PencilIcon } from "./pencil";
import { BookmarkIcon } from "./bookmark";
import { BookmarkFilledIcon } from "./bookmark-filled";
import { HistoryIcon } from "./history";
import { NotificationIcon } from "./notification";
import { SettingsIcon } from "./settings";
import { AboutIcon } from "./about";
import { HelpIcon } from "./help";
import { DurationIcon } from "./duration";
import { CategoriesIcon } from "./categories";
import { SixtyLogoBig } from "./sixty-logo-big";
import { userIcon } from "./user";
// Video controls
import { PlayIcon } from "./play";
import { PauseIcon } from "./pause";
import { FullScreenIcon } from "./fullscreen";
import { MinusTenIcon } from "./minus-ten";
import { PlusTenIcon } from "./plus-ten";
import { LikeIcon } from "./like";
import { LikeFilledIcon } from "./like-filled";
// Social
import { InstagramIcon } from "./instagram";
import { WebIcon } from "./web";
import { WhatsAppIcon } from "./whatsapp";
import { EmailIcon } from "./email";
// Chat
import { HandIcon } from "./hand";
import { ArrowLeftIcon } from "./arrowleft";
import { PlaneIcon } from "./plane";
import { OptionsIcon } from "./options";
//Dashboard creators
import { ArrowDown } from "./arrow-down";
import { SubscribersIcon } from "./subscribers";
import { Heart } from "./heart";
import { WalletIcon } from "./wallet";
import { ContentIcon } from "./content";
import { InsightsIcon } from "./insights";
import { CheckMarkFullIcon } from "./checkmark-full";
import { ExclamationIcon } from "./exclamation";
import { TopicFitness } from "./topic-fitness";
import { TopicNutritional } from "./topic-nutritional";
import { TopicStress } from "./topic-stress";
import { TopicBreathing } from "./topic-breathing";
import { TopicYoga } from "./topic-yoga";
import { TopicMeditation } from "./topic-meditation";
import { TopicAnxiety } from "./topic-anxiety";
import { TopicCoaching } from "./topic-coaching";
import { TopicSleepHealth } from "./topic-sleep-health";
import { TopicStretching } from "./topic-stretching";
import { TopicRelaxing } from "./topic-relaxing";
import { TopicWork } from "./topic-work";
import { SixtyLogoGreen } from "./sixty-logo-green";
import { SixtyXGreen } from "./sixty-x-green";
import Vector from "./vector";
import { SixtyXWhiteGreen } from "./sixty-x-white-green";
import { MyLibraryIcon } from "./my-library";
import { SearchHeaderIcon } from "./search-header";
import { LogIn } from "./login";
import { MessageIcon } from "./message";
import { ButtonClose } from "./button-close";
import { SendMessage } from "./send-message";
import { BookmarkFilledLGIcon } from "./bookmark-filled-lg";
import { BookmarkIconLG } from "./bookmark-lg";
import { EditProfileIcon } from "./edit-profile";
import { AddIcon } from "./add";
import { ScheduleEvent } from "./schedule-event";
import { AddToCalendar } from "./add-to-calendar";
import { Calendar } from "./calendar";
import { ButtonCloseGreen } from "./button-close-green";
import { ArrowUp } from "./arrow-up";
import { ArrowBackLive } from "./arrow-back-live";
import { ShareArrowIcon } from "./share-arrow";
import { AddToCalendarWhite } from "./add-to-calendar-white";
import { PlayVimeo } from "./play-vimeo";
import { SixtyLogoWhiteBigIcon } from "./sixty-logo-white-big";
import { CheckBgGreen } from "./check-bg-green";
import { CheckEmail } from "./check-email";
import { CompleteProfile } from "./complete-profile";
import { ComplainMessage } from "./complain-message";
import { ApprovalPending } from "./approval-pending";
import { VideoAddIcon } from "./add-video-icon";
import { AddPdfIcon } from "./add-pdf-icon";
import { AddImageIcon } from "./add-image-icon";
import { PlusIcon } from "./plus";
import { SwitchIcon } from "./switch";
import { TimeIcon } from "./time";
import { MagicWand } from "./magic-wand";
import { MinusIcon } from "./minus";
import { MarkerIcon } from "./marker";
import { EditAddressIcon } from "./edit-address";
import { TruckIcon } from "./truck";
import { CheckedInputIcon } from "./checked-input";
import { ShippingIcon } from "./shipping";
import { PaymentIcon } from "./payment";
import { ReturnIcon } from "./return";
import { ShoppingBagIcon } from "./shopping-bag";
import { HearthOutlineIcon } from "./heart-outline";
import { CartIcon } from "./cart";
import { LikeGreenIcon } from "./like-green";
import { LocationIcon } from "./location";
import { ToastCheckMobile } from "./mobileToastCheck";
import { StripeIcon } from "./stripe";
import { LiveIcon } from "./lives";
import { CheckedFormInputIcon } from "./checked-form-input";
import EmptyCart from "./empty-cart";
import { CashIcon } from "./cash";
import { AlertYellowIcon } from "./alert-yellow";
import { EmptyOrders } from "./orders-empty";
import { ChatVerticalDots } from "./chat-vertical-dots";
import { WatchIcon } from "./watch";
import { userSmallIcon } from "./user-small";
import { SoundMuteIcon } from "./mute";
import { ResendIcon } from "./resend";
import { EnableVolume } from "./enable-volume";
import { RecordingIcon } from "./recording";
import { LivePeopleIcon } from "./livePeople";
import { UnionIcon } from "./union";
import { MicrophoneIcon } from "./microphone";
import { CameraIcon } from "./camera";
import { MicrophoneOffIcon } from "./microphone-off";
import { VideocamOffIcon } from "./videocam-off";
import { VideocamIcon } from "./videocam";
import { ZoomInIcon } from "./zoom_in";
import { ZoomOutIcon } from "./zoom_out";
import { KumuVideoIcon } from "./kumu/video-icon";
import { KumuFolderIcon } from "./kumu/folder";
import { KumuArrowIcon } from "./kumu/arrow-icon";
import { KumuIcon } from "./kumu/kumu-icon";
import { KumuGalleryIcon } from "./kumu/gallery";
import { KumuDragIndicator } from "./kumu/drag-indicator";
import { KumuNotificationIcon } from "./kumu/notification-icon";
import { KumuHamburguerMenu } from "./kumu/hamburguer-menu";
import { KumuProfileIcon } from "./kumu/profile";
import { KumuCredentialsIcon } from "./kumu/credentials";
import { KumuConnectIcon } from "./kumu/connect";
import { KumuWalletIcon } from "./kumu/wallet";
import { KumuVuesaxOutlineIcon } from "./kumu/vuesax-outline";
import { XIcon } from "./kumu/x-icon";
import { KumuFeedbackIcon } from "./kumu/feedback-icon";
import { KumuMembersIcon } from "./kumu/members-icon";
import { KumuLoaderIcon } from "./kumu/loader-icon";
import { KumuDownloadIcon } from "./kumu/download-icon";
import { OpenAIIcon } from "./open-ai";
import { KumuIconDesktop } from "./kumu/kumu-icon-desktop";
import { KumuGoToIcon } from "./kumu/goto-icon";
import { LinkedInIcon } from "./linkeding-icon";
import { TikTokIcon } from "./tiktok-icon";
import { FacebookIcon } from "./facebook-icon";
import { YoutubeIcon } from "./youtube-icon";
import { XErrorIcon } from "./x-error-icon";
import { SearchV2Icon } from "./search-v2";
import { KumuMessagesIcon } from "./kumu/messages";
import { KumuPlusIcon } from "./kumu/plus";
import { KumuSearchV3Icon } from "./kumu/search-v3";
import { ExportIcon } from "./export";
import { PracticingIcon } from "./practicing";

const GetIcon: FunctionComponent<IconProps & { iconName: IconName }> = ({
  iconName,
  ...restOfProps
}) => {
  let DynamicIcon;
  switch (iconName) {
    // KUMU
    case "practicing-badge":
      DynamicIcon = PracticingIcon;
      break;
    case "kumu-vuesax-outline":
      DynamicIcon = KumuVuesaxOutlineIcon;
      break;
    case "kumu-arrow-icon":
      DynamicIcon = KumuArrowIcon;
      break;
    case "kumu-video-icon":
      DynamicIcon = KumuVideoIcon;
      break;
    case "kumu-folder-icon":
      DynamicIcon = KumuFolderIcon;
      break;
    case "kumu-back-arrow":
      DynamicIcon = KumuBackArrow;
      break;
    case "kumu-add-icon":
      DynamicIcon = KumuAddIcon;
      break;
    case "kumu-gallery-icon":
      DynamicIcon = KumuGalleryIcon;
      break;
    case "kumu-arrow-right":
      DynamicIcon = KumuArrowRight;
      break;
    case "kumu-chevron-up":
      DynamicIcon = KumuChevronUp;
      break;
    case "kumu-chevron-down":
      DynamicIcon = KumuChevronDown;
      break;
    case "kumu-notification":
      DynamicIcon = KumuNotificationIcon;
      break;
    case "kumu-hamburguer-menu":
      DynamicIcon = KumuHamburguerMenu;
      break;
    case "kumu-drag-indicator":
      DynamicIcon = KumuDragIndicator;
      break;
    case "kumu-profile":
      DynamicIcon = KumuProfileIcon;
      break;
    case "kumu-members":
      DynamicIcon = KumuMembersIcon;
      break;
    case "kumu-credentials":
      DynamicIcon = KumuCredentialsIcon;
      break;
    case "kumu-connect":
      DynamicIcon = KumuConnectIcon;
      break;
    case "kumu-profile":
      DynamicIcon = KumuProfileIcon;
      break;
    case "kumu-export":
      DynamicIcon = ExportIcon;
      break;
    case "kumu-wallet":
      DynamicIcon = KumuWalletIcon;
      break;
    // Video Controls
    case "minus-ten":
      DynamicIcon = MinusTenIcon;
      break;
    case "microphone":
      DynamicIcon = MicrophoneIcon;
      break;
    case "microphone-off":
      DynamicIcon = MicrophoneOffIcon;
      break;
    case "plus-ten":
      DynamicIcon = PlusTenIcon;
      break;
    case "union":
      DynamicIcon = UnionIcon;
      break;
    case "fullscreen":
      DynamicIcon = FullScreenIcon;
      break;
    case "play":
      DynamicIcon = PlayIcon;
      break;
    case "pause":
      DynamicIcon = PauseIcon;
      break;
    // Socials
    case "ig":
      DynamicIcon = InstagramIcon;
      break;
    case "other":
      DynamicIcon = WebIcon;
      break;
    case "open-ai":
      DynamicIcon = OpenAIIcon;
      break;
    // Basics
    case "alert":
      DynamicIcon = AlertIcon;
      break;
    case "like":
      DynamicIcon = LikeIcon;
      break;
    case "live-people":
      DynamicIcon = LivePeopleIcon;
      break;
    case "like-filled":
      DynamicIcon = LikeFilledIcon;
      break;
    case "pencil":
      DynamicIcon = PencilIcon;
      break;
    case "settings":
      DynamicIcon = SettingsIcon;
      break;
    case "categories":
      DynamicIcon = CategoriesIcon;
      break;
    case "about":
      DynamicIcon = AboutIcon;
      break;
    case "subscribe":
      DynamicIcon = SubscribeIcon;
      break;
    case "help":
      DynamicIcon = HelpIcon;
      break;
    case "time":
      DynamicIcon = TimeIcon;
      break;
    case "tip":
      DynamicIcon = TipIcon;
      break;
    case "truck":
      DynamicIcon = TruckIcon;
      break;
    case "login":
      DynamicIcon = LogIn;
      break;
    case "bookmark":
      DynamicIcon = BookmarkIcon;
      break;
    case "switch":
      DynamicIcon = SwitchIcon;
      break;
    case "message":
      DynamicIcon = MessageIcon;
      break;
    case "send-message":
      DynamicIcon = SendMessage;
      break;
    case "bookmark-filled-lg":
      DynamicIcon = BookmarkFilledLGIcon;
      break;
    case "bookmark-lg":
      DynamicIcon = BookmarkIconLG;
      break;
    case "calendar":
      DynamicIcon = Calendar;
      break;
    case "camera":
      DynamicIcon = CameraIcon;
      break;
    case "zoom-in":
      DynamicIcon = ZoomInIcon;
      break;
    case "zoom-out":
      DynamicIcon = ZoomOutIcon;
      break;
    case "checked-input":
      DynamicIcon = CheckedInputIcon;
      break;
    case "checked-form-input":
      DynamicIcon = CheckedFormInputIcon;
      break;
    case "edit-profile":
      DynamicIcon = EditProfileIcon;
      break;
    case "edit-address":
      DynamicIcon = EditAddressIcon;
      break;
    case "add-icon":
      DynamicIcon = AddIcon;
      break;
    case "schedule-event":
      DynamicIcon = ScheduleEvent;
      break;
    case "button-close":
      DynamicIcon = ButtonClose;
      break;
    case "button-close-green":
      DynamicIcon = ButtonCloseGreen;
      break;
    case "complete-profile":
      DynamicIcon = CompleteProfile;
      break;
    case "chat-vertical-dots":
      DynamicIcon = ChatVerticalDots;
      break;
    case "approval-pending":
      DynamicIcon = ApprovalPending;
      break;
    case "explore-by-topic":
      DynamicIcon = ExploreByTopic;
      break;
    case "my-library-icon":
      DynamicIcon = MyLibraryIcon;
      break;
    case "search-header-icon":
      DynamicIcon = SearchHeaderIcon;
      break;
    case "insights":
      DynamicIcon = InsightsIcon;
      break;
    case "bookmark-filled":
      DynamicIcon = BookmarkFilledIcon;
      break;
    case "history":
      DynamicIcon = HistoryIcon;
      break;
    case "notification":
      DynamicIcon = NotificationIcon;
      break;
    case "loading":
      DynamicIcon = LoadingSpinnerIcon;
      break;
    case "lock":
      DynamicIcon = LockIcon;
      break;
    case "lock-card":
      DynamicIcon = LockCardIcon;
      break;
    case "show-password":
      DynamicIcon = ShowPasswordIcon;
      break;
    case "hide-password":
      DynamicIcon = HidePasswordIcon;
      break;
    case "duration":
      DynamicIcon = DurationIcon;
      break;
    case "search":
      DynamicIcon = SearchIcon;
      break;
    case "search-v2":
      DynamicIcon = SearchV2Icon;
      break;
    case "sixty-logo":
      DynamicIcon = SixtyLogo;
      break;
    case "sixty-logo-white":
      DynamicIcon = SixtyLogoWhiteIcon;
      break;
    case "sixty-logo-white-big":
      DynamicIcon = SixtyLogoWhiteBigIcon;
      break;
    case "sixty-logo-white-footer":
      DynamicIcon = SixtyLogoWhiteFooter;
      break;
    case "sixty-logo-big":
      DynamicIcon = SixtyLogoBig;
      break;
    case "sixty-logo-green":
      DynamicIcon = SixtyLogoGreen;
      break;
    case "sixty-x-green":
      DynamicIcon = SixtyXGreen;
      break;
    case "creators":
      DynamicIcon = CreatorsIcon;
      break;
    case "share":
      DynamicIcon = ShareIcon;
      break;
    case "shopping-bag":
      DynamicIcon = ShoppingBagIcon;
      break;
    case "share-arrow":
      DynamicIcon = ShareArrowIcon;
      break;
    case "check-bg-green":
      DynamicIcon = CheckBgGreen;
      break;
    case "save":
      DynamicIcon = SaveIcon;
      break;
    case "sound-mute":
      DynamicIcon = SoundMuteIcon;
      break;
    case "add-to-calendar":
      DynamicIcon = AddToCalendar;
      break;
    case "add-to-calendar-white":
      DynamicIcon = AddToCalendarWhite;
      break;
    case "play-vimeo":
      DynamicIcon = PlayVimeo;
      break;
    case "check-email":
      DynamicIcon = CheckEmail;
      break;
    case "withdraw":
      DynamicIcon = WithdrawIcon;
      break;
    case "support-creator-activity":
      DynamicIcon = SupportCreatorActivityIcon;
      break;
    case "support-creator":
      DynamicIcon = SupportCreatorIcon;
      break;
    case "vector":
      DynamicIcon = Vector;
      break;
    case "sixty":
      DynamicIcon = SixtyIcon;
      break;
    case "sixty-x-white-green":
      DynamicIcon = SixtyXWhiteGreen;
      break;
    case "user":
      DynamicIcon = userIcon;
      break;
    case "user-small":
      DynamicIcon = userSmallIcon;
      break;
    case "to-right-arrow":
      DynamicIcon = ToRightArrowIcon;
      break;
    case "rating":
      DynamicIcon = RatingIcon;
      break;
    case "resend":
      DynamicIcon = ResendIcon;
      break;
    case "recording":
      DynamicIcon = RecordingIcon;
      break;
    case "views":
      DynamicIcon = ViewsIcon;
      break;
    case "video":
      DynamicIcon = VideoIcon;
      break;
    case "add-video-icon":
      DynamicIcon = VideoAddIcon;
      break;
    case "add-pdf-icon":
      DynamicIcon = AddPdfIcon;
      break;
    case "plus-icon":
      DynamicIcon = PlusIcon;
      break;
    case "minus-icon":
      DynamicIcon = MinusIcon;
      break;
    case "add-image-icon":
      DynamicIcon = AddImageIcon;
      break;
    case "explore":
      DynamicIcon = ExploreIcon;
      break;
    case "profile":
      DynamicIcon = ProfileIcon;
      break;
    case "home":
      DynamicIcon = HomeIcon;
      break;
    case "chat":
      DynamicIcon = ChatIcon;
      break;
    case "complain-message":
      DynamicIcon = ComplainMessage;
      break;
    case "arrow-up":
      DynamicIcon = ArrowUp;
      break;
    case "arrow-back":
      DynamicIcon = ArrowBack;
      break;
    case "arrow-back-live":
      DynamicIcon = ArrowBackLive;
      break;
    // Monetary
    case "balance":
      DynamicIcon = BalanceIcon;
      break;
    // Topics
    case "stretching":
      DynamicIcon = StretchingIcon;
      break;
    case "yoga":
      DynamicIcon = YogaIcon;
      break;
    case "magic-wand":
      DynamicIcon = MagicWand;
      break;
    case "meditation":
      DynamicIcon = MeditationIcon;
      break;
    case "marker":
      DynamicIcon = MarkerIcon;
      break;
    case "breathing":
      DynamicIcon = BreathingIcon;
      break;
    case "relaxing":
      DynamicIcon = RelaxingIcon;
      break;
    case "nutritional":
      DynamicIcon = NutritionalIcon;
      break;
    case "close":
      DynamicIcon = CloseIcon;
      break;
    case "trash":
      DynamicIcon = TrashIcon;
      break;
    case "copy":
      DynamicIcon = CopyIcon;
      break;
    case "whatsApp":
      DynamicIcon = WhatsAppIcon;
      break;
    case "email":
      DynamicIcon = EmailIcon;
      break;
    case "increase":
      DynamicIcon = IncreaseIcon;
      break;
    case "decrease":
      DynamicIcon = DecreaseIcon;
      break;
    case "exit":
      DynamicIcon = ExitIcon;
      break;
    case "hand":
      DynamicIcon = HandIcon;
      break;
    case "arrow-left":
      DynamicIcon = ArrowLeftIcon;
      break;
    case "plane":
      DynamicIcon = PlaneIcon;
      break;
    case "options":
      DynamicIcon = OptionsIcon;
      break;
    //Dashboard creators
    case "arrow-down":
      DynamicIcon = ArrowDown;
      break;
    case "subscribers":
      DynamicIcon = SubscribersIcon;
      break;
    case "heart":
      DynamicIcon = Heart;
      break;
    case "heart-outline":
      DynamicIcon = HearthOutlineIcon;
      break;
    case "wallet":
      DynamicIcon = WalletIcon;
      break;
    case "watch":
      DynamicIcon = WatchIcon;
      break;
    case "content":
      DynamicIcon = ContentIcon;
      break;
    case "checkmark-full":
      DynamicIcon = CheckMarkFullIcon;
      break;
    case "checkmark":
      DynamicIcon = CheckMarkIcon;
      break;
    case "x-icon":
      DynamicIcon = XIcon;
      break;
    case "exclamation":
      DynamicIcon = ExclamationIcon;
      break;
    case "update":
      DynamicIcon = UpdateIcon;
      break;
    case "toast-check":
      DynamicIcon = ToastCheckMobile;
      break;
    case "topic-fitness":
      DynamicIcon = TopicFitness;
      break;
    case "topic-nutritional":
      DynamicIcon = TopicNutritional;
      break;
    case "topic-stress":
      DynamicIcon = TopicStress;
      break;
    case "topic-breathing":
      DynamicIcon = TopicBreathing;
      break;
    case "topic-yoga":
      DynamicIcon = TopicYoga;
      break;
    case "topic-meditation":
      DynamicIcon = TopicMeditation;
      break;
    case "topic-anxiety":
      DynamicIcon = TopicAnxiety;
      break;
    case "topic-coaching":
      DynamicIcon = TopicCoaching;
      break;
    case "topic-sleep-health":
      DynamicIcon = TopicSleepHealth;
      break;
    case "topic-stretching":
      DynamicIcon = TopicStretching;
      break;
    case "topic-relaxing":
      DynamicIcon = TopicRelaxing;
      break;
    case "topic-work":
      DynamicIcon = TopicWork;
      break;
    case "shipping-icon":
      DynamicIcon = ShippingIcon;
      break;
    case "payment-icon":
      DynamicIcon = PaymentIcon;
      break;
    case "return-icon":
      DynamicIcon = ReturnIcon;
      break;
    case "cart-icon":
      DynamicIcon = CartIcon;
      break;
    case "cash-icon":
      DynamicIcon = CashIcon;
      break;
    case "like-green":
      DynamicIcon = LikeGreenIcon;
      break;
    case "location-icon":
      DynamicIcon = LocationIcon;
      break;
    case "stripe-icon":
      DynamicIcon = StripeIcon;
      break;
    case "department-live-sessions":
      DynamicIcon = LiveIcon;
      break;
    case "empty-cart":
      DynamicIcon = EmptyCart;
      break;
    case "enable-volume":
      DynamicIcon = EnableVolume;
      break;
    case "alert-yellow":
      DynamicIcon = AlertYellowIcon;
      break;
    case "videocam":
      DynamicIcon = VideocamIcon;
      break;
    case "videocam-off":
      DynamicIcon = VideocamOffIcon;
      break;
    case "empty-orders":
      DynamicIcon = EmptyOrders;
      break;
    case "kumu-icon":
      DynamicIcon = KumuIcon;
      break;
    case "kumu-desktop-icon":
      DynamicIcon = KumuIconDesktop;
      break;
    case "kumu-feedback-icon":
      DynamicIcon = KumuFeedbackIcon;
      break;
    case "kumu-loader-icon":
      DynamicIcon = KumuLoaderIcon;
      break;
    case "kumu-download-icon":
      DynamicIcon = KumuDownloadIcon;
      break;
    case "kumu-goto-icon":
      DynamicIcon = KumuGoToIcon;
      break;
    case "kumu-lock":
      DynamicIcon = KumuLockIcon;
      break;
    case "li":
      DynamicIcon = LinkedInIcon;
      break;
    case "x":
      DynamicIcon = XIcon;
      break;
    case "tt":
      DynamicIcon = TikTokIcon;
      break;
    case "fb":
      DynamicIcon = FacebookIcon;
      break;
    case "yt":
      DynamicIcon = YoutubeIcon;
      break;
    case "x-error-icon":
      DynamicIcon = XErrorIcon;
      break;
    case "kumu-messages":
      DynamicIcon = KumuMessagesIcon;
      break;
    case "kumu-plus":
      DynamicIcon = KumuPlusIcon;
      break;
    case "search-v3":
      DynamicIcon = KumuSearchV3Icon;
      break;
    case "info":
      DynamicIcon = InfoIcon;
      break;
    default:
      return null;
  }
  //@ts-ignore
  return <DynamicIcon {...restOfProps} />;
};

// Example on how to add dynamic icons but those at the moment are not dynamic
const DynamicallyGetIcon: FunctionComponent<
  IconProps & { iconName: string }
> = ({ iconName, ...restOfProps }) => {
  let DynamicIcon;
  switch (iconName) {
    // Topics
    case "stretching":
      DynamicIcon = dynamic(() => import("./stretching"));
      break;
    case "yoga":
      DynamicIcon = dynamic(() => import("./yoga"));
      break;
    case "meditation":
      DynamicIcon = dynamic(() => import("./meditation"));
      break;
    case "breathing":
      DynamicIcon = dynamic(() => import("./breathing"));
      break;
    case "relaxing":
      DynamicIcon = dynamic(() => import("./relaxing"));
      break;
    case "nutritional":
      DynamicIcon = dynamic(() => import("./nutritional"));
      break;

    default:
      return null;
  }
  return <DynamicIcon {...restOfProps} />;
};

export {
  GetIcon,
  DynamicallyGetIcon,
  CheckMarkIcon,
  SixtyLogo,
  StretchingIcon,
  YogaIcon,
  MeditationIcon,
  BreathingIcon,
};
