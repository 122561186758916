import { FunctionComponent } from "react";

export const ResendIcon: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    width="22"
    height="18"
    viewBox="0 0 22 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Icon">
      <g id="icon share">
        <path
          id="Vector"
          d="M11.8508 0.214986C11.7 0.0976615 11.5192 0.0250451 11.3292 0.00540479C11.1391 -0.0142355 10.9473 0.0198898 10.7757 0.103895C10.6041 0.1879 10.4595 0.318409 10.3584 0.480562C10.2573 0.642715 10.2038 0.829995 10.2039 1.02108V5.08826C9.03159 5.13739 7.87474 5.37441 6.77754 5.79028C5.07634 6.43333 3.59341 7.54747 2.50219 9.0024C1.23081 10.688 0.296157 13.0267 0.00433126 16.2348C-0.0149924 16.4438 0.0306529 16.6538 0.135041 16.836C0.239429 17.0181 0.397477 17.1637 0.587624 17.2527C0.777772 17.3418 0.990761 17.37 1.19754 17.3335C1.40431 17.2971 1.59481 17.1977 1.74304 17.049C4.16743 14.6205 6.57754 13.4247 8.35911 12.8308C9.09377 12.5859 9.72232 12.4431 10.2039 12.3615V16.3266C10.2039 16.5233 10.2607 16.7158 10.3675 16.881C10.4743 17.0461 10.6266 17.1769 10.8059 17.2575C10.9853 17.3382 11.1842 17.3653 11.3787 17.3355C11.5731 17.3057 11.7548 17.2204 11.9018 17.0898L21.0851 8.92689C21.1973 8.82744 21.2862 8.7045 21.3454 8.56682C21.4047 8.42913 21.4329 8.28007 21.428 8.13025C21.4232 7.98043 21.3853 7.83352 21.3173 7.69997C21.2492 7.56642 21.1525 7.44952 21.0341 7.35756L11.8508 0.214986ZM11.108 7.13716H11.1141C11.2564 7.15199 11.4003 7.13683 11.5364 7.09268C11.6725 7.04852 11.7978 6.97634 11.9043 6.88079C12.0108 6.78524 12.0962 6.66843 12.1548 6.5379C12.2134 6.40737 12.244 6.26601 12.2447 6.12292V3.10671L18.8138 8.21671L12.2447 14.0532V11.2248C12.2447 10.9541 12.1372 10.6946 11.9458 10.5033C11.7545 10.3119 11.4949 10.2044 11.2243 10.2044H11.1325C10.8211 10.2187 10.5105 10.248 10.2019 10.2921C9.58967 10.3819 8.73256 10.5534 7.71219 10.8942C5.84002 11.53 4.08431 12.4675 2.51444 13.6696C2.91034 12.2084 3.48583 11.0901 4.13274 10.2309C4.99347 9.08179 6.16453 8.20243 7.50812 7.69633C8.57738 7.29248 9.71426 7.09781 10.857 7.12288C10.9488 7.12492 11.0182 7.129 11.061 7.13308L11.1059 7.13716H11.108Z"
          fill="white"
        />
      </g>
    </g>
  </svg>
);
