import { FunctionComponent } from "react";

const XIcon: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    width="14"
    height="13"
    viewBox="0 0 14 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.0336725 0L5.43888 7.17016L0 13H1.22443L5.98663 7.89616L9.834 13H14L8.29094 5.42652L13.3537 0H12.1293L7.74401 4.70055L4.2005 0H0.0344844H0.0336725ZM1.83376 0.894659H3.74719L12.1983 12.1054H10.2848L1.83376 0.894659Z"
      fill="#202421"
    />
  </svg>
);

export { XIcon };
