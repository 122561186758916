import { FunctionComponent } from "react";

export const OptionsIcon: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="10.0004" cy="4.66667" r="1.66667" />
    <circle cx="10.0004" cy="10.5007" r="1.66667" />
    <circle cx="10.0004" cy="16.3327" r="1.66667" />
  </svg>
);
