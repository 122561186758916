import { FunctionComponent } from "react";

export const CompleteProfile: FunctionComponent<IconProps> = ({
  className,
}) => (
  <svg
    width={230}
    height={230}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M59.225 113.461a18.985 18.985 0 0 0-4.384 4.823c-5.32-2.89-11.547-2.44-17.432 2.62-7.618 6.703-6.268 22.344 4.403 24.285a15.83 15.83 0 0 0 4.042.217.98.98 0 0 0 .052-1.955c-6.749-.824-10.062-4.938-9.993-11.055a12.278 12.278 0 0 1 3.672-8.994c4.002-3.58 8.997-5.06 14.005-3.061-.275.493-.535.982-.763 1.451a1.002 1.002 0 0 0 1.061 1.515c.26-.058.485-.216.628-.439.308-.372.726-.878 1.197-1.455.455.268.893.564 1.31.889a1 1 0 0 0 1.11.136.993.993 0 0 0 .422-1.357.998.998 0 0 0-.251-.302c-.405-.374-.82-.72-1.244-1.048 1.301-1.644 2.709-3.502 3.598-4.925.707-1.125-.349-2.121-1.433-1.345Z"
      fill="#2C9084"
    />
    <path
      d="M43.763 132.686a21.428 21.428 0 0 1 3.105-3.083.988.988 0 0 1 1.063-.36.995.995 0 0 1 .484 1.601 20.286 20.286 0 0 1-3.39 5.501 1.656 1.656 0 0 1-2.081.36 1.652 1.652 0 0 1-.555-.514c-.268-.397-1.01-1.15-1.765-2.008-1.188-1.29-3.349-4.223-1.907-5.041 1.132-.641 4.153 2.602 5.046 3.544ZM27.53 118.029c-.017 1.174-.037 5.45-.038 5.476-.115 1.61-.345 4.041-1.471 4.094-2.208.053-1.869-9.068-1.754-11.27a1.679 1.679 0 0 1 1.651-1.587c2.205.055 10.171-.165 10.03 1.84-.065.94-.551 1.06-5.52 1.343-.965.065-1.926.079-2.897.104Z"
      fill="#2C9084"
    />
    <path
      d="M168.652 115.851c-.16-.594-.712-.46-1.817-.345-4.37.53-10.235 1.265-14.246-.467-8.008-3.266-5.479-12.601-5.003-20.74a.994.994 0 0 0-.946-1.061 57.843 57.843 0 0 0-6.872.09c1.592-6.168 4.324-12.702 8.138-22.366a.58.58 0 0 0-.295-.78.61.61 0 0 0-.793.306c-2.008 4.39-6.767 13.018-9.327 23.658a1.01 1.01 0 0 0 .997 1.241c2.379-.033 4.507-.25 6.999-.18-.602 7.884-2.801 17.941 6.385 21.585 1.657.575 3.382.933 5.131 1.063.183 3.921.076 7.82.512 12.206a.618.618 0 0 0 .892.486.587.587 0 0 0 .299-.354.602.602 0 0 0 .025-.235c-.219-3.597-.026-8.101-.032-11.93a18.316 18.316 0 0 0 8.481-1.024c.923-.364 1.624-.519 1.472-1.153Z"
      fill="#fff"
    />
    <path
      d="M156.63 102.464a4.239 4.239 0 0 0 3.105 2.227c3.074.492 4.715-2.022 5.31-5.093.146-.979.236-1.966.269-2.955a.609.609 0 0 0-.48-.71.607.607 0 0 0-.711.48c-1.136 3.601-1.408 4.382-1.88 5.131a2.102 2.102 0 0 1-2.636 1.088 7.216 7.216 0 0 1-2.059-.92.597.597 0 0 0-.918.752ZM153.825 67.34c1.28-1.437 3.123-1.022 4.747 0 2.148 1.329 2.156 2.221 2.853 1.767.89-.567-.92-2.682-1.766-3.314-3.12-2.488-6.9-1.365-7.831 1.253-.31.839-.503 1.622.052 1.83.638.267.696-.443 1.945-1.535ZM153.797 76.905c0 .943.228 1.848.633 2.515.406.667.955 1.042 1.529 1.042.573 0 1.123-.375 1.529-1.042.405-.667.633-1.572.633-2.515 0-.944-.228-1.849-.633-2.516-.406-.667-.956-1.041-1.529-1.041-.574 0-1.123.374-1.529 1.041-.405.668-.633 1.572-.633 2.516Z"
      fill="#fff"
    />
    <path
      d="M183.532 49.18a31.181 31.181 0 0 0-10.274-.32c-1.035-.703-14.086-9.2-18.258-12-5.195-3.487-9.825-7.494-15.891-5.066-10.821 4.327-15.338 22.014-3.851 28.186 4.049 2.185 8.564 1.769 13.127 1.7 6.136-.123 11.896-.296 18.066-.908a20.177 20.177 0 0 0 15.64 18.386c2.268.576 1.463.105 2.668.048 2.361-.115 6.126-.245 7.904 1.31a6.556 6.556 0 0 1 .819 8.38 10.198 10.198 0 0 1-8.227 3.865 1.02 1.02 0 0 0-.998 1.38c.427 1.1.778 2.23 1.05 3.378.141.575 2.241 10.047 2.444 11.054a95.708 95.708 0 0 1 1.944 13.435.604.604 0 0 0 1.047.383.595.595 0 0 0 .155-.435 84.974 84.974 0 0 0-1.811-16.283c2.081-5.075 4.508-9.356 5.683-15.18.115-.143.246-.275.345-.422a8.702 8.702 0 0 0 1.159-7.322c.401-1.907.675-3.838.82-5.781 1.172-12.217-1.594-25.291-13.561-27.788Zm-40.856-12.453a8.868 8.868 0 0 0-4.337 2.776 15.35 15.35 0 0 0-3.352 6.91.603.603 0 0 1-.201.416.593.593 0 0 1-.439.146.608.608 0 0 1-.562-.64c-.172-5.921 4.395-10.817 8.469-11.477.863-.154 3.363-.142 3.288.832-.056.712-.607.352-2.866 1.037Z"
      fill="#65B0B7"
    />
    <path
      d="m223.608 197.296-.098.007-.199-.766a.586.586 0 0 0 .451-.584c-.363-27.462-7.376-57.569-30.644-72.15l-.703-.127c-5.668 1.108-11.05 3.71-16.25 5.949-5.708 2.443-11.207 4.685-16.3 7.293a29.494 29.494 0 0 1-7.191 3.045 3.816 3.816 0 0 1-1.74 0c-.291-.652 1.732-3.117 2.2-3.659a.995.995 0 0 0-.001-1.326.998.998 0 0 0-1.317-.158 86.453 86.453 0 0 0-31.589 38.537.986.986 0 0 0 .153 1.035 52.61 52.61 0 0 0 10.019 9.941 1.002 1.002 0 0 0 1.394-.256c1.88-2.663 4.167-5.132 6.104-7.561.115.115 23.773 21.168 29.517 25.614a.968.968 0 0 0 .767.192c6.976-1.205 14.26-.762 21.354-1.113 11.635-.62 22.326-1.602 34.175-2.738a.593.593 0 0 0 .537-.64.593.593 0 0 0-.639-.537v.002Z"
      fill="#2C9084"
    />
    <path
      d="M141.297 170.901c7.935-9.601 11.04-13.059 23.299-25.321a.61.61 0 0 0 .192-.419.593.593 0 0 0-.161-.432.604.604 0 0 0-.85-.031 128.889 128.889 0 0 0-20.625 21.11c-1.247 1.714-1.983 3.001-2.828 4.403a.603.603 0 0 0 .353.927.608.608 0 0 0 .451-.077.613.613 0 0 0 .169-.16ZM62.242 144.313a28.298 28.298 0 0 0-1.725 13.037c1.776 19.246 19.568 38.977 26.754 48.568a.604.604 0 1 0 .984-.702c-5.315-7.793-10.276-14.201-14.7-20.958-7.57-11.73-14.018-26.16-10.185-39.575a.594.594 0 1 0-1.128-.37ZM136.876 189.488a453.392 453.392 0 0 0-3.883-2.334c3.078-3.695 3.045-4.109 5.379-7.183a.61.61 0 0 0-.102-.844.622.622 0 0 0-.857.103c-1.736 2.152-4.235 5.29-6.038 7.19a147.755 147.755 0 0 1-26.537-21.93c-.614-.639-4.8-4.748-6.192-6.308a.598.598 0 0 0-.909.781 103.647 103.647 0 0 0 15.308 16.173 129.81 129.81 0 0 0 10.518 8.316 146.366 146.366 0 0 0 12.691 7.89c1.999.908 2.659 1.063 2.944.55.347-.636-.477-1.064-2.322-2.404Z"
      fill="#fff"
    />
    <path
      d="M66.177 144.968a13.09 13.09 0 0 0-.217 5.937c.086.398.713 2.954 1.56 2.751.568-.159.46-.471.32-3.02-.185-1.74-.12-3.498.192-5.22.167-1.495.1-1.993-.332-2.137-.656-.218-1.374 1.287-1.523 1.689ZM70.912 145.776c.01 1.344.235 2.678.665 3.952.276.737 1.058 2.558 1.792 2.253.599-.24.364-.739.038-2.76a19.72 19.72 0 0 1-.487-3.505c-.023-.648 0-2.845-.819-2.943-.818-.098-1.224 1.877-1.189 3.003Z"
      fill="#fff"
    />
    <path
      d="M96.176 145.995c-2.227-3.233-5.75-2.131-8.344-2.686-3.84-.805-6.975-5.262-7.228-9.098a1.007 1.007 0 0 0-.832-.92 9.396 9.396 0 0 0-9.698 5.579.966.966 0 0 0 .998 1.343c4.27-.528 7.007 4.255 6.555 8.28a.557.557 0 0 0-.115.129c-.22.276-.062 1.279.397 1.408.064.019.13.025.196.019a.517.517 0 0 0 .188-.058c2.89 2.849 2.436 8.873 7.484 10.58 7.767 2.579 14.984-7.919 10.399-14.576Z"
      fill="#fff"
    />
    <path
      d="M58.586 151.558c-1.79.058-4.645.054-6.038-1.023-3.2-2.445-1.15-9.345-.679-13.076.392-3.176.403-7.737 2.496-10.211.833-.967 2.538-1.102 3.825-1.201 9.858-.381 19.727.435 29.389 2.431 2.395.453 4.907 1.097 5.95 3.313 1.251 2.723.01 7.074-.307 10.159a.603.603 0 0 0 .53.671.605.605 0 0 0 .672-.531c.469-3.36 1.966-7.64.575-10.951-1.21-2.844-4.047-3.812-7.062-4.453a115.515 115.515 0 0 0-29.889-2.661c-1.939.124-3.985.438-5.233 1.919-2.504 2.967-2.476 7.475-2.93 11.27-.483 4.115-3.627 14.631 4.593 15.598 1.39.115 2.787.094 4.172-.064a.59.59 0 0 0 .53-.395.604.604 0 0 0-.595-.795ZM40.182 45.821c3.565-.783 8.2-.75 28.583-2.866 1.932-.192 3.852-.371 5.796-.46a.602.602 0 0 0 0-1.203c-8.012-.345-12.146.428-19.537 1.035-6.341.567-9.965.345-15.18 2.356a.589.589 0 0 0-.12 1.086.61.61 0 0 0 .458.052ZM26.644 64.851c5.865-.601 55.006-3.6 55.605-3.62 5.84-.258 11.398.076 17.542-.04a.61.61 0 0 0 .586-.846.61.61 0 0 0-.586-.369c-5.865-.127-11.194-.718-17.605-.627-7.624.152-39.445 2.332-43.682 2.7-4.176.356-8.12.92-12.027 1.626a.595.595 0 1 0 .167 1.176ZM27.549 86.135c4.552-.516 55.038-3.61 55.605-3.634 5.358-.23 12 .083 17.541-.038a.613.613 0 0 0 .614-.601.6.6 0 0 0-.614-.601c-6.18-.154-11.233-.732-17.605-.64-8.378.208-39.887 2.377-43.68 2.7-3.492.334-6.512.65-12.027 1.637a.594.594 0 0 0 .166 1.177ZM29.937 106.116c4.552-.516 55.039-3.611 55.606-3.634 5.15-.23 12.075.064 17.541-.038a.61.61 0 0 0 .614-.601.618.618 0 0 0-.182-.43.602.602 0 0 0-.432-.172c-6.202-.154-11.24-.731-17.606-.639-8.379.208-39.886 2.377-43.68 2.699-3.492.334-6.512.651-12.027 1.637a.593.593 0 0 0-.512.666.612.612 0 0 0 .678.512Z"
      fill="#fff"
    />
    <path
      d="M47.885 160.362c-9.584.07-22.907 1.14-32.486 1.765a4.309 4.309 0 0 1-4.555-4.002c-.884-14.158-.744-37.542-1.023-43.451-.268-5.015-.652-10.031-.794-15.046-.32-12.183-1.176-64.001-1.214-67.583a4.11 4.11 0 0 1 4.298-4.223c3.864.14 7.741.09 11.605-.025 5.783-.18 83.331-1.972 84.547-1.984a4.146 4.146 0 0 1 4.107 4.043c.446 13.36 1.929 25.621 2.495 38.339.805 17.394.361 35.05.869 52.458.242 9.167 1.309 20.896 1.972 31.091a4.487 4.487 0 0 1-4.338 4.708c-8.647.249-7.257.107-10.262.308a.608.608 0 0 0 .077 1.214c2.115-.144 3.463-.06 5.643-.102l4.581-.128a5.785 5.785 0 0 0 4.102-1.849 5.789 5.789 0 0 0 1.54-4.229c-.372-6.908-1.663-28.16-1.725-31.064-.269-12.232-.09-24.477-.192-36.721-.275-28.314-2.185-34.422-2.776-54.084a6.138 6.138 0 0 0-6.103-5.962c-7.36.077-12.28.516-18.387.563-9.787.077-42.389.436-48.914.654-9.208.288-19.363 1.088-28.761.741a6.166 6.166 0 0 0-6.41 6.283c.056 5.274-.137 10.267.076 15.417.204 5.22.857 40.01 1.19 52.189.153 5.758.666 11.49.908 17.235.383 8.584.014 25.294 1.177 41.326a5.963 5.963 0 0 0 6.385 5.502c10.495-.788 21.007-1.849 32.396-2.185a.597.597 0 0 0 .413-.181.589.589 0 0 0 .162-.421.601.601 0 0 0-.373-.553.587.587 0 0 0-.23-.043Z"
      fill="#fff"
    />
  </svg>
);
