import { FunctionComponent } from "react";

export const Heart: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    width={35}
    height={32}
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path d="M0 10.01C.211 6.215 1.961 2.716 6.031.825 9.66-.86 13.444.05 16.261 3.422c.235.28.455.573.666.872.187.266.32.25.51-.013a13.969 13.969 0 0 1 1.82-2.072c3.45-3.197 8.547-2.643 11.693.332 4.145 3.918 4.58 10.802.989 15.225-1.358 1.672-2.97 3.109-4.486 4.632-1.326 1.332-2.689 2.627-4.032 3.942-1.795 1.758-3.602 3.502-5.488 5.16-.742.653-.762.677-1.51.01-1.2-1.072-2.396-2.15-3.55-3.275-2.444-2.382-4.854-4.804-7.292-7.194-1.041-1.02-2.118-2.003-3.05-3.133C.806 15.817.007 13.384 0 10.01Z" />
  </svg>
);
