import { createContext, FunctionComponent, useState } from "react";

interface IUserContext {
  user: User;
  email: string;
}

interface DispatchUserContext {
  setUser: (state: User) => any;
  resetUser: () => any;
}

const initialUserState = {
  email: "",
  id: "",
  likedCoursesIDs: [],
  userInitials: "",
  isOnboardingCompleted: false,
  likedItems: [],
};

const defaultState: IUserContext & DispatchUserContext = {
  user: initialUserState,
  email: "",
  setUser: () => {},
  resetUser: () => {},
};

const UserContext = createContext<IUserContext & DispatchUserContext>(
  defaultState
);

type OwnProps = {
  value: IUserContext;
};

type Props = React.PropsWithChildren<OwnProps>;

const UserProvider: FunctionComponent<Props> = ({ value, children }) => {
  const [state, setState] = useState(value ? value : defaultState);

  const setUser = (user: User) => {
    setState({ ...state, user });
  };

  const resetUser = () => {
    setState(defaultState);
  };

  return (
    <UserContext.Provider value={{ ...state, setUser, resetUser }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;

export { UserProvider };
