import { clientFetchService } from "@/services/fetch";
import { FileSystemServices } from "@/services/filesystem";
import { VideoToolsService } from "@/services/video-tools";
import { createCookie, EXPIRE_IN_A_YEAR, getCookie } from "@/utils/cookies";
import useMediaQuery, { desktop } from "@/utils/hooks/useMediaQuery";
import { SIXTY_URLS } from "@/utils/urls";
import { KUMU_URLS } from "@/utils/urls/kumu";
import React, {
  createContext,
  FunctionComponent,
  ReactNode,
  useState,
  useEffect,
  useContext,
} from "react";
import { useSEvents } from "../sevents/hooks";
import UserContext from "../user";
import {
  getVideoDurationFromFile,
  getVideoDurationFromPresignedUrl,
  getVideoResolutionFromPresignedUrl,
} from "@/utils/helpers/commonHelper";

interface UploaderContextType {
  uploadFile: UploadFileType[];
  setUploadFile: React.Dispatch<React.SetStateAction<UploadFileType[]>>;
  enableUploadV2: boolean;
  fileSystemContext?: KumuFileSystem;
  finishedFiles?: FinishedFile[];
  deleteFile: (fileID?: string) => Promise<void>;
  updateFileStatus: (fileID?: string) => Promise<void>;
}

const UploaderContext = createContext<UploaderContextType | undefined>(
  undefined
);

type GeneratedFileOnFileSystem = { url: string; fileCreated: KumuFile };

type GeneratedVimeoFile =
  | {
      video: {
        result: {
          url: string;
          vimeoPath: string;
        };
      };
      duration: number | undefined;
      width: number | undefined;
      height: number | undefined;
    }
  | null
  | undefined;

type Props = {
  children: ReactNode;
};

export const UploaderProvider: FunctionComponent<Props> = ({ children }) => {
  const [uploadFile, setUploadFile] = useState<UploadFileType[]>([]);
  const [processedFiles, setProcessedFiles] = useState<Set<string>>(new Set());
  const [enableUploadV2, setEnabledUploadV2] = useState<boolean>(false);
  const [fileSystemContext, setFileSystemContext] = useState<KumuFileSystem>();
  const [finishedFiles, setFinishedFiles] = useState<FinishedFile[]>([]);
  const isDesktop = useMediaQuery(desktop);
  const { user } = useContext(UserContext);
  const creatorID = user.creator?.id ?? "";
  const { uploadFileSevent, successFileUploadedSevent } = useSEvents();
  useEffect(() => {
    console.log("v2");
    // To enable this feature flag you need to add 'updateV2' cookie on the browser with 'true' value
    //const cookiev2 = getCookie(document.cookie, "updateV2");
    const cookiev2 = "true";
    createCookie("updateV2", cookiev2, EXPIRE_IN_A_YEAR);

    setEnabledUploadV2(cookiev2 === "true" ?? false);
    loadFileSystem();
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      const filesInProgress = uploadFile.some(
        (file) => file.progress >= 0 && file.progress < 100
      );
      if (filesInProgress) {
        const message =
          "You have files being uploaded. Are you sure you want to leave?";
        event.preventDefault();
        event.returnValue = message;
        return message;
      }
    };

    const handleUnload = (event: any) => {
      event.preventDefault();
      cleanUnfinishedFiles();
      const message = "You are about to leave";
      event.returnValue = message;
      return message;
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("unload", handleUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("unload", handleUnload);
    };
  }, [uploadFile]);

  useEffect(() => {
    const pendingFiles = uploadFile.filter(
      (file) => !processedFiles.has(file.phisicalName)
    );

    const processPendingFiles = async () => {
      for (let file of pendingFiles) {
        setProcessedFiles((prevProcessedFiles) =>
          new Set(prevProcessedFiles).add(file.phisicalName)
        );

        try {
          await processUpload(file);
        } catch (error) {
          console.error(`Error processing file ${file.fileName}:`, error);
        }
      }
    };

    if (pendingFiles.length > 0) {
      processPendingFiles();
    }
  }, [uploadFile]);

  //MAIN PROCESS
  const processUpload = async (file: UploadFileType) => {
    // file generation in fileSystem
    console.log("File to process: ", file.fileName);
    uploadFileSevent({
      payload: {
        creatorID,
        fileName: file.fileName,
        mimeType: file.mimeType,
        size: file.size,
      },
    });

    //S3 upload process
    let upload: GeneratedFileOnFileSystem | null = null;
    if (file.filesystemFile) {
      console.log("Existing file processing");
      upload = { fileCreated: file.filesystemFile, url: "" };
    } else {
      console.log("New file processing");
      upload = await generateFileFileSystem(file);
      await manageS3Put(upload.url, { ...file, fileId: upload.fileCreated.id });
      updateFileId(file.phisicalName, upload.fileCreated.id);
    }

    // If the process comes from post/course creation we run the vimeo upload process
    // We need the origin and the current courseId
    if (file.origin === "CONTENT_CREATION" && file.courseId) {
      //s3 get link process
      const s3GetPresignedUrl: any = await manageS3Get(upload.fileCreated.id);
      if (s3GetPresignedUrl && s3GetPresignedUrl.data.link) {
        // vimeo upload process
        const vimeo: GeneratedVimeoFile | null =
          file.contentType === "DIGITAL_PRODUCT"
            ? null
            : await vimeoHandlerWithPresignedURL({
                ...upload.fileCreated,
                presignedUrl: s3GetPresignedUrl.data.link,
              });
        // update of coursemodule or post acording to id
        if (file.contentType === "MULTI_MODULE") {
          console.log("Processing serie module");
          if (file.moduleId && vimeo) {
            const addedModule: any = await clientFetchService(
              KUMU_URLS.API.COURSES.UPDATE_MODULE(file.moduleId),
              {
                method: "PATCH",
                body: JSON.stringify({
                  video: { url: vimeo.video.result.url },
                  vimeoVideoMetadata: { path: vimeo.video.result.vimeoPath },
                  duration: vimeo.duration,
                  resol_width: vimeo.width,
                  resol_height: vimeo.height,
                }),
              }
            );

            console.log(
              "New serie module processed: ",
              JSON.stringify(addedModule)
            );
            if (addedModule)
              setFinishedFiles((prevProcessedFiles) => [
                ...prevProcessedFiles,
                {
                  phisicalName: file.phisicalName,
                  courseId: file.courseId ?? "",
                  moduleId: file.moduleId ?? addedModule.id,
                  response: addedModule,
                },
              ]);
          }
        } else if (file.contentType === "SINGLE_POST") {
          console.log("Processing post module");
          if (vimeo) {
            const addedModule: KumuAddedModuleResponse =
              await clientFetchService(
                KUMU_URLS.API.COURSES.PATCH(file.courseId),
                {
                  method: "PATCH",
                  body: JSON.stringify({
                    video: { url: vimeo.video.result.url },
                    vimeoVideoMetadata: { path: vimeo.video.result.vimeoPath },
                    duration: vimeo.duration,
                    resol_width: vimeo.width,
                    resol_height: vimeo.height,
                  }),
                }
              );

            console.log(
              "New post module processed: ",
              JSON.stringify(addedModule)
            );

            if (addedModule)
              setFinishedFiles((prevProcessedFiles) => [
                ...prevProcessedFiles,
                {
                  phisicalName: file.phisicalName,
                  courseId: file.courseId ?? addedModule.id,
                  response: {
                    ...addedModule,
                    video: { url: vimeo.video.result.url }, // Check why endp its not returning video obj
                  },
                },
              ]);
          }
        } else if (file.contentType === "DIGITAL_PRODUCT") {
          console.log("Processing digital product");
          const updatedCourse: any = await clientFetchService(
            KUMU_URLS.API.COURSES.PATCH(file.courseId),
            {
              method: "PATCH",
              body: JSON.stringify({
                digitalProductId:
                  file.filesystemFile?.id ?? upload.fileCreated.id,
              }),
            }
          );

          console.log(
            "New digital product processed: ",
            JSON.stringify(updatedCourse)
          );
          if (updatedCourse)
            setFinishedFiles((prevProcessedFiles) => [
              ...prevProcessedFiles,
              {
                phisicalName: file.phisicalName,
                courseId: file.courseId ?? updatedCourse.id,
                response: {
                  ...updatedCourse,
                },
              },
            ]);
        }
      }
    }
    //when the process it`s done remove the file from the list
    removeFile(file);
  };

  //UTILS
  const updateProgress = (phisicalName: string, progress: number) => {
    setUploadFile((prevFiles) =>
      prevFiles.map((file) =>
        file.phisicalName === phisicalName ? { ...file, progress } : file
      )
    );
  };

  const removeFile = (currentFile: UploadFileType) => {
    setUploadFile((prevFiles) =>
      prevFiles.filter((file) => file.phisicalName !== currentFile.phisicalName)
    );
    setProcessedFiles((prevProcessedFiles) => {
      const newProcessedFiles = new Set(prevProcessedFiles);
      newProcessedFiles.delete(currentFile.phisicalName);
      return newProcessedFiles;
    });
  };

  const thumbnailLiveGenerator = async (fileVideo: File) => {
    const thumbnail = await VideoToolsService.generateThumbnail(
      isDesktop,
      fileVideo,
      1
    );
    const formData = new FormData();
    formData.append("thumbnail", thumbnail);
    const thumbnail_url: { url: string } = await clientFetchService(
      SIXTY_URLS.API.COURSES_UPLOAD_THUMBNAIL,
      {
        method: "POST",
        body: formData,
      }
    );
    return thumbnail_url.url;
  };

  // const getVideoDurationFromFile = (presignedURL?: string) => {
  //   return new Promise((resolve, reject) => {
  //     if (presignedURL) {
  //       const video = document.createElement("video");
  //       video.preload = "metadata";
  //       video.onloadedmetadata = () => {
  //         window.URL.revokeObjectURL(video.src);
  //         resolve(video.duration);
  //       };
  //       video.onerror = (error) => {
  //         reject(error);
  //       };
  //       video.src = presignedURL;
  //     }
  //   });
  // };

  const updateFileId = (phisicalName: string, fileId: string) => {
    setUploadFile((prevFiles) =>
      prevFiles.map((file) =>
        file.phisicalName === phisicalName ? { ...file, fileId } : file
      )
    );
  };

  const updateFileStatus = async (fileID?: string) => {
    if (fileID) {
      await clientFetchService(SIXTY_URLS.API.FILES.DELETE(fileID), {
        method: "PATCH",
      });
      return;
    }
  };

  //FILE SYSTEM FUNCTIONS
  const generateFileFileSystem = async (
    file: UploadFileType
  ): Promise<GeneratedFileOnFileSystem> => {
    const thumbnailFinal = !file.thumbnailUrl
      ? await thumbnailLiveGenerator(file.file as File)
      : file.thumbnailUrl;

    return await clientFetchService(SIXTY_URLS.API.FILESYSTEM, {
      method: "POST",
      body: JSON.stringify({
        mimeType: file.mimeType,
        size: file.size,
        fileName: file.fileName,
        description: file.description,
        folderName:
          file.contentType === "DIGITAL_PRODUCT"
            ? "digital_product"
            : "folderName",
        thumbnail: thumbnailFinal,
      }),
    });
  };

  const loadFileSystem = async () => {
    const currentFileSystem: any = await clientFetchService(
      SIXTY_URLS.API.FILESYSTEM,
      {
        method: "GET",
      }
    );
    setFileSystemContext(currentFileSystem);
  };

  const deleteFile = async (fileID?: string) => {
    if (fileID) {
      await clientFetchService(SIXTY_URLS.API.FILES.DELETE(fileID), {
        method: "DELETE",
      });
      await loadFileSystem();
      return;
    }
  };

  const cleanUnfinishedFiles = () => {
    uploadFile.map((file) => {
      file.fileId && deleteFile(file.fileId);
    });
  };

  //S3 FUNCTIONS
  const manageS3Put = async (presignedUrl: string, file: UploadFileType) => {
    await FileSystemServices.uploadFilesWithPreSignedUrl_withProgress(
      presignedUrl,
      file.file as File,
      (value: number) => {
        updateProgress(file.phisicalName, Math.round(value));
      }
    );
    //change file status when upload is complete
    file.fileId && (await updateFileStatus(file.fileId));
    //reload file system
    loadFileSystem();
    successFileUploadedSevent({
      payload: {
        creatorID,
      },
    });
  };

  const manageS3Get = async (id: string) => {
    return await clientFetchService(
      KUMU_URLS.API.CONTENT.SOCIAL.EXPORTED_FILE(id),
      {
        method: "GET",
      }
    );
  };

  //VIMEO FUNCTION

  const vimeoHandlerWithPresignedURL = async (
    currentFile: KumuFile & { presignedUrl: string }
  ): Promise<GeneratedVimeoFile> => {
    try {
      await clientFetchService(SIXTY_URLS.API.VIMEO.CREATE_URI_FROM_URL, {
        method: "POST",
        body: JSON.stringify({
          size: currentFile.size,
          presignedURL: currentFile.presignedUrl,
          name: currentFile.filename,
        }),
      })
        .then(async (vimeo_response: any) => {
          const { uri } = vimeo_response as {
            uri: string;
          };

          const currentDuration = (await getVideoDurationFromPresignedUrl(
            currentFile.presignedUrl
          )) as number | undefined;

          const dimensions = (await getVideoResolutionFromPresignedUrl(
            currentFile.presignedUrl
          )) as { width: number; height: number } | undefined;

          const uriParts = uri.split("/");
          const videoID = uriParts[uriParts.length - 1];
          //check resolution
          return {
            video: {
              result: {
                url: `https://player.vimeo.com/video/${videoID}`,
                vimeoPath: uri,
              },
            },
            duration: currentDuration,
            width: dimensions?.width,
            height: dimensions?.height,
          };
        })
        .catch((resp) => {
          console.log("FILE UPLOAD RESULT", resp);
          return null;
        });
    } catch (error) {
      console.log("Error uploading video", error);
      return null;
    }
  };

  const value: UploaderContextType = {
    finishedFiles,
    uploadFile,
    setUploadFile,
    enableUploadV2,
    fileSystemContext,
    deleteFile,
    updateFileStatus,
  };

  return (
    <UploaderContext.Provider value={value}>
      {children}
    </UploaderContext.Provider>
  );
};

export default UploaderContext;

// UseUploadFile hook
export const useUploaderContext = () => {
  const context = useContext(UploaderContext);
  if (!context) {
    throw new Error("useUploaderContext must be used within a UploadFile");
  }
  return context;
};
