import { parseDomain, ParseResultType } from "parse-domain";

const getCookieDomain = () => {
  let cookieDomain;

  try {
    const parseResult = parseDomain(window.location.hostname);
    if (parseResult.type === ParseResultType.Listed) {
      const { domain, labels, subDomains } = parseResult;
      const finalSubDomain = subDomains.length
        ? subDomains[subDomains.length - 1]
        : domain;
      if (domain === "localhost") {
        cookieDomain = "localhost";
      } else if (labels.includes("ngrok")) {
        cookieDomain = ".sa.ngrok.io";
      } else if (domain === "kumu" && subDomains.length) {
        cookieDomain = `${finalSubDomain}.${domain}.co`;
      } else {
        cookieDomain = `${finalSubDomain}.${domain}.app`;
      }
    } else {
      cookieDomain = window.location.hostname;
    }
  } catch (e) {
    console.error("Error parsing domain:", e);
    cookieDomain = "kumu.co";
  }

  return cookieDomain;
};

export const createCookie = (
  cookieName: string,
  cookieValue: string,
  hourToExpire: number
) => {
  let date = new Date();
  const cookieDomain = getCookieDomain();

  date.setTime(date.getTime() + hourToExpire * 60 * 60 * 1000);
  document.cookie = `${cookieName}=${cookieValue}; Domain=${cookieDomain}; expires = ${date.toUTCString()}; path=/`;
};

export const deleteCookie = (cookieName: string) => {
  const cookieDomain = getCookieDomain();
  document.cookie = `${cookieName}=; Domain=${cookieDomain}; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/`;
};

export const getCookie = (cookieBase: any, cname: string) => {
  if (!cookieBase || cookieBase === "") return "";

  let name = cname + "=";
  let decodedCookie = decodeURIComponent(cookieBase);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

// Cookie mismatch utils
const VERSION_COOKIE_NAME = "cookie_version";
const COOKIE_VERSION = process.env.NEXT_PUBLIC_COOKIE_VERSION || "0.1.0";

const getOldCookieDomain = () => {
  let cookieDomain;

  try {
    const parseResult = parseDomain(window.location.hostname);
    if (parseResult.type === ParseResultType.Listed) {
      const { domain, labels } = parseResult;
      if (domain === "localhost") {
        cookieDomain = "localhost";
      } else if (labels.includes("ngrok")) {
        cookieDomain = ".sa.ngrok.io";
      } else if (domain === "kumu") {
        cookieDomain = `${domain}.co`;
      } else {
        cookieDomain = `${domain}.app`;
      }
    } else {
      cookieDomain = window.location.hostname;
    }
  } catch (e) {
    cookieDomain = "kumu.co";
  }

  return cookieDomain;
};

export const deleteCookieByDomain = (
  cookieName: string,
  cookieDomain: string
) => {
  document.cookie = `${cookieName}=; Domain=${cookieDomain}; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/`;
};

const deleteAllCookies = () => {
  const cookies = document.cookie.split(";");

  const oldCookieDomain = getOldCookieDomain();
  const currentCookieDomain = getCookieDomain();

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf("=");
    const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;

    deleteCookieByDomain(name, oldCookieDomain);
    deleteCookieByDomain(name, currentCookieDomain);
  }
};

const getCookieByName = (name: string) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop()?.split(";").shift();
  return null;
};

export const checkAndClearCookies = () => {
  const currentVersion = getCookieByName(VERSION_COOKIE_NAME);
  const expectedVersion = COOKIE_VERSION;

  if (currentVersion !== expectedVersion) {
    console.error(
      `[COOKIE] CLEARING MISMATCH - Current: "${currentVersion}", Expected: "${expectedVersion}"`
    );

    deleteAllCookies();
    createCookie(VERSION_COOKIE_NAME, expectedVersion, EXPIRE_IN_A_YEAR); // Set for 1 year
    window.location.reload();
  }
};

export const deleteCookieInAllDomains = (name: string) => {
  const oldCookieDomain = getOldCookieDomain();
  const currentCookieDomain = getCookieDomain();

  deleteCookieByDomain(name, oldCookieDomain);
  deleteCookieByDomain(name, currentCookieDomain);
};

export const EXPIRE_IN_A_YEAR = 8760;
