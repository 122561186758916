import { FunctionComponent } from "react";

export const XErrorIcon: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.58579 6.00106L1.05025 2.46552C0.849906 2.26518 0.749732 2.02947 0.749732 1.75842C0.749732 1.48736 0.849906 1.25166 1.05025 1.05131C1.2506 0.850963 1.4863 0.750789 1.75736 0.750789C2.02842 0.750789 2.26412 0.850963 2.46447 1.05131L6 4.58684L9.53553 1.05131C9.73588 0.850963 9.97158 0.750789 10.2426 0.750789C10.5137 0.750789 10.7494 0.850963 10.9497 1.05131C11.1501 1.25166 11.2503 1.48736 11.2503 1.75842C11.2503 2.02947 11.1501 2.26518 10.9497 2.46552L7.41421 6.00106L10.9497 9.53659C11.1501 9.73694 11.2503 9.97264 11.2503 10.2437C11.2503 10.5148 11.1501 10.7505 10.9497 10.9508C10.7494 11.1512 10.5137 11.2513 10.2426 11.2513C9.97158 11.2513 9.73588 11.1512 9.53553 10.9508L6 7.41527L2.46447 10.9508C2.26412 11.1512 2.02842 11.2513 1.75736 11.2513C1.4863 11.2513 1.2506 11.1512 1.05025 10.9508C0.849906 10.7505 0.749732 10.5148 0.749732 10.2437C0.749732 9.97264 0.849905 9.73694 1.05025 9.53659L4.58579 6.00106Z"
      fill="red"
    />
  </svg>
);
