import * as React from "react";
import { FunctionComponent } from "react";

export const ArrowBack: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    width={24}
    height={25}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <mask
      id="arrow-back-mask"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={4}
      y={4}
      width={16}
      height={17}
    >
      <path
        d="M18.791 11.505H7.621l4.88-4.88c.39-.39.39-1.03 0-1.42a.996.996 0 0 0-1.41 0l-6.59 6.59a.996.996 0 0 0 0 1.41l6.59 6.59a.996.996 0 1 0 1.41-1.41l-4.88-4.88h11.17c.55 0 1-.45 1-1s-.45-1-1-1Z"
        fill="#000"
      />
    </mask>
    <g mask="url(#arrow-back-mask)">
      <path d="M0 .5h24v24H0z" />
    </g>
  </svg>
);

export default ArrowBack;
