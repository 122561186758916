import { FunctionComponent } from "react";

export const LikeFilledIcon: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="20"
    height="18"
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M9.82265 18L8.81583 17.0914C6.14734 14.6521 4.01091 12.5239 2.40655 10.7067C0.802183 8.88949 0 7.04775 0 5.18145C0 3.70805 0.495225 2.47613 1.48568 1.48568C2.47613 0.495225 3.69986 0 5.15689 0C5.99181 0 6.81855 0.200546 7.63711 0.601637C8.45566 1.00273 9.18417 1.66166 9.82265 2.57844C10.543 1.66166 11.2879 1.00273 12.0573 0.601637C12.8267 0.200546 13.6371 0 14.4884 0C15.9454 0 17.1692 0.495225 18.1596 1.48568C19.1501 2.47613 19.6453 3.70805 19.6453 5.18145C19.6453 7.04775 18.8431 8.88949 17.2387 10.7067C15.6344 12.5239 13.498 14.6521 10.8295 17.0914L9.82265 18Z" />
  </svg>
);
