import { FunctionComponent } from "react";

export const ExitIcon: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1856_18420)">
      <path d="M12.1946 0.5H1.80837C1.32531 0.503854 0.863527 0.699294 0.524462 1.04339C0.185397 1.38749 -0.00322006 1.8521 4.16083e-05 2.33517V5.16667H1.16671V2.33517C1.16493 2.24914 1.18012 2.1636 1.2114 2.08344C1.24269 2.00327 1.28946 1.93006 1.34905 1.86798C1.40864 1.8059 1.47987 1.75617 1.55868 1.72163C1.6375 1.68709 1.72234 1.66841 1.80837 1.66667H12.1975C12.3703 1.67171 12.534 1.74479 12.6531 1.87C12.7722 1.99521 12.837 2.1624 12.8334 2.33517V12.6648C12.837 12.8381 12.7718 13.0057 12.6521 13.131C12.5323 13.2563 12.3679 13.329 12.1946 13.3333H1.80837C1.72234 13.3316 1.6375 13.3129 1.55868 13.2784C1.47987 13.2438 1.40864 13.1941 1.34905 13.132C1.28946 13.0699 1.24269 12.9967 1.2114 12.9166C1.18012 12.8364 1.16493 12.7509 1.16671 12.6648V9.83333H4.16083e-05V12.6648C-0.00322006 13.1479 0.185397 13.6125 0.524462 13.9566C0.863527 14.3007 1.32531 14.4961 1.80837 14.5H12.1975C12.6796 14.4946 13.1399 14.2985 13.4777 13.9546C13.8155 13.6106 14.0033 13.1469 14 12.6648V2.33517C14.0033 1.8526 13.8151 1.38842 13.4767 1.04441C13.1382 0.7004 12.6772 0.504622 12.1946 0.5V0.5Z" />
      <path d="M1.75 8.08322H9.96683L7.4585 10.5916L8.28333 11.4187L11.1702 8.53122C11.3057 8.3958 11.4132 8.23501 11.4865 8.05806C11.5598 7.8811 11.5975 7.69143 11.5975 7.49989C11.5975 7.30834 11.5598 7.11868 11.4865 6.94172C11.4132 6.76476 11.3057 6.60398 11.1702 6.46855L8.28333 3.58105L7.45675 4.40822L9.96508 6.91655H1.75V8.08322Z" />
    </g>
    <defs>
      <clipPath id="clip0_1856_18420">
        <rect
          width="14"
          height="14"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);
