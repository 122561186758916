import { FunctionComponent } from "react";

const TopicSleepHealth: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    width={48}
    height={50}
    viewBox="0 0 48 50"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path d="M30.06.599a1.192 1.192 0 0 0-1.4 1.681 22.255 22.255 0 0 1 2.516 10.314A22.555 22.555 0 0 1 2.107 34.188a1.186 1.186 0 0 0-1.4 1.682A24.923 24.923 0 1 0 30.078.599h-.018Zm-7.193 46.42A22.458 22.458 0 0 1 4.215 37.13a24.933 24.933 0 0 0 29.334-24.537 24.523 24.523 0 0 0-1.601-8.77 22.55 22.55 0 0 1-9.081 43.189v.005Z" />
  </svg>
);

export { TopicSleepHealth };
