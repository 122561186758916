import { FunctionComponent } from "react";

export const KumuVuesaxOutlineIcon: FunctionComponent<IconProps> = ({
  className,
}) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    className={className}
  >
    <g id="vuesax/outline/export">
      <g id="export">
        <path
          id="Vector"
          d="M12.9999 11.7502C12.8099 11.7502 12.6199 11.6802 12.4699 11.5302C12.1799 11.2402 12.1799 10.7602 12.4699 10.4702L20.6699 2.27023C20.9599 1.98023 21.4399 1.98023 21.7299 2.27023C22.0199 2.56023 22.0199 3.04023 21.7299 3.33023L13.5299 11.5302C13.3799 11.6802 13.1899 11.7502 12.9999 11.7502Z"
          fill="#202421"
        />
        <path
          id="Vector_2"
          d="M22 7.55C21.59 7.55 21.25 7.21 21.25 6.8V2.75H17.2C16.79 2.75 16.45 2.41 16.45 2C16.45 1.59 16.79 1.25 17.2 1.25H22C22.41 1.25 22.75 1.59 22.75 2V6.8C22.75 7.21 22.41 7.55 22 7.55Z"
          fill="#202421"
        />
        <path
          id="Vector_3"
          d="M15 23H9C3.57 23 1 20.43 1 15V9C1 3.57 3.57 1 9 1H11C11.5 1 12 1.5 12 2C12 2.5 11.5 3 11 3H9C4.39 3 3 4.39 3 9V15C3 19.61 4.39 21 9 21H15C19.61 21 21 19.61 21 15V13C21 12.5 21.5 12 22 12C22.5 12 23 12.5 23 13V15C23 20.43 20.43 23 15 23Z"
          fill="#202421"
        />
      </g>
    </g>
  </svg>
);
