const formatters = {
  es: new Intl.NumberFormat("es-AR"),
  en: new Intl.NumberFormat("en-EN"),
};
module.exports = {
  locales: ["en"],
  defaultLocale: "en",
  pages: {
    "*": ["common", "dashboard", "onboarding", "chat"],
  },
  interpolation: {
    format: (value, format, lang) => {
      if (format === "formatNumber") return formatters[lang].format(value);
      return value;
    },
  },
};
