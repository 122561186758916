import { FunctionComponent } from "react";

const PlusIcon: FunctionComponent<IconProps & { strokeWidth?: number }> = ({
  className,
  strokeWidth = 1,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 24 24"
    strokeLinecap="round"
    strokeLinejoin="round"
    className={className}
  >
    <path
      d="M10 1V19"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={strokeWidth}
    />
    <path
      d="M1 10H19"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={strokeWidth}
    />
  </svg>
);

export { PlusIcon };
