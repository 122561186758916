import { FunctionComponent } from "react";

export const HelpIcon: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M7.99965 16C3.58384 15.9952 0.00479979 12.4162 0 8.00035V7.84036C0.0879961 3.44455 3.70784 -0.056897 8.10364 0.000700511C12.5011 0.0598979 16.0265 3.65574 15.9977 8.05235C15.9689 12.4498 12.3971 16 7.99965 16ZM7.98685 14.4001H7.99965C9.6964 14.3984 11.323 13.7229 12.5219 12.5222C13.7208 11.3216 14.3938 9.69391 14.393 7.99715C14.3921 6.3004 13.7175 4.67343 12.5174 3.47394C11.3173 2.27445 9.69001 1.60063 7.99325 1.60063C6.29649 1.60063 4.66918 2.27445 3.4691 3.47394C2.26901 4.67343 1.59438 6.3004 1.59353 7.99715C1.59268 9.69391 2.26568 11.3216 3.46457 12.5222C4.66346 13.7229 6.29009 14.3984 7.98685 14.4001ZM8.79961 12.8001H7.19968V11.2002H8.79961V12.8001ZM8.79961 10.4002H7.19968C7.18727 9.88867 7.31204 9.38311 7.56103 8.93605C7.81002 8.48899 8.17416 8.11674 8.61562 7.85796C9.1436 7.45317 9.59958 7.10439 9.59958 6.40042C9.59958 5.97609 9.43101 5.56914 9.13097 5.2691C8.83092 4.96905 8.42398 4.80049 7.99965 4.80049C7.57532 4.80049 7.16837 4.96905 6.86833 5.2691C6.56828 5.56914 6.39972 5.97609 6.39972 6.40042H4.79979V6.32842C4.80934 5.47977 5.15562 4.66966 5.76246 4.07632C6.3693 3.48299 7.18699 3.15501 8.03565 3.16456C8.8843 3.17411 9.69441 3.52039 10.2877 4.12723C10.8811 4.73408 11.2091 5.55176 11.1995 6.40042C11.1713 6.82608 11.0437 7.2392 10.8269 7.60665C10.6102 7.9741 10.3104 8.28565 9.95156 8.51633C9.62707 8.72002 9.35419 8.99613 9.15433 9.32299C8.95446 9.64986 8.83305 10.0186 8.79961 10.4002Z" />
  </svg>
);
