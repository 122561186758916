import { FunctionComponent } from "react";

export const YoutubeIcon: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    width="17"
    height="11"
    viewBox="0 0 17 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.8551 2.9488C16.7507 2.01347 16.526 0.979493 15.699 0.436117C15.0585 0.0147418 14.2166 -0.000836097 13.4306 2.91508e-05C11.7692 0.000894398 10.1069 0.00262827 8.44544 0.00349352C6.84741 0.00522402 5.24938 0.00609349 3.65135 0.00782398C2.9838 0.00868923 2.33489 -0.0397604 1.71489 0.228466C1.18252 0.458622 0.765767 0.896425 0.514969 1.38356C0.167207 2.06105 0.094488 2.82853 0.0525328 3.57784C-0.0248512 4.94233 -0.0164602 6.3103 0.0758412 7.67392C0.143902 8.66896 0.316382 9.76867 1.14523 10.4029C1.87991 10.9644 2.91667 10.9921 3.86952 10.993C6.89402 10.9956 9.91946 10.9982 12.9449 10.9999C13.3328 11.0008 13.7374 10.9939 14.1327 10.9541C14.9103 10.8762 15.6515 10.6694 16.1512 10.1347C16.6556 9.59563 16.7852 8.84546 16.8616 8.13509C17.0481 6.41151 17.0462 4.67151 16.8551 2.9488ZM6.69637 7.91618V3.08378L11.2052 5.49955L6.69637 7.91618Z"
      fill="#202421"
    />
  </svg>
);
