import { FunctionComponent } from "react";

const HearthOutlineIcon: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      id="Vector"
      d="M11.6652 0.539062C10.9148 0.550734 10.1808 0.760209 9.53722 1.14633C8.89368 1.53245 8.36343 2.08155 8 2.73818C7.63657 2.08155 7.10632 1.53245 6.46278 1.14633C5.81924 0.760209 5.08521 0.550734 4.33481 0.539062C3.13859 0.591035 2.01155 1.11433 1.19993 1.99463C0.38831 2.87493 -0.0419227 4.04067 0.0032261 5.23717C0.0032261 9.75201 7.30428 14.9666 7.61482 15.1878L8 15.4604L8.38518 15.1878C8.69572 14.9679 15.9968 9.75201 15.9968 5.23717C16.0419 4.04067 15.6117 2.87493 14.8001 1.99463C13.9884 1.11433 12.8614 0.591035 11.6652 0.539062ZM8 13.8197C5.83221 12.2004 1.33602 8.22196 1.33602 5.23717C1.29047 4.39399 1.58017 3.56699 2.14194 2.93656C2.70371 2.30613 3.49197 1.9234 4.33481 1.87186C5.17765 1.9234 5.96591 2.30613 6.52769 2.93656C7.08946 3.56699 7.37916 4.39399 7.3336 5.23717H8.6664C8.62084 4.39399 8.91054 3.56699 9.47231 2.93656C10.0341 2.30613 10.8224 1.9234 11.6652 1.87186C12.508 1.9234 13.2963 2.30613 13.8581 2.93656C14.4198 3.56699 14.7095 4.39399 14.664 5.23717C14.664 8.2233 10.1678 12.2004 8 13.8197Z"
      fill="#33878E"
    />
  </svg>
);

export { HearthOutlineIcon };
