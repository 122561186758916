import { FunctionComponent } from "react";

export const KumuFeedbackIcon: FunctionComponent<IconProps> = ({
  className,
}) => (
  <svg
    width="22"
    height="24"
    viewBox="0 0 22 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M8.18654 19.2732V18.3858C6.43737 17.3225 5 15.2494 5 13.0463C5 9.25977 8.46032 6.29171 12.3693 7.14847C14.0881 7.53095 15.5939 8.67839 16.3772 10.2619C17.9667 13.4747 16.2936 16.8865 13.8371 18.3781V19.2655C13.8371 19.4873 13.9208 19.9999 13.107 19.9999H8.91663C8.08007 20.0075 8.18654 19.6786 8.18654 19.2732Z"
      stroke="#292D32"
    />
    <path d="M11 1V3" stroke="#292D32" />
    <path d="M18 3L17 4.73205" stroke="#292D32" />
    <path d="M20.9316 8L18.9998 8.51764" stroke="#292D32" />
    <path d="M1 8L2.93185 8.51764" stroke="#292D32" />
    <path d="M4 3L5 4.73205" stroke="#292D32" />
    <path d="M8 23C9.96286 21.6667 12.0371 21.6667 14 23" stroke="#292D32" />
  </svg>
);
