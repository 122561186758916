import useTranslation from "next-translate/useTranslation";
import { GetIcon } from "@/components/common/icon";
import { Label } from "@/components/common/label";
import { classNames } from "@/utils/classNames";
import { FunctionComponent } from "react";

type SuccessProps = {
  labelContent: string;
  headline?: string;
};

export const SuccessContent: FunctionComponent<SuccessProps> = ({
  labelContent,
  headline,
}) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center">
      <div className="flex flex-wrap justify-center items-center bg-[black] rounded-full w-[90px] h-[90px] relative my-5">
        <GetIcon
          iconName="checkmark"
          className="fill-current text-white w-9 h-9 stroke-current stroke-1 absolute "
        />
      </div>
      <Label
        className="mt-4"
        textColor="black"
        fontSize="[20px]"
        fontWeight="semibold"
      >
        {headline || t("common:common.done")}
      </Label>
      <Label textColor="black" fontWeight="medium" className={"my-4"}>
        {labelContent}
      </Label>
    </div>
  );
};
