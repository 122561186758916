import { FunctionComponent } from "react";

export const WalletIcon: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    className={className}
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12.5 11.0352C12.5 10.8362 12.579 10.6455 12.7197 10.5048C12.8603 10.3642 13.0511 10.2852 13.25 10.2852H15.25C15.4489 10.2852 15.6397 10.3642 15.7803 10.5048C15.921 10.6455 16 10.8362 16 11.0352C16 11.2341 15.921 11.4248 15.7803 11.5655C15.6397 11.7061 15.4489 11.7852 15.25 11.7852H13.25C13.0511 11.7852 12.8603 11.7061 12.7197 11.5655C12.579 11.4248 12.5 11.2341 12.5 11.0352ZM0 2.28516H0.0139999C0.075493 1.73515 0.33754 1.22709 0.750043 0.858128C1.16255 0.489163 1.69656 0.285173 2.25 0.285156H13.75C14.0455 0.285156 14.3381 0.343354 14.611 0.456427C14.884 0.5695 15.1321 0.735234 15.341 0.944166C15.5499 1.1531 15.7157 1.40114 15.8287 1.67412C15.9418 1.9471 16 2.23968 16 2.53516V3.37216C16.7118 3.54117 17.3458 3.94534 17.7995 4.51932C18.2532 5.09331 18.5 5.80353 18.5 6.53516V15.0352C18.5 15.8971 18.1576 16.7238 17.5481 17.3333C16.9386 17.9427 16.112 18.2852 15.25 18.2852H3.25C2.38805 18.2852 1.5614 17.9427 0.951903 17.3333C0.34241 16.7238 0 15.8971 0 15.0352V2.28516ZM15.25 4.78516H1.5V15.0352C1.5 16.0012 2.284 16.7852 3.25 16.7852H15.25C15.7141 16.7852 16.1592 16.6008 16.4874 16.2726C16.8156 15.9444 17 15.4993 17 15.0352V6.53516C17 6.07103 16.8156 5.62591 16.4874 5.29772C16.1592 4.96953 15.7141 4.78516 15.25 4.78516ZM14.5 3.28516V2.53516C14.5 2.33624 14.421 2.14548 14.2803 2.00483C14.1397 1.86417 13.9489 1.78516 13.75 1.78516H2.25C2.05109 1.78516 1.86032 1.86417 1.71967 2.00483C1.57902 2.14548 1.5 2.33624 1.5 2.53516C1.5 2.73407 1.57902 2.92483 1.71967 3.06549C1.86032 3.20614 2.05109 3.28516 2.25 3.28516H14.5Z" />
  </svg>
);
