import { FunctionComponent } from "react";

export const KumuBackArrow: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 18 18"
    className={className}
  >
    <path d="M6.01023 6.16789C5.38027 6.79786 5.82643 7.875 6.71734 7.875L17 7.875C17.5523 7.875 18 8.32271 18 8.875V9.125C18 9.67729 17.5523 10.125 17 10.125L6.71734 10.125C5.82643 10.125 5.38027 11.2021 6.01023 11.8321L9.88973 15.7116C10.2827 16.1046 10.2799 16.7426 9.88345 17.1321L9.70705 17.3054C9.31555 17.69 8.68721 17.6872 8.29912 17.2991L0.707106 9.70711C0.316582 9.31658 0.316582 8.68342 0.707108 8.29289L8.29912 0.700876C8.68721 0.312794 9.31555 0.310013 9.70705 0.694647L9.88345 0.867949C10.2799 1.25743 10.2827 1.89542 9.88973 2.28839L6.01023 6.16789Z" />
  </svg>
);
