import { FunctionComponent } from "react";

export const AboutIcon: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M8 16C6.41775 16 4.87104 15.5308 3.55544 14.6518C2.23985 13.7727 1.21447 12.5233 0.608967 11.0615C0.00346629 9.59966 -0.15496 7.99113 0.153721 6.43928C0.462403 4.88743 1.22433 3.46197 2.34315 2.34315C3.46197 1.22433 4.88743 0.462403 6.43928 0.153721C7.99113 -0.15496 9.59966 0.00346629 11.0615 0.608967C12.5233 1.21447 13.7727 2.23985 14.6518 3.55544C15.5308 4.87104 16 6.41775 16 8C15.9977 10.121 15.1541 12.1545 13.6543 13.6543C12.1545 15.1541 10.121 15.9977 8 16ZM8 1.33334C6.68146 1.33334 5.39253 1.72433 4.2962 2.45687C3.19987 3.18942 2.34539 4.23061 1.84081 5.44878C1.33622 6.66695 1.2042 8.0074 1.46144 9.30061C1.71867 10.5938 2.35361 11.7817 3.28596 12.714C4.21831 13.6464 5.4062 14.2813 6.6994 14.5386C7.99261 14.7958 9.33305 14.6638 10.5512 14.1592C11.7694 13.6546 12.8106 12.8001 13.5431 11.7038C14.2757 10.6075 14.6667 9.31855 14.6667 8C14.6647 6.23249 13.9617 4.53792 12.7119 3.2881C11.4621 2.03828 9.76752 1.33528 8 1.33334ZM4.66667 9.33334C4.7 10.6633 6 12.6667 8.00467 12.6667C10.0093 12.6667 11.3333 10.6667 11.3333 9.33334H4.66667ZM4 6.66667V7.33334H6.66667V6.66667C6.66667 6.31305 6.52619 5.97391 6.27615 5.72386C6.0261 5.47381 5.68696 5.33334 5.33334 5.33334C4.97972 5.33334 4.64058 5.47381 4.39053 5.72386C4.14048 5.97391 4 6.31305 4 6.66667ZM9.33334 6.66667V7.33334H12V6.66667C12 6.31305 11.8595 5.97391 11.6095 5.72386C11.3594 5.47381 11.0203 5.33334 10.6667 5.33334C10.313 5.33334 9.97391 5.47381 9.72386 5.72386C9.47381 5.97391 9.33334 6.31305 9.33334 6.66667Z" />
  </svg>
);
