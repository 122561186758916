import { classNames } from "@/utils/classNames";
import React, { FunctionComponent } from "react";

type FontSize =
  | "xs"
  | "sm"
  | "base"
  | "lg"
  | "xl"
  | "2xl"
  | "3xl"
  | "4xl"
  | "5xl"
  | string;

type Props<T> = {
  as?: T;
  className?: string;
  text?: React.ReactNode;
  textColor?: string | null;
  children?: React.ReactNode;
  textAlignment?: "center" | "left" | "right";
  fontSize?: FontSize;
  desktopFontSize?: FontSize;
  fontWeight?: "normal" | "medium" | "semibold" | "bold" | "extrabold" | string;
  onClick?: () => any;
};

// According to figma specs
type HeadlineComponent = FunctionComponent<Props<React.ElementType>>;
type BodyComponent = FunctionComponent<Props<React.ElementType>>;
type LabelComponent = FunctionComponent<Props<React.ElementType>> & {
  H2Headline: HeadlineComponent;
  H4Headline: HeadlineComponent;
  H6Headline: HeadlineComponent;
  SmallParagraph: HeadlineComponent;
  Body: BodyComponent;
};

const Label: LabelComponent = ({
  as,
  children,
  className,
  text,
  textColor = "sblack",
  fontSize = "base",
  desktopFontSize = fontSize,
  textAlignment = "center",
  fontWeight = "normal",
  onClick = null,
}) => {
  const Component = as || "p";
  return (
    <Component
      onClick={onClick}
      className={classNames(
        `text-${textColor} text-${textAlignment} text-${fontSize} desktop:text-${desktopFontSize} font-${fontWeight}`,
        textColor ? `text-${textColor}` : null,
        className
      )}
    >
      {children ? children : text}
    </Component>
  );
};

const H6Headline: HeadlineComponent = ({
  fontSize = "xl",
  textAlignment = "left",
  fontWeight = "semibold",
  ...restOfProps
}) => (
  <Label
    fontSize={fontSize}
    textAlignment={textAlignment}
    fontWeight={fontWeight}
    {...restOfProps}
  />
);

const H2Headline: HeadlineComponent = ({
  fontSize = "2xl",
  textAlignment = "left",
  fontWeight = "semibold",
  ...restOfProps
}) => (
  <Label
    fontSize={fontSize}
    textAlignment={textAlignment}
    fontWeight={fontWeight}
    {...restOfProps}
  />
);

const H4Headline: HeadlineComponent = ({
  fontSize = "base",
  textAlignment = "left",
  fontWeight = "semibold",
  ...restOfProps
}) => (
  <Label
    fontSize={fontSize}
    textAlignment={textAlignment}
    fontWeight={fontWeight}
    {...restOfProps}
  />
);

const Body: BodyComponent = ({
  fontSize = "base",
  textAlignment = "left",
  fontWeight = "normal",
  ...restOfProps
}) => (
  <Label
    fontSize={fontSize}
    textAlignment={textAlignment}
    fontWeight={fontWeight}
    {...restOfProps}
  />
);

const SmallParagraph: HeadlineComponent = ({
  fontSize = "xs",
  textAlignment = "left",
  fontWeight = "normal",
  ...restOfProps
}) => (
  <Label
    fontSize={fontSize}
    textAlignment={textAlignment}
    fontWeight={fontWeight}
    {...restOfProps}
  />
);

Label.Body = Body;
Label.H2Headline = H2Headline;
Label.H4Headline = H4Headline;
Label.H6Headline = H6Headline;
Label.SmallParagraph = SmallParagraph;

export { Label };
