import { FunctionComponent } from "react";

const AddIcon: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    width={19}
    height={18}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    stroke="#fff"
    className={className}
  >
    <path
      d="M1.5 9a8 8 0 1 0 16 0 8 8 0 0 0-16 0ZM9.5 5v8M5.5 9h8"
      stroke="current"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export { AddIcon };
