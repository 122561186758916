import { FunctionComponent } from "react";
import useTranslation from "next-translate/useTranslation";
//Components
import { Button } from "@/components/common/button";
import { GetIcon } from "@/components/common/icon";
import { Label } from "@/components/common/label";
import KumuButtonComponent from "@/components/layout/kumu/button-component";

type Props = {
  handleClick: () => void;
  price: number;
};

export const SubscribeContent: FunctionComponent<Props> = ({
  price,
  handleClick,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="flex flex-col items-start w-full px-5">
        <Label
          textColor="green-dark"
          fontWeight="medium"
          textAlignment="left"
          className="w-full m-auto"
        >
          {t("common:subscription.bysubscribing")}
        </Label>
        <Label
          textColor="green-dark"
          fontWeight="bold"
          textAlignment="left"
          className="w-full m-auto my-3"
        >
          {t("common:subscription.subscribefor")} ${price} a month AUD
        </Label>
        <KumuButtonComponent
          className="flex my-5 mx-0 m-auto self-end"
          theme={"black"}
          onClick={() => handleClick()}
          iconName="kumu-arrow-right"
          iconClassname="w-4 h-4"
        >
          Join
        </KumuButtonComponent>
      </div>
    </>
  );
};
