import useTranslation from "next-translate/useTranslation";
import { Button } from "@/components/common/button";
import { Label } from "@/components/common/label";
import { FunctionComponent } from "react";
import KumuButtonComponent from "@/components/layout/kumu/button-component";

type SuccessProps = {
  price: number;
  handleClick: () => void;
};

export const UnlockContent: FunctionComponent<SuccessProps> = ({
  price,
  handleClick,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="flex flex-col items-start w-full px-5">
        <Label textColor="green-dark" fontWeight="semibold" fontSize="lg">
          {t("common:content.aresure")} ${price}?
        </Label>
        <KumuButtonComponent
          theme={"black"}
          className="my-5 mt-[26px] self-end "
          onClick={() => handleClick()}
          iconName="kumu-arrow-right"
          iconClassname="w-4 h-4"
        >
          Checkout
        </KumuButtonComponent>
      </div>
    </>
  );
};
