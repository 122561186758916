import { FunctionComponent } from "react";

const VideoAddIcon: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className={className}
  >
    <g clipPath="url(#clip0_765_758)">
      <path
        d="M15 10.0001L19.553 7.72412C19.7054 7.64795 19.8748 7.612 20.045 7.61967C20.2152 7.62733 20.3806 7.67837 20.5256 7.76792C20.6706 7.85748 20.7902 7.9826 20.8733 8.13139C20.9563 8.28019 20.9999 8.44773 21 8.61812V15.3821C20.9999 15.5525 20.9563 15.7201 20.8733 15.8688C20.7902 16.0176 20.6706 16.1428 20.5256 16.2323C20.3806 16.3219 20.2152 16.3729 20.045 16.3806C19.8748 16.3882 19.7054 16.3523 19.553 16.2761L15 14.0001V10.0001Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 8C3 7.46957 3.21071 6.96086 3.58579 6.58579C3.96086 6.21071 4.46957 6 5 6H13C13.5304 6 14.0391 6.21071 14.4142 6.58579C14.7893 6.96086 15 7.46957 15 8V16C15 16.5304 14.7893 17.0391 14.4142 17.4142C14.0391 17.7893 13.5304 18 13 18H5C4.46957 18 3.96086 17.7893 3.58579 17.4142C3.21071 17.0391 3 16.5304 3 16V8Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_765_758">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export { VideoAddIcon };
