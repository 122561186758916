import { classNames } from "@/utils/classNames";
import { FunctionComponent } from "react";

const ExclamationIcon: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    className={classNames(className, "fill-current")}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 45"
  >
    <path
      d="M4.162 28.462c.9 0 1.67-.318 2.307-.956.637-.637.956-1.444.956-2.419V3.262c0-.9-.337-1.668-1.013-2.306C5.737.32 4.95 0 4.05 0c-.975 0-1.781.319-2.419.956C.994 1.594.675 2.4.675 3.375V25.2c0 .9.337 1.669 1.012 2.306.675.638 1.5.956 2.475.956Zm-.45 14.213c1.05 0 1.932-.356 2.644-1.069.713-.712 1.069-1.593 1.069-2.644 0-1.05-.356-1.93-1.069-2.643-.712-.713-1.593-1.069-2.643-1.069-1.05 0-1.932.356-2.644 1.069C.356 37.03 0 37.912 0 38.962c0 1.05.356 1.932 1.069 2.644.712.713 1.594 1.069 2.643 1.069Z"
      fill="#D9B24D"
    />
  </svg>
);

export { ExclamationIcon };
