import { FunctionComponent } from "react";

export const RecordingIcon: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Group 1000002174">
      <path
        id="Vector 1"
        d="M17 6.58105C11.4861 6.58105 7 11.0672 7 16.5811C7 22.0949 11.4861 26.5811 17 26.5811C22.5139 26.5811 27 22.0949 27 16.5811C27 11.0672 22.5139 6.58105 17 6.58105ZM17 8.08105C21.7031 8.08105 25.5 11.8779 25.5 16.5811C25.5 21.2842 21.7031 25.0811 17 25.0811C12.2969 25.0811 8.5 21.2842 8.5 16.5811C8.5 11.8779 12.2969 8.08105 17 8.08105Z"
        fill="#EF4444"
      />
      <g id="Vector 2" filter="url(#filter0_d_2857_499)">
        <path
          d="M12.5 16.5811C12.5 14.1047 14.5236 12.0811 17 12.0811C19.4764 12.0811 21.5 14.1047 21.5 16.5811C21.5 19.0575 19.4764 21.0811 17 21.0811C14.5236 21.0811 12.5 19.0575 12.5 16.5811Z"
          fill="#EF4444"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_d_2857_499"
        x="0.5"
        y="0.0810547"
        width="33"
        height="33"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="6" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.937255 0 0 0 0 0.266667 0 0 0 0 0.266667 0 0 0 0.5 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2857_499"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2857_499"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
