import { FunctionComponent } from "react";

export const BookmarkFilledLGIcon: FunctionComponent<IconProps> = ({
  className,
}) => (
  <svg
    width={14}
    height={18}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M2 0h10c1.1 0 2 .9 2 2v16l-7-3-7 3L.01 2C.01.9.9 0 2 0Z"
      fill="#33878E"
    />
  </svg>
);
