import { FunctionComponent } from "react";

const ProfileIcon: FunctionComponent<IconProps> = ({ className }) => (
  <svg className={className} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.7837 20.7729V18.7946C19.7837 17.7452 19.3668 16.7388 18.6248 15.9968C17.8828 15.2548 16.8764 14.8379 15.827 14.8379H7.9137C6.86432 14.8379 5.85793 15.2548 5.11591 15.9968C4.37389 16.7388 3.95703 17.7452 3.95703 18.7946V20.7729"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.8698 10.8811C14.055 10.8811 15.8264 9.10965 15.8264 6.92444C15.8264 4.73923 14.055 2.96777 11.8698 2.96777C9.68455 2.96777 7.91309 4.73923 7.91309 6.92444C7.91309 9.10965 9.68455 10.8811 11.8698 10.8811Z"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export { ProfileIcon };
