import { FunctionComponent } from "react";

export const PlayIcon: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="40"
    height="46"
    viewBox="0 0 40 46"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M37.5 18.6699C40.8333 20.5944 40.8333 25.4056 37.5 27.3301L7.5 44.6506C4.16667 46.5751 5.3927e-06 44.1695 5.56095e-06 40.3205L7.07516e-06 5.67949C7.2434e-06 1.83049 4.16668 -0.575137 7.50001 1.34936L37.5 18.6699Z" />
  </svg>
);
