import { FunctionComponent } from "react";

const CopyIcon: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="10"
    height="12"
    viewBox="0 0 10 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 2.543L5.457 0H1.5C1.10218 0 0.720644 0.158035 0.43934 0.43934C0.158035 0.720644 0 1.10218 0 1.5V10H8V2.543ZM1 9V1.5C1 1.36739 1.05268 1.24021 1.14645 1.14645C1.24021 1.05268 1.36739 1 1.5 1H5V3H7V9H1ZM10 4.5V12H2.5V11H9V3.5L10 4.5Z"
      fill="white"
    />
  </svg>
);

export { CopyIcon };
