import React, {
  createContext,
  FunctionComponent,
  ReactNode,
  useState,
  useEffect,
  useContext,
} from "react";
import { useRouter } from "next/router";

interface PreviousPathContextType {
  previousPath: string;
  setPreviousPath: React.Dispatch<React.SetStateAction<string>>;
}

const PreviousPathContext = createContext<PreviousPathContextType | undefined>(
  undefined
);

type Props = {
  children: ReactNode;
};

export const PreviousPathProvider: FunctionComponent<Props> = ({
  children,
}) => {
  const [previousPath, setPreviousPath] = useState("");
  const router = useRouter();

  useEffect(() => {
    const path = router.asPath;
    if (!path.includes("flow")) {
      setPreviousPath(path);
    }
  }, [router.pathname]);

  const value: PreviousPathContextType = { previousPath, setPreviousPath };

  return (
    <PreviousPathContext.Provider value={value}>
      {children}
    </PreviousPathContext.Provider>
  );
};

export default PreviousPathContext;

// UsePreviousPathContext hook
export const usePreviousPathContext = () => {
  const context = useContext(PreviousPathContext);
  if (!context) {
    throw new Error(
      "usePreviousPathContext must be used within a PreviousPathProvider"
    );
  }
  return context;
};
