import { FunctionComponent } from "react";

export const UpdateIcon: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    width={17}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M15.167 10.666v3.333a.667.667 0 0 1-.667.667h-12a.666.666 0 0 1-.667-.667v-3.333H.5v3.333a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-3.333h-1.333Z"
      fill="#fff"
    />
    <path
      d="M8.478 0a1.995 1.995 0 0 0-1.415.583L4.451 3.195l.942.943 2.423-2.422.017 10.95h1.334l-.018-10.94 2.413 2.412.943-.943L9.892.583A1.994 1.994 0 0 0 8.478 0Z"
      fill="#fff"
    />
  </svg>
);
