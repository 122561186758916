import { FunctionComponent } from "react";

export const LiveIcon: FunctionComponent<IconProps> = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" className={className}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45 45">
      <path
        d="M39.375 9.375C42.1123 13.0267 43.5919 17.4675 43.5919 22.0312C43.5919 26.595 42.1123 31.0358 39.375 34.6875"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.4678 13.5977C37.1002 16.109 37.969 19.0399 37.969 22.0352C37.969 25.0304 37.1002 27.9613 35.4678 30.4727"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.62703 9.375C2.88975 13.0267 1.41016 17.4675 1.41016 22.0312C1.41016 26.595 2.88975 31.0358 5.62703 34.6875"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.53248 13.5977C7.9001 16.109 7.03125 19.0399 7.03125 22.0352C7.03125 25.0304 7.9001 27.9613 9.53248 30.4727"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.4113 14.8705C20.1979 14.7424 19.9543 14.6732 19.7055 14.67C19.4566 14.6669 19.2114 14.7298 18.9948 14.8524C18.7782 14.975 18.598 15.1528 18.4727 15.3678C18.3473 15.5828 18.2813 15.8272 18.2813 16.0761V27.9842C18.2807 28.2333 18.3464 28.4781 18.4716 28.6934C18.5968 28.9088 18.7771 29.0869 18.9938 29.2096C19.2106 29.3323 19.4562 29.3952 19.7052 29.3917C19.9543 29.3882 20.198 29.3186 20.4113 29.1898L30.255 23.2367C30.4631 23.1118 30.6354 22.935 30.7549 22.7238C30.8745 22.5125 30.9374 22.2739 30.9374 22.0311C30.9374 21.7883 30.8745 21.5497 30.7549 21.3384C30.6354 21.1272 30.4631 20.9504 30.255 20.8255L20.4113 14.8705Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </svg>
);
