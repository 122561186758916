import { FunctionComponent } from "react";

export const MinusTenIcon: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="41"
    height="46"
    viewBox="0 0 41 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.2143 32.9606V22.8966H12.9023V21.6006H15.6383V32.9606H14.2143ZM19.4669 22.3846C19.7656 22.0432 20.1496 21.7712 20.6189 21.5686C21.0989 21.3659 21.6909 21.2646 22.3949 21.2646C23.1096 21.2646 23.7336 21.4139 24.2669 21.7126C24.8109 22.0006 25.2269 22.4219 25.5149 22.9766C26.0376 24.0006 26.2989 25.3766 26.2989 27.1046C26.2989 29.3232 25.9043 30.8966 25.1149 31.8246C24.4643 32.5712 23.5203 32.9446 22.2829 32.9446C20.0109 32.9446 18.7043 31.6166 18.3629 28.9606C18.2989 28.4059 18.2669 27.7872 18.2669 27.1046C18.2669 26.4112 18.2989 25.7926 18.3629 25.2486C18.4376 24.6939 18.5656 24.1606 18.7469 23.6486C18.9389 23.1366 19.1789 22.7152 19.4669 22.3846ZM24.4589 23.9846C24.2989 23.5152 24.0323 23.1632 23.6589 22.9286C23.2963 22.6832 22.8643 22.5606 22.3629 22.5606C21.8616 22.5606 21.4456 22.6459 21.1149 22.8166C20.7949 22.9872 20.5443 23.2059 20.3629 23.4726C20.1816 23.7392 20.0376 24.0859 19.9309 24.5126C19.8243 24.9286 19.7549 25.3339 19.7229 25.7286C19.7016 26.1126 19.6909 26.5712 19.6909 27.1046C19.6909 27.6379 19.7016 28.1019 19.7229 28.4966C19.7549 28.8806 19.8243 29.2859 19.9309 29.7126C20.0376 30.1286 20.1816 30.4699 20.3629 30.7366C20.5443 31.0032 20.7949 31.2219 21.1149 31.3926C21.4456 31.5632 21.8349 31.6486 22.2829 31.6486C22.7309 31.6486 23.1149 31.5632 23.4349 31.3926C23.7656 31.2219 24.0216 31.0032 24.2029 30.7366C24.3949 30.4699 24.5389 30.1232 24.6349 29.6966C24.8056 29.0139 24.8909 28.2406 24.8909 27.3766C24.8909 26.5126 24.8589 25.8459 24.7949 25.3766C24.7416 24.9072 24.6296 24.4432 24.4589 23.9846Z"
      fill="white"
    />
    <path
      d="M15.8022 8.8136C15.4969 9.27382 15.6225 9.89441 16.0828 10.1997L23.5826 15.1749C24.0428 15.4802 24.6634 15.3546 24.9687 14.8944C25.274 14.4342 25.1484 13.8136 24.6882 13.5083L18.0217 9.08588L22.4441 2.41936C22.7494 1.95913 22.6238 1.33855 22.1635 1.03325C21.7033 0.727942 21.0827 0.853533 20.7774 1.31376L15.8022 8.8136ZM27.2779 10.6681L27.7067 9.76466L27.2779 10.6681ZM36.7618 28.6678L37.7493 28.825L37.7493 28.825L36.7618 28.6678ZM31.818 38.1817L31.1217 37.4639L31.818 38.1817ZM11.6375 40.7666L12.1303 39.8965L11.6375 40.7666ZM4.45538 32.806L3.53932 33.207L4.45538 32.806ZM4.45195 22.3571C4.57793 21.8193 4.24415 21.2813 3.70642 21.1553C3.1687 21.0293 2.63066 21.3631 2.50468 21.9009L4.45195 22.3571ZM16.8339 10.3465C20.217 9.66187 23.7308 10.0917 26.8492 11.5715L27.7067 9.76466C24.1978 8.09948 20.2439 7.61587 16.4372 8.38626L16.8339 10.3465ZM26.8492 11.5715C29.9675 13.0514 32.5225 15.5017 34.1315 18.5554L35.9009 17.6231C34.0904 14.187 31.2155 11.4298 27.7067 9.76466L26.8492 11.5715ZM34.1315 18.5554C35.7404 21.6091 36.3168 25.1019 35.7742 28.5107L37.7493 28.825C38.3599 24.9894 37.7113 21.0592 35.9009 17.6231L34.1315 18.5554ZM35.7742 28.5107C35.2316 31.9194 33.5993 35.0606 31.1217 37.4639L32.5143 38.8995C35.3021 36.1953 37.1388 32.6607 37.7493 28.825L35.7742 28.5107ZM31.1217 37.4639C28.6442 39.8672 25.4547 41.4031 22.031 41.8416L22.2851 43.8254C26.1375 43.3319 29.7264 41.6037 32.5143 38.8995L31.1217 37.4639ZM22.031 41.8416C18.6073 42.2802 15.1337 41.5977 12.1303 39.8965L11.1446 41.6367C14.524 43.551 18.4327 44.3189 22.2851 43.8254L22.031 41.8416ZM12.1303 39.8965C9.12703 38.1953 6.75568 35.5669 5.37145 32.405L3.53932 33.207C5.09689 36.765 7.76521 39.7225 11.1446 41.6367L12.1303 39.8965ZM5.37145 32.405C3.98721 29.243 3.66461 25.7177 4.45195 22.3571L2.50468 21.9009C1.61874 25.6824 1.98174 29.6491 3.53932 33.207L5.37145 32.405Z"
      fill="white"
    />
  </svg>
);
