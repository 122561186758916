import { FunctionComponent } from "react";

const BookmarkIconLG: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    width={14}
    height={18}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M12 0H2C.9 0 .01.9.01 2L0 18l7-3 7 3V2c0-1.1-.9-2-2-2Zm0 15-5-2.18L2 15V2h10v13Z"
      fill="#33878E"
    />
  </svg>
);

export { BookmarkIconLG };
