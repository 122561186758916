import { FunctionComponent } from "react";

const YogaIcon: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="80"
    height="48"
    viewBox="0 0 80 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M39.5413 1.30396C38.7941 2.55852 38.1442 3.70882 37.0217 5.76269C36.1773 7.30569 35.8228 8.06329 35.2216 9.58545C33.3589 14.3048 32.9488 18.2701 34.0157 21.2449C34.179 21.7001 34.3563 22.0963 34.3945 22.0963C34.4084 22.0963 34.537 21.7557 34.676 21.3387C35.093 20.1119 35.8332 18.2979 36.3024 17.363C37.0391 15.8965 39.9375 10.7636 40.0313 10.767C40.0521 10.7705 40.2329 11.0659 40.8132 12.0529C41.5778 13.3561 43.3223 16.5081 43.7498 17.3596C44.3023 18.4647 45.1329 20.5325 45.5256 21.7835L45.6403 22.1484L45.7446 21.9225C46.1268 21.0815 46.3736 20.2232 46.5195 19.1945C46.6029 18.5898 46.6238 17.0329 46.5543 16.31C46.318 13.8391 45.6681 11.5073 44.3475 8.41777C43.8923 7.34392 43.4613 6.51334 42.0851 4.04244C41.2302 2.50986 40.1668 0.681887 40.0521 0.556778C40.0209 0.522026 39.9097 0.688837 39.5413 1.30396Z"
      fill="#F2F2F2"
    />
    <path
      d="M23.7715 6.26305C23.7715 6.29085 23.7472 6.52022 23.7194 6.77739C23.5352 8.44203 23.5039 10.9616 23.6533 12.3656C23.7472 13.2657 24.0217 14.6697 24.1294 14.8087C24.1538 14.8365 24.4457 15.0137 24.7793 15.2014C28.119 17.0641 29.4813 18.3152 32.0912 21.9121C32.3171 22.2248 32.5048 22.4785 32.5117 22.4785C32.5187 22.4785 32.5013 22.4055 32.4735 22.3117C32.4457 22.2213 32.3449 21.8495 32.2511 21.4881C31.9626 20.3829 31.8062 19.4933 31.6707 18.2561C31.5873 17.4533 31.5838 15.4273 31.6707 14.7461C31.8236 13.5298 32.0217 12.7513 32.397 11.886C32.477 11.7053 32.5256 11.5489 32.5082 11.535C32.4909 11.5211 32.3553 11.396 32.2059 11.2604C30.0304 9.26913 26.9131 7.35775 24.2198 6.36731C23.9904 6.2839 23.7958 6.2144 23.7854 6.2144C23.7784 6.2144 23.7715 6.23525 23.7715 6.26305Z"
      fill="#F2F2F2"
    />
    <path
      d="M55.5867 6.40223C54.8256 6.69067 54.2973 6.92351 53.3799 7.38572C51.2808 8.43524 49.3312 9.76626 47.6387 11.2988L47.3398 11.5699L47.4754 11.8618C47.7777 12.5047 48.0279 13.4153 48.1635 14.364C48.4763 16.5082 48.233 19.4448 47.5553 21.7975C47.4511 22.159 47.3711 22.4578 47.3746 22.4648C47.3815 22.4683 47.4962 22.3223 47.6283 22.1416C49.0601 20.1711 49.8907 19.1564 50.8151 18.2528C51.9376 17.1546 53.1957 16.265 55.2391 15.1251L55.7743 14.8297L55.8299 14.649C55.9133 14.364 56.1184 13.3284 56.174 12.8731C56.3026 11.8827 56.3408 11.0104 56.3234 9.56817C56.306 8.27886 56.2748 7.77147 56.1566 6.64897C56.1323 6.43698 56.1045 6.25627 56.0941 6.24237C56.0836 6.22847 55.8543 6.30145 55.5867 6.40223Z"
      fill="#F2F2F2"
    />
    <path
      d="M12.8584 12.5466C12.8619 14.5692 13.2859 16.1852 14.0539 17.0783C14.1373 17.1756 14.4396 17.3876 14.9505 17.7039C15.9931 18.3468 17.6438 19.4415 18.1581 19.8272C18.3875 20.001 18.735 20.279 18.9331 20.4423C19.1277 20.6092 19.2876 20.7238 19.2876 20.696C19.2876 20.6161 19.1833 19.796 19.1277 19.438C19.0513 18.9271 18.947 18.4476 18.8358 18.0792C18.7837 17.8985 18.7455 17.7525 18.7524 17.7525C18.8011 17.7525 19.6351 17.9923 20.0348 18.1244C22.4535 18.9132 25.1503 20.206 27.8714 21.8811C28.417 22.2182 30.0991 23.3268 30.5925 23.6778C30.7385 23.7855 30.8601 23.862 30.8601 23.855C30.8601 23.8446 30.7385 23.6847 30.5925 23.4971C29.5361 22.1452 27.7463 20.3555 26.0296 18.9341C22.7524 16.2234 18.9574 14.1 15.0652 12.8003C14.4327 12.5918 13.2441 12.2373 12.9731 12.1748L12.8584 12.1504V12.5466Z"
      fill="#F2F2F2"
    />
    <path
      d="M66.7772 12.2475C64.4939 12.8556 62.5547 13.582 60.4522 14.6245C57.5678 16.0528 54.9996 17.8044 52.4766 20.0598C51.8441 20.6228 50.3879 22.0824 49.9049 22.635C49.4948 23.1006 48.8623 23.8826 48.8623 23.9173C48.8623 23.9312 48.9909 23.8548 49.1507 23.7436C49.8145 23.2813 50.7216 22.6732 51.1907 22.3708C53.8805 20.6506 56.7893 19.1979 59.2185 18.3604C59.8128 18.1588 60.9596 17.8148 60.9805 17.8356C60.9874 17.8426 60.9631 17.933 60.9249 18.0337C60.8241 18.3048 60.692 18.8539 60.6121 19.3335C60.5252 19.8478 60.4244 20.6888 60.4418 20.7548C60.4487 20.7896 60.5356 20.734 60.7129 20.5811C61.5226 19.8756 63.354 18.6245 65.2863 17.4638C65.4044 17.3943 65.5608 17.2692 65.6373 17.1858C66.2315 16.5394 66.6764 15.2396 66.7945 13.8078C66.8397 13.2414 66.8293 12.2336 66.7772 12.2475Z"
      fill="#F2F2F2"
    />
    <path
      d="M39.8507 15.5666C39.0896 16.7725 36.5805 21.259 36.0905 22.2877C34.5683 25.478 33.6161 28.6613 33.3798 31.3302C33.1157 34.3224 33.7968 36.908 35.3364 38.7429C36.4033 40.0183 37.9706 41.0505 39.6005 41.5544L39.9515 41.6621L40.2956 41.544C42.0888 40.9393 43.6666 39.8376 44.7057 38.4649C45.7274 37.1165 46.3043 35.511 46.4676 33.5683C46.5163 32.9636 46.4919 31.6395 46.4155 30.941C46.1931 28.9497 45.644 26.8993 44.6744 24.4423C44.0002 22.7256 43.7083 22.1383 42.0575 19.16C41.3069 17.8047 40.1322 15.7855 39.955 15.5492C39.9063 15.4832 39.9029 15.4832 39.8507 15.5666Z"
      fill="#F2F2F2"
    />
    <path
      d="M3.24936 16.5575C4.23285 17.2352 5.10514 18.3472 5.88707 19.9076C6.48133 21.0927 6.95744 22.4133 7.28064 23.7721C7.47873 24.6027 7.96874 25.8712 8.51435 26.9624C9.96352 29.8607 12.0869 32.2065 14.8671 33.9789C16.8688 35.2578 19.3154 36.2517 21.6681 36.7383C22.1512 36.839 23.1486 37.0059 23.2702 37.0059C23.2876 37.0059 23.0652 36.7661 22.7802 36.4776C22.0296 35.7165 21.5604 35.0632 21.0634 34.0866C20.1494 32.2969 19.6316 30.1631 19.4579 27.4906C19.4057 26.6461 19.4335 24.561 19.51 23.8069C19.5795 23.1396 19.6143 23.2439 19.1382 22.7156C16.8758 20.2135 13.0878 18.3055 8.21895 17.2178C6.51609 16.839 4.74024 16.5644 3.45788 16.481L3.11035 16.4602L3.24936 16.5575Z"
      fill="#F2F2F2"
    />
    <path
      d="M75.4302 16.5362C73.8454 16.7065 71.6769 17.0992 70.2347 17.478C66.3042 18.5136 63.319 19.9906 61.1956 21.9437C60.8585 22.2529 60.3407 22.7986 60.1113 23.0766L59.9897 23.226L60.0383 23.6604C60.1322 24.557 60.1739 25.9819 60.1287 26.9654C59.9549 30.8298 59.0548 33.7351 57.3902 35.8237C57.2581 35.9871 56.9523 36.3207 56.7056 36.564L56.2607 37.0088L56.4449 36.9845C57.5014 36.8559 58.8602 36.5431 60.0175 36.1643C63.597 34.9897 66.5092 33.0887 68.7577 30.4545C70.3772 28.5605 71.6943 26.034 72.3198 23.6222C73.1817 20.3068 74.5092 17.9159 76.2051 16.63C76.3024 16.557 76.3858 16.4875 76.3858 16.4806C76.3858 16.4528 76.0036 16.4771 75.4302 16.5362Z"
      fill="#F2F2F2"
    />
    <path
      d="M21.0498 21.4679C21.3765 22.1178 21.4321 22.792 21.3209 24.8076C21.2931 25.315 21.2583 26.149 21.2479 26.6668C21.1715 29.7667 21.7171 32.3002 22.9855 34.7641C24.678 38.0482 27.0724 39.8761 30.3426 40.38C30.7666 40.4461 30.989 40.46 31.7814 40.46C32.7822 40.46 33.1367 40.4287 33.9708 40.2549C34.2001 40.2063 34.4017 40.168 34.4191 40.168C34.4364 40.168 34.3287 40.0534 34.1793 39.9144C32.9873 38.8231 32.2818 37.5721 31.8161 35.7198C31.3365 33.8119 31.2497 31.2923 31.5833 28.8701C31.6597 28.3315 31.6354 28.189 31.3852 27.6746C30.8257 26.5313 29.6823 25.4227 27.903 24.3141C26.2488 23.2785 23.7848 22.1421 21.5433 21.3845L20.9004 21.1656L21.0498 21.4679Z"
      fill="#F2F2F2"
    />
    <path
      d="M57.8804 21.4361C56.2922 21.9956 55.3504 22.3883 53.9498 23.0694C51.4755 24.2719 49.6614 25.5716 48.7231 26.8157C48.4138 27.2293 48.1045 27.8027 47.9933 28.1676C47.9203 28.4109 47.9203 28.4283 47.9724 28.7584C48.3686 31.2988 48.2365 34.1346 47.6249 36.2232C47.1835 37.7211 46.4989 38.8783 45.498 39.8236L45.1436 40.1572L45.498 40.2337C48.1114 40.7932 50.7144 40.3901 52.7961 39.1007C54.4816 38.0582 55.8856 36.3553 56.9142 34.1033C57.6753 32.4422 58.1132 30.7358 58.287 28.7688C58.346 28.0703 58.3391 26.291 58.2661 24.9982C58.1445 22.8192 58.1966 22.1033 58.5233 21.4569C58.5997 21.304 58.6588 21.1754 58.6519 21.1719C58.6484 21.1685 58.3009 21.2866 57.8804 21.4361Z"
      fill="#F2F2F2"
    />
    <path
      d="M4.36183 26.044C2.77364 26.117 1.50171 26.3672 0.712828 26.7599C0.44871 26.8955 0.0351562 27.1665 0.0351562 27.2083C0.0351562 27.2256 0.181116 27.4063 0.358353 27.6149C1.55731 28.9911 3.32968 30.1031 5.21674 30.6592L5.61986 30.7773L6.17243 30.6071C6.90223 30.3812 7.46522 30.2665 7.9865 30.2387L8.41048 30.2178L7.9865 29.8147C6.85357 28.7374 6.00214 27.4619 5.68937 26.3672C5.64419 26.2178 5.60249 26.0892 5.59206 26.0788C5.57121 26.0579 4.63985 26.0336 4.36183 26.044Z"
      fill="#F2F2F2"
    />
    <path
      d="M74.6902 26.0548L74.4017 26.0757L74.2627 26.4927C73.8909 27.6048 73.2236 28.6091 72.1428 29.676L71.5938 30.2182L72.0038 30.239C72.5321 30.2668 73.1472 30.3919 73.8283 30.6108L74.3705 30.7881L74.7423 30.6769C76.3027 30.2147 77.7171 29.4293 78.8987 28.3693C79.34 27.9732 80.0142 27.2295 79.9621 27.1947C79.9551 27.1878 79.8856 27.1356 79.8092 27.0766C79.2775 26.67 78.3044 26.3363 77.2028 26.1834C76.393 26.0722 75.2601 26.0131 74.6902 26.0548Z"
      fill="#F2F2F2"
    />
    <path
      d="M8.34097 31.1706C7.24975 31.2644 6.18633 31.5702 5.12639 32.0881C4.49042 32.4008 3.87878 32.7831 3.40615 33.1654C3.03778 33.4642 2.6416 33.8187 2.6416 33.85C2.6416 33.8674 2.79104 33.8778 2.97175 33.8778C3.86488 33.8778 5.53647 34.2601 7.63898 34.9481L8.38268 35.1914L8.6607 35.0037C9.23758 34.618 10.1133 34.2253 11.1802 33.8743L11.7432 33.6901L11.5799 33.5859C10.4921 32.9047 9.91873 32.3313 9.44957 31.459L9.27234 31.1324L8.97347 31.1358C8.80665 31.1393 8.52169 31.1532 8.34097 31.1706Z"
      fill="#F2F2F2"
    />
    <path
      d="M70.5691 31.4307C70.1243 32.2648 69.3806 33.0258 68.5187 33.5228L68.2441 33.6792L68.9705 33.9294C70.1034 34.3186 70.7637 34.6175 71.3371 35.0032L71.6221 35.1943L72.0322 35.0553C74.2216 34.3186 76.0843 33.8772 77.0227 33.8772C77.2312 33.8772 77.3598 33.8633 77.3528 33.8425C77.3354 33.7869 76.7724 33.2969 76.4353 33.0397C75.5144 32.3482 74.4162 31.7956 73.325 31.4828C72.6195 31.2813 72.0113 31.184 71.2085 31.1458L70.7324 31.1214L70.5691 31.4307Z"
      fill="#F2F2F2"
    />
    <path
      d="M12.7028 34.4231C10.9582 34.8124 9.72453 35.3406 8.2545 36.3206C7.49689 36.828 6.38134 37.7073 6.40566 37.7802C6.42652 37.8463 7.66371 38.3398 8.58465 38.649C11.6568 39.6812 14.6802 39.9662 17.5126 39.4901C18.8158 39.2676 20.2511 38.795 21.2172 38.2702C21.808 37.947 21.808 37.9644 21.2276 37.9158C18.7637 37.7038 16.324 36.8454 14.3188 35.4831C13.9296 35.219 13.3631 34.788 13.033 34.5066C12.9148 34.4058 12.8523 34.3884 12.7028 34.4231Z"
      fill="#F2F2F2"
    />
    <path
      d="M66.8461 34.5972C66.0364 35.3617 64.3162 36.3869 62.9365 36.9256C61.529 37.4782 60.0069 37.8361 58.655 37.9299C58.4674 37.9438 58.3145 37.9647 58.3145 37.9821C58.3145 38.0481 58.9226 38.3678 59.5864 38.6493C60.7471 39.1428 61.96 39.4451 63.5099 39.6363C64.1216 39.7127 66.0955 39.7232 66.7592 39.6537C68.7506 39.4486 70.509 39.0246 72.4586 38.2775C72.7922 38.1489 73.1884 37.9855 73.3378 37.916L73.6159 37.7909L73.3656 37.5546C73.0703 37.2696 72.2883 36.6823 71.6941 36.2931C70.4708 35.4903 69.3448 34.9621 68.1493 34.625C67.847 34.5416 67.1832 34.3991 67.0998 34.3991C67.0755 34.4026 66.9608 34.4894 66.8461 34.5972Z"
      fill="#F2F2F2"
    />
    <path
      d="M24.0842 38.9447C24.0181 38.9481 23.8583 38.9759 23.7297 39.0037C22.5099 39.2713 21.1198 40.2861 19.5803 42.0306C19.1146 42.5623 18.9443 42.7118 18.5933 42.9064C18.2736 43.0801 17.6341 43.3165 17.0642 43.4694C16.7688 43.5493 16.5012 43.6223 16.4734 43.6327C16.4074 43.657 16.7757 43.7717 17.4812 43.9524C21.6272 45.0123 26.7601 44.6787 31.5907 43.0315C32.2163 42.8195 33.3909 42.3712 33.4326 42.3295C33.443 42.3226 33.2241 42.3226 32.9461 42.3365C32.3031 42.3643 31.1076 42.3086 30.3883 42.2183C28.4595 41.975 26.9061 41.3981 25.6515 40.4633C25.092 40.0498 24.7167 39.6536 24.2406 38.9829C24.2197 38.9516 24.1606 38.9377 24.0842 38.9447Z"
      fill="#F2F2F2"
    />
    <path
      d="M55.5656 39.254C54.3909 40.8422 52.1876 41.923 49.4874 42.2393C49.1607 42.2775 48.5595 42.3053 47.7671 42.3192L46.5508 42.3401L47.2285 42.6042C52.3162 44.599 58.0399 45.1029 62.5195 43.9526C63.0755 43.8101 63.5273 43.6711 63.5273 43.6433C63.5273 43.6329 63.3744 43.5877 63.1902 43.5425C62.3666 43.3479 61.4318 42.9691 61.046 42.6772C60.9487 42.6077 60.7089 42.3609 60.5073 42.1315C59.9791 41.5268 59.0512 40.6094 58.6099 40.2583C57.6959 39.532 56.8966 39.1185 56.1146 38.969C55.9617 38.9412 55.8366 38.9169 55.8297 38.9169C55.8227 38.9169 55.7046 39.0698 55.5656 39.254Z"
      fill="#F2F2F2"
    />
    <path
      d="M35.0235 42.0723C34.384 42.743 33.8871 43.7682 33.6751 44.8629L33.6299 45.0923L33.7446 45.2938C33.8071 45.4016 33.9461 45.6031 34.0573 45.7387C34.8497 46.7326 36.0938 47.4172 37.7515 47.7751C40.1633 48.2964 42.8879 47.8933 44.629 46.7534C45.199 46.3781 45.8558 45.697 46.0747 45.2521L46.1651 45.0679L46.1025 44.7517C45.9149 43.7682 45.4005 42.7256 44.7646 42.0445C44.5526 41.8151 44.5213 41.7908 44.5004 41.8534C43.9965 43.2782 42.4396 44.1574 40.1911 44.286C39.3154 44.3381 37.9809 44.0671 37.1329 43.6639C36.2954 43.2678 35.6247 42.6353 35.364 41.9924L35.2876 41.7978L35.0235 42.0723Z"
      fill="#F2F2F2"
    />
  </svg>
);

export { YogaIcon };
export default YogaIcon;
