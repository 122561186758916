import { GetIcon } from "@/components/common/icon";
import { classNames } from "@/utils/classNames";
import React, { FC, ButtonHTMLAttributes } from "react";

type Props = {
  theme:
    | "green"
    | "black"
    | "grey"
    | "white"
    | "hollow"
    | "hollow-border"
    | "hollow-underlined";
  className?: string;
  iconName?: IconName;
  iconClassname?: string;
  loading?: boolean;
};

const KumuButtonComponent: FC<
  Props & ButtonHTMLAttributes<HTMLButtonElement>
> = ({
  children,
  className,
  theme,
  iconName,
  iconClassname,
  loading,
  disabled,
  ...restOfProps
}) => {
  const selectedTheme: {
    [theme: string]: { buttonClassNames: string; iconClassNames: string };
  } = {
    green: {
      buttonClassNames: "bg-green-leaf text-graphite",
      iconClassNames: "fill-current",
    },
    black: {
      buttonClassNames: "bg-graphite text-white",
      iconClassNames: "fill-current",
    },
    grey: {
      buttonClassNames: "bg-light-grey text-graphite",
      iconClassNames: "fill-current",
    },
    white: {
      buttonClassNames: "bg-white text-graphite",
      iconClassNames: "fill-current",
    },
    hollow: {
      buttonClassNames: "text-graphite",
      iconClassNames: "fill-current",
    },
    "hollow-border": {
      buttonClassNames: "box-border border-2 border-graphite text-graphite",
      iconClassNames: "fill-green-leaf",
    },
    "hollow-underlined": {
      buttonClassNames: "text-graphite underline underline-offset-2",
      iconClassNames: "fill-current",
    },
  };

  return (
    <button
      disabled={disabled || loading}
      className={classNames(
        "flex rounded-lg gap-4 py-4 font-bold relative",
        "hover:opacity-80 transition-all duration-300",
        className,
        disabled || loading ? "opacity-50" : null,
        loading ? "opacity-50 px-7" : "px-4 desktop:px-6",
        selectedTheme[theme].buttonClassNames,
        !className?.includes("justify") ? "justify-center" : null,
        !className?.includes("items") ? "items-center" : null
      )}
      {...restOfProps}
    >
      {loading ? (
        <GetIcon
          className="animate-spin fill-current text-light-4 m-auto  w-6 h-6"
          iconName="loading"
        />
      ) : (
        <>
          {children}
          {iconName ? (
            <GetIcon
              iconName={iconName}
              className={classNames(
                selectedTheme[theme].iconClassNames,
                iconClassname
              )}
            />
          ) : null}
        </>
      )}
    </button>
  );
};

export default KumuButtonComponent;
