import { FunctionComponent } from "react";

const SoundMuteIcon: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="streamlinehq-volume-control-mute-music-audio-24 1"
      clipPath="url(#clip0_2725_3185)"
    >
      <path
        id="Vector"
        d="M13.422 4.65827C13.1728 4.53371 12.8938 4.48101 12.6163 4.50609C12.3388 4.53117 12.0738 4.63304 11.851 4.80027L5.25098 9.00027H2.25098C1.85315 9.00027 1.47162 9.15831 1.19032 9.43961C0.909012 9.72091 0.750977 10.1024 0.750977 10.5003V13.5003C0.750977 13.8981 0.909012 14.2796 1.19032 14.5609C1.47162 14.8422 1.85315 15.0003 2.25098 15.0003H5.25098L11.851 19.2003C12.0738 19.3674 12.3388 19.4692 12.6163 19.4942C12.8937 19.5192 13.1726 19.4665 13.4218 19.3419C13.671 19.2173 13.8805 19.0258 14.027 18.7889C14.1734 18.5519 14.251 18.2788 14.251 18.0003V6.00027C14.2511 5.72166 14.1735 5.44853 14.0271 5.2115C13.8807 4.97447 13.6712 4.7829 13.422 4.65827V4.65827Z"
        stroke="white"
        strokeWidth="1.68"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_2"
        d="M5.25293 9V15"
        stroke="white"
        strokeWidth="1.68"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_3"
        d="M23.25 9.375L18 14.625"
        stroke="white"
        strokeWidth="1.68"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_4"
        d="M18 9.375L23.25 14.625"
        stroke="white"
        strokeWidth="1.68"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2725_3185">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export { SoundMuteIcon };
