import { FunctionComponent } from "react";

const WithdrawIcon: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    width={23}
    height={23}
    fill="none"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.917 23H0v-7.807a8.873 8.873 0 0 1 2.526-6.206A8.553 8.553 0 0 1 8.618 6.41l4.64-.15V0l8.9 9.069c.54.55.842 1.294.842 2.07 0 .776-.303 1.52-.842 2.07l-8.897 9.069v-6.26l-4.643.15a6.653 6.653 0 0 0-4.737 2.006A6.901 6.901 0 0 0 1.917 23Zm6.708-8.783 6.556-.15v3.5l5.629-5.738a.985.985 0 0 0 0-1.38l-5.634-5.737v3.5l-6.558.15a6.653 6.653 0 0 0-4.742 2.002 6.901 6.901 0 0 0-1.966 4.829v2.292a8.633 8.633 0 0 1 2.99-2.41 8.477 8.477 0 0 1 3.718-.858h.007Z"
      fill="#CCE9E2"
    />
  </svg>
);

export { WithdrawIcon };
