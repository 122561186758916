import { FunctionComponent } from "react";

const VideoIcon: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="13"
    height="12"
    viewBox="0 0 13 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M6.5 0C8.0913 0 9.61742 0.632141 10.7426 1.75736C11.8679 2.88258 12.5 4.4087 12.5 6C12.5 7.5913 11.8679 9.11742 10.7426 10.2426C9.61742 11.3679 8.0913 12 6.5 12C4.9087 12 3.38258 11.3679 2.25736 10.2426C1.13214 9.11742 0.5 7.5913 0.5 6C0.5 4.4087 1.13214 2.88258 2.25736 1.75736C3.38258 0.632141 4.9087 0 6.5 0ZM6.5 11.1429C7.86397 11.1429 9.17208 10.601 10.1365 9.63655C11.101 8.67208 11.6429 7.36397 11.6429 6C11.6429 4.63603 11.101 3.32792 10.1365 2.36345C9.17208 1.39898 7.86397 0.857143 6.5 0.857143C5.13603 0.857143 3.82792 1.39898 2.86345 2.36345C1.89898 3.32792 1.35714 4.63603 1.35714 6C1.35714 7.36397 1.89898 8.67208 2.86345 9.63655C3.82792 10.601 5.13603 11.1429 6.5 11.1429ZM5.85714 7.82657L8.59743 6L5.85714 4.17343V7.82657ZM5.99943 3.23743L9.34057 5.46514C9.42862 5.52385 9.5008 5.60339 9.55073 5.69669C9.60066 5.78999 9.62679 5.89418 9.62679 6C9.62679 6.10582 9.60066 6.21001 9.55073 6.30331C9.5008 6.39662 9.42862 6.47615 9.34057 6.53486L5.99943 8.76257C5.90262 8.82711 5.7901 8.86416 5.67389 8.86978C5.55767 8.87539 5.44211 8.84936 5.33953 8.79446C5.23694 8.73956 5.15118 8.65785 5.09139 8.55804C5.03159 8.45823 5.00001 8.34406 5 8.22771V3.77143C5.00001 3.65508 5.03159 3.54091 5.09139 3.4411C5.15118 3.34129 5.23694 3.25958 5.33953 3.20468C5.44211 3.14978 5.55767 3.12375 5.67389 3.12937C5.7901 3.13498 5.90262 3.17204 5.99943 3.23657V3.23743Z" />
  </svg>
);

export { VideoIcon };
