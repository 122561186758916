import { FunctionComponent } from "react";

export const DurationIcon: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="12"
    height="12"
    viewBox="0 0 12 12"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 1.09091C3.28878 1.09091 1.09091 3.28878 1.09091 6C1.09091 8.71122 3.28878 10.9091 6 10.9091C8.71122 10.9091 10.9091 8.71122 10.9091 6C10.9091 3.28878 8.71122 1.09091 6 1.09091ZM0 6C0 2.68629 2.68629 0 6 0C9.31371 0 12 2.68629 12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.99856 2.18164C6.29979 2.18164 6.54399 2.42584 6.54399 2.72708V5.66264L8.42422 6.60276C8.69366 6.73748 8.80287 7.06512 8.66815 7.33455C8.53343 7.60399 8.20581 7.7132 7.93637 7.57848L5.75463 6.4876C5.56985 6.3952 5.45312 6.20634 5.45312 5.99974V2.72708C5.45312 2.42584 5.69732 2.18164 5.99856 2.18164Z"
    />
  </svg>
);
