import { FunctionComponent } from "react";

const ViewsIcon: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="14"
    height="14"
    viewBox="0 0 14 14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_973_5931)">
      <path d="M13.8955 6.52225C13.3833 5.40225 11.3749 1.75 6.99992 1.75C2.62492 1.75 0.616508 5.40225 0.104342 6.52225C0.0355889 6.67234 0 6.83549 0 7.00058C0 7.16567 0.0355889 7.32882 0.104342 7.47892C0.616508 8.59775 2.62492 12.25 6.99992 12.25C11.3749 12.25 13.3833 8.59775 13.8955 7.47775C13.9641 7.32783 13.9996 7.16488 13.9996 7C13.9996 6.83512 13.9641 6.67217 13.8955 6.52225ZM6.99992 11.0833C3.32084 11.0833 1.60409 7.95317 1.16659 7.00642C1.60409 6.04683 3.32084 2.91667 6.99992 2.91667C10.6703 2.91667 12.3876 6.03342 12.8333 7C12.3876 7.96658 10.6703 11.0833 6.99992 11.0833Z" />
      <path d="M6.99968 4.08398C6.42281 4.08398 5.85891 4.25504 5.37926 4.57553C4.89962 4.89602 4.52578 5.35154 4.30503 5.88449C4.08427 6.41744 4.02651 7.00389 4.13905 7.56966C4.25159 8.13544 4.52938 8.65514 4.93728 9.06305C5.34518 9.47095 5.86488 9.74874 6.43066 9.86128C6.99644 9.97382 7.58288 9.91606 8.11583 9.6953C8.64879 9.47455 9.10431 9.10071 9.42479 8.62107C9.74528 8.14142 9.91634 7.57751 9.91634 7.00065C9.91541 6.22739 9.60783 5.48606 9.06105 4.93928C8.51427 4.3925 7.77294 4.08491 6.99968 4.08398ZM6.99968 8.75065C6.65356 8.75065 6.31521 8.64802 6.02743 8.45572C5.73964 8.26343 5.51534 7.99012 5.38289 7.67035C5.25043 7.35058 5.21578 6.99871 5.2833 6.65924C5.35083 6.31978 5.5175 6.00796 5.76224 5.76321C6.00698 5.51847 6.3188 5.3518 6.65827 5.28428C6.99773 5.21675 7.3496 5.25141 7.66937 5.38386C7.98914 5.51632 8.26245 5.74062 8.45475 6.0284C8.64704 6.31619 8.74967 6.65453 8.74967 7.00065C8.74967 7.46478 8.5653 7.9099 8.23711 8.23809C7.90892 8.56628 7.4638 8.75065 6.99968 8.75065Z" />
    </g>
    <defs>
      <clipPath id="clip0_973_5931">
        <rect width="14" height="14" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export { ViewsIcon };
