import { FunctionComponent } from "react";

const TopicAnxiety: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    width={46}
    height={30}
    viewBox="0 0 46 30"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path d="M5.671 7.019c1.381 1.662 3.277 4.033 6.972 4.033 3.695 0 5.59-2.38 6.971-4.033 1.248-1.543 1.905-2.286 3.29-2.286 1.387 0 2.048.743 3.296 2.286 1.38 1.662 3.276 4.033 6.967 4.033 3.69 0 5.59-2.38 6.971-4.033 1.248-1.543 1.905-2.286 3.295-2.286h2.381V0h-2.38c-3.696 0-5.591 2.381-6.972 4.033-1.257 1.538-1.905 2.281-3.295 2.281s-2.043-.743-3.29-2.28C28.485 2.366 26.59 0 22.904 0c-3.686 0-5.59 2.381-6.972 4.033-1.252 1.538-1.904 2.281-3.29 2.281-1.386 0-2.038-.743-3.29-2.28C7.966 2.366 6.075 0 2.38 0H0v4.724h2.381c1.371 0 2.033.752 3.29 2.295ZM5.671 25.962C7.052 27.628 8.948 30 12.643 30c3.695 0 5.59-2.381 6.971-4.038 1.248-1.539 1.905-2.281 3.29-2.281 1.387 0 2.048.742 3.296 2.28C27.58 27.629 29.476 30 33.167 30c3.69 0 5.59-2.381 6.971-4.038 1.248-1.539 1.905-2.281 3.295-2.281h2.381v-4.734h-2.38c-3.696 0-5.591 2.381-6.972 4.034-1.257 1.538-1.905 2.28-3.295 2.28s-2.043-.742-3.29-2.28c-1.391-1.667-3.287-4.034-6.972-4.034-3.686 0-5.59 2.381-6.972 4.034-1.252 1.538-1.904 2.28-3.29 2.28-1.386 0-2.038-.742-3.29-2.28-1.386-1.667-3.277-4.034-6.972-4.034H0v4.734h2.381c1.371 0 2.033.742 3.29 2.28Z" />
  </svg>
);

export { TopicAnxiety };
