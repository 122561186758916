import { FunctionComponent } from "react";

export const KumuDownloadIcon: FunctionComponent<IconProps> = ({
  className,
}) => (
  <svg
    width="18"
    height="22"
    viewBox="0 0 18 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <rect y="19.75" width="18" height="2" rx="1" fill="#292D32" />
    <path
      d="M6.16789 12.2398C6.79786 12.8697 7.875 12.4236 7.875 11.5327L7.875 1.25C7.875 0.697716 8.32272 0.25 8.875 0.25L9.125 0.25C9.67728 0.25 10.125 0.697715 10.125 1.25L10.125 11.5327C10.125 12.4236 11.2021 12.8697 11.8321 12.2398L15.7116 8.36027C16.1046 7.9673 16.7426 7.97012 17.1321 8.36655L17.3054 8.54295C17.69 8.93445 17.6872 9.56279 17.2991 9.95088L9.70711 17.5429C9.31658 17.9334 8.68342 17.9334 8.29289 17.5429L0.700876 9.95088C0.312793 9.56279 0.310014 8.93445 0.694647 8.54295L0.867948 8.36655C1.25743 7.97012 1.89542 7.9673 2.28839 8.36027L6.16789 12.2398Z"
      fill="#292D32"
    />
  </svg>
);
