import { FunctionComponent } from "react";

export const EditProfileIcon: FunctionComponent<IconProps> = ({
  className,
}) => (
  <svg
    width={16}
    height={16}
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path d="M15.293.707a2.417 2.417 0 0 0-3.416 0L0 12.584V16h3.416L15.293 4.123a2.418 2.418 0 0 0 0-3.416ZM2.867 14.667H1.333v-1.534l8.874-8.866L11.74 5.8l-8.873 8.867ZM14.35 3.18l-1.672 1.671-1.53-1.533 1.671-1.669a1.084 1.084 0 1 1 1.533 1.534l-.002-.003Z" />
  </svg>
);
