import React, { FunctionComponent } from "react";

const ShareArrowIcon: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    className={className}
    viewBox="0 0 16 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M8.81872 0.167485C8.71012 0.0830116 8.57999 0.0307278 8.44314 0.0165868C8.30629 0.00244575 8.16822 0.027016 8.04465 0.0874998C7.92108 0.147984 7.81697 0.24195 7.74419 0.3587C7.67141 0.47545 7.63287 0.610292 7.63296 0.747871V3.67625C6.78888 3.71162 5.95595 3.88227 5.16596 4.1817C3.9411 4.64469 2.87339 5.44688 2.08771 6.49442C1.17232 7.70809 0.499366 9.39194 0.289251 11.7017C0.275338 11.8523 0.308203 12.0034 0.383362 12.1346C0.458521 12.2658 0.572316 12.3705 0.709222 12.4347C0.846129 12.4988 0.999481 12.5191 1.14836 12.4928C1.29724 12.4666 1.4344 12.3951 1.54112 12.288C3.28669 10.5395 5.02196 9.67846 6.30469 9.25088C6.83365 9.07456 7.2862 8.97171 7.63296 8.91294V11.7678C7.63293 11.9095 7.67382 12.0481 7.75072 12.167C7.82762 12.2859 7.93725 12.3801 8.06642 12.4381C8.19558 12.4962 8.33878 12.5157 8.47876 12.4943C8.61875 12.4728 8.74956 12.4114 8.85545 12.3174L15.4674 6.44006C15.5482 6.36845 15.6122 6.27994 15.6549 6.1808C15.6975 6.08167 15.7178 5.97435 15.7143 5.86648C15.7108 5.7586 15.6836 5.65283 15.6346 5.55667C15.5855 5.46052 15.5159 5.37635 15.4307 5.31014L8.81872 0.167485ZM8.28388 5.15145H8.28829C8.39076 5.16213 8.49433 5.15121 8.59232 5.11942C8.69032 5.08763 8.78057 5.03566 8.85725 4.96686C8.93394 4.89807 8.99536 4.81397 9.03757 4.71998C9.07977 4.626 9.10182 4.52422 9.10229 4.4212V2.24953L13.8321 5.92873L9.10229 10.131V8.09452C9.10229 7.89968 9.02489 7.71281 8.88712 7.57504C8.74934 7.43726 8.56248 7.35986 8.36763 7.35986H8.30151C8.07729 7.37019 7.85369 7.39127 7.63149 7.42304C7.1907 7.48769 6.57358 7.61111 5.83891 7.85649C4.49095 8.31433 3.22683 8.98931 2.09653 9.85478C2.38158 8.80274 2.79593 7.99755 3.26171 7.37896C3.88143 6.55158 4.7246 5.91844 5.69198 5.55405C6.46185 5.26328 7.2804 5.12312 8.10315 5.14117C8.16927 5.14264 8.21923 5.14558 8.25008 5.14851L8.28241 5.15145H8.28388Z" />
  </svg>
);

export { ShareArrowIcon };
