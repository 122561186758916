import { FunctionComponent } from "react";

const RatingIcon: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 48"
  >
    <path d="M41.882 15.104a1.688 1.688 0 0 0-1.364-1.152l-12.445-1.808-5.57-11.28c-.57-1.152-2.464-1.152-3.041 0l-5.563 11.28-12.445 1.808a1.697 1.697 0 0 0-1.372 1.152 1.688 1.688 0 0 0 .43 1.736l9.008 8.782-2.126 12.392a1.707 1.707 0 0 0 .676 1.663c.523.377 1.213.43 1.782.125l11.134-5.848 11.127 5.848c.251.133.523.193.794.193.352 0 .696-.106.994-.318a1.71 1.71 0 0 0 .676-1.663L32.45 25.622l9.007-8.782c.464-.45.623-1.12.424-1.736Z" />
  </svg>
);

export { RatingIcon };
