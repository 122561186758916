import { FunctionComponent } from "react";

const TipIcon: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    width={23}
    height={23}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path d="M22.063 8.42a2.884 2.884 0 0 0-4.078.19l-3.083 3.388a3.014 3.014 0 0 0-2.578-1.456H2.876A2.876 2.876 0 0 0 0 13.417v6.708A2.875 2.875 0 0 0 2.876 23H12.89l9.381-10.527a2.896 2.896 0 0 0-.208-4.054Zm-1.223 2.778-8.809 9.885H2.876a.959.959 0 0 1-.959-.958v-6.708a.958.958 0 0 1 .959-.959h9.448a1.095 1.095 0 0 1 .156 2.179l-4.948.706.272 1.898 4.947-.708a3.025 3.025 0 0 0 2.409-1.972L19.403 9.9a.957.957 0 0 1 .671-.316c.256-.01.507.08.698.252a.973.973 0 0 1 .068 1.361v.001ZM9.99 8.768l.554.393.553-.392c.434-.307 4.24-3.074 4.24-5.702A2.98 2.98 0 0 0 12.46 0a2.885 2.885 0 0 0-1.917.697A2.884 2.884 0 0 0 8.627 0 2.98 2.98 0 0 0 5.75 3.067c0 2.628 3.805 5.395 4.24 5.702ZM8.628 1.918a.901.901 0 0 1 .958.958h1.917a.903.903 0 0 1 .959-.958 1.07 1.07 0 0 1 .958 1.15c0 1.03-1.534 2.653-2.875 3.72-1.189-.96-2.876-2.614-2.876-3.72a1.068 1.068 0 0 1 .959-1.15Z" />
  </svg>
);

export { TipIcon };
