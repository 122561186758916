import { FunctionComponent } from "react";

export const DecreaseIcon: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="12"
    height="2"
    viewBox="0 0 12 2"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M11.6119 0.15V1.32H0.331875V0.15H11.6119Z" fill="#5B38D6" />
  </svg>
);
