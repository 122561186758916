import { FunctionComponent } from "react";

const TopicBreathing: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    width={50}
    height={37}
    viewBox="0 0 50 37"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path d="M48.571 12.304H31.58a4.558 4.558 0 0 1-4.628-4.497 4.678 4.678 0 0 1 4.628-4.603.935.935 0 0 0 0-1.864 6.496 6.496 0 0 0-6.497 6.497 6.412 6.412 0 0 0 6.497 6.316H48.57a.93.93 0 1 0 0-1.859v.01Z" />
    <path d="M48.571 14.664H31.58a6.911 6.911 0 0 1-6.997-6.817A6.996 6.996 0 0 1 31.58.851a1.434 1.434 0 0 1 0 2.863 4.168 4.168 0 0 0-4.128 4.103 4.073 4.073 0 0 0 4.127 3.998h16.992a1.43 1.43 0 0 1 0 2.859v-.01ZM31.58 1.83a5.997 5.997 0 0 0-5.997 5.997 5.908 5.908 0 0 0 5.997 5.817H48.57a.43.43 0 0 0 0-.86H31.58a5.072 5.072 0 0 1-5.128-4.997 5.187 5.187 0 0 1 5.127-5.102.435.435 0 0 0 0-.865v.01ZM48.57 23.334H18.38a6.738 6.738 0 0 0-6.496 8.556 6.098 6.098 0 0 0 3.428 3.933 6.946 6.946 0 0 0 5.812-.045l2.584-1.23a.931.931 0 0 0 .445-1.239.94.94 0 0 0-1.244-.44l-2.58 1.23a5.124 5.124 0 0 1-4.247.035 4.275 4.275 0 0 1-2.404-2.75 4.82 4.82 0 0 1-.185-1.308 4.888 4.888 0 0 1 4.883-4.883h30.19a.93.93 0 0 0 0-1.859h.005Z" />
    <path d="M18.171 36.922a7.426 7.426 0 0 1-3.058-.66 6.587 6.587 0 0 1-3.704-4.252 7.144 7.144 0 0 1-.27-1.95 7.252 7.252 0 0 1 7.242-7.24h30.19a1.43 1.43 0 0 1 0 2.858H18.38a4.393 4.393 0 0 0-4.383 4.383c0 .397.055.792.165 1.174a3.762 3.762 0 0 0 2.134 2.424 4.562 4.562 0 0 0 3.823-.03l2.584-1.204a1.434 1.434 0 0 1 2.042 1.216 1.44 1.44 0 0 1-.818 1.367l-2.584 1.23a7.496 7.496 0 0 1-3.173.684Zm.21-13.103a6.247 6.247 0 0 0-6.012 7.92 5.602 5.602 0 0 0 3.158 3.614 6.571 6.571 0 0 0 5.388-.04l2.583-1.23a.434.434 0 0 0 .225-.244.444.444 0 0 0 0-.33.429.429 0 0 0-.574-.2l-2.584 1.224a5.608 5.608 0 0 1-4.663.04 4.782 4.782 0 0 1-2.683-3.068 5.373 5.373 0 0 1 5.182-6.827h30.19a.43.43 0 0 0 0-.86H18.38ZM48.57 17.087H10.026a7.657 7.657 0 0 1-7.61-8.571 6.837 6.837 0 0 1 3.602-5.222 7.786 7.786 0 0 1 7.112-.185l2.444 1.19a.93.93 0 0 0 .814-1.675L13.943 1.44a9.645 9.645 0 0 0-8.815.224A8.69 8.69 0 0 0 .5 9.421a9.535 9.535 0 0 0 9.525 9.51h38.546a.93.93 0 1 0 0-1.86v.016Z" />
    <path d="M48.57 19.43H10.026A10.04 10.04 0 0 1 0 9.437a9.235 9.235 0 0 1 4.888-8.191 10.12 10.12 0 0 1 9.27-.235l2.449 1.185a1.431 1.431 0 0 1-1.25 2.573l-2.448-1.19a7.271 7.271 0 0 0-6.652.176 6.352 6.352 0 0 0-3.398 5.682 7.176 7.176 0 0 0 7.166 7.171h38.546a1.43 1.43 0 0 1 0 2.859v-.035ZM9.76.98A9.17 9.17 0 0 0 5.367 2.1 8.19 8.19 0 0 0 1 9.416a9.035 9.035 0 0 0 9.025 9.015h38.546a.43.43 0 0 0 0-.86H10.025A8.18 8.18 0 0 1 1.86 9.437c0-.32.02-.641.055-.96a7.356 7.356 0 0 1 3.848-5.622 8.266 8.266 0 0 1 7.571-.19l2.444 1.185a.43.43 0 1 0 .375-.775l-2.444-1.19A9.085 9.085 0 0 0 9.76.98ZM7.632 21.53H2.359a.93.93 0 1 0 0 1.858h5.273a.93.93 0 0 0 0-1.859Z" />
    <path d="M7.631 23.889H2.36a1.429 1.429 0 1 1 0-2.859h5.272a1.43 1.43 0 0 1 0 2.859Zm-5.272-1.86a.43.43 0 0 0 0 .86h5.272a.43.43 0 1 0 0-.86H2.36ZM15.912 13.683a.93.93 0 0 0 .93.93h2.134a.93.93 0 0 0 0-1.86h-2.134a.93.93 0 0 0-.93.93Z" />
    <path d="M18.975 15.113h-2.134a1.429 1.429 0 1 1 0-2.86h2.134a1.43 1.43 0 0 1 0 2.86Zm-2.134-1.86a.43.43 0 0 0 0 .86h2.134a.43.43 0 0 0 0-.86h-2.134ZM38.79 6.467h.17a.934.934 0 0 0 0-1.864h-.17a.934.934 0 0 0 0 1.864Z" />
    <path d="M38.961 6.967h-.17a1.434 1.434 0 0 1 0-2.863h.17a1.434 1.434 0 0 1 0 2.863Zm-.17-1.864a.435.435 0 0 0 0 .865h.17a.435.435 0 0 0 0-.865h-.17Z" />
  </svg>
);

export { TopicBreathing };
