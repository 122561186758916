// Services
import { clientFetchService } from "@/services/fetch";
import { useContext } from "react";
import UserContext from "../user";

const useSEvents = () => {
  const userContext = useContext(UserContext);

  const createSEvent = ({
    type,
    payload,
  }: {
    type: SEventType;
    payload: any;
  }): Promise<any> => {
    return clientFetchService("/api/sevents", {
      method: "POST",
      body: JSON.stringify({
        type,
        segment: userContext.user.segment,
        payload,
      }),
    });
  };

  const createShareIntentSEvent = ({
    payload,
  }: {
    payload: { creatorID: string; userTier: UserTier };
  }) => createSEvent({ type: "INTENT_SHARE", payload });

  const createViewCreatorSEvent = ({
    payload,
  }: {
    payload: { creatorID: string; userTier: UserTier };
  }) => createSEvent({ type: "VIEW_CREATOR_PAGE", payload });

  const createViewContentSEvent = ({
    payload,
  }: {
    payload: {
      creatorUserName: string;
      userTier: UserTier;
      courseID: string;
      topicID: string; // only the main topic of the content, not leafs and just the first one
    };
  }) => createSEvent({ type: "VIEW_CONTENT_PAGE", payload });

  const liveOpenChatSEvent = ({
    payload,
  }: {
    payload: {
      liveID: string;
    };
  }) => createSEvent({ type: "LIVE_OPEN_CHAT", payload });

  const liveCloseChatSEvent = ({
    payload,
  }: {
    payload: {
      liveID: string;
    };
  }) => createSEvent({ type: "LIVE_CLOSE_CHAT", payload });

  const liveReactionSEvent = ({
    payload,
  }: {
    payload: {
      liveID: string;
    };
  }) => createSEvent({ type: "LIVE_REACTION", payload });

  const live = {
    closeChat: liveCloseChatSEvent,
    openChat: liveOpenChatSEvent,
    react: liveReactionSEvent,
  };

  const supportSuccessSEvent = ({
    payload,
  }: {
    payload: {
      userID: string;
      entityID?: string; // courseId, liveId
      context: ContextType; // in which page it was operated
      transactionType?: string;
    };
  }) => createSEvent({ type: "SUPPORT_SUCCESS", payload });

  const supportFailureSEvent = ({
    payload,
  }: {
    payload: {
      userID: string;
      entityID?: string; // courseId, liveId
      context: ContextType; // in which page it was operated
    };
  }) => createSEvent({ type: "SUPPORT_FAILURE", payload });

  const rateCourseSEvent = ({
    payload,
  }: {
    payload: {
      courseID: string;
      rate: number;
    };
  }) => createSEvent({ type: "RATE_CONTENT", payload });

  const viewItemSevent = ({
    payload,
  }: {
    payload: {
      itemID: string;
      creatorID: string;
    };
  }) => createSEvent({ type: "VIEW_ITEM", payload });

  const shareItemSevent = ({
    payload,
  }: {
    payload: {
      itemID: string;
      creatorID: string;
    };
  }) => createSEvent({ type: "SHARE_ITEM", payload });

  const askItemSevent = ({
    payload,
  }: {
    payload: {
      itemID: string;
      creatorID: string;
    };
  }) => createSEvent({ type: "ASK_ITEM", payload });

  const viewLibraryPageSevent = ({
    payload,
  }: {
    payload: {
      creatorID: string;
    };
  }) => createSEvent({ type: "VIEW_LIBRARY_PAGE", payload });

  const uploadFileSevent = ({
    payload,
  }: {
    payload: {
      creatorID: string;
      fileName: string;
      size: number;
      mimeType: string;
    };
  }) => createSEvent({ type: "UPLOAD_FILE", payload });

  const successFileUploadedSevent = ({
    payload,
  }: {
    payload: {
      creatorID: string;
    };
  }) => createSEvent({ type: "SUCCESS_FILE_UPLOADED", payload });

  const failFileUploadedSevent = ({
    payload,
  }: {
    payload: {
      creatorID: string;
    };
  }) => createSEvent({ type: "FAIL_FILE_UPLOADED", payload });

  const exportSocialContentSevent = ({
    payload,
  }: {
    payload: {
      creatorID: string;
      fileID: string;
      platformKey: string;
      contentType: string;
    };
  }) => createSEvent({ type: "EXPORT_SOCIAL_CONTENT", payload });

  const successExportSocialContentSevent = ({
    payload,
  }: {
    payload: {
      creatorID: string;
      fileID: string;
      platformKey: string;
      contentType: string;
    };
  }) => createSEvent({ type: "SUCCESS_EXPORT_SOCIAL_CONTENT", payload });

  const failExportSocialContentSevent = ({
    payload,
  }: {
    payload: {
      creatorID: string;
      fileID: string;
      platformKey: string;
      contentType: string;
    };
  }) => createSEvent({ type: "FAIL_EXPORT_SOCIAL_CONTENT", payload });

  const failPublishProjectSevent = ({
    payload,
  }: {
    payload: {
      creatorID: string;
      courseID: string;
      contentType: string;
    };
  }) => createSEvent({ type: "FAIL_PUBLISH_SOCIAL_CONTENT", payload });

  const clickNewProjectSevent = ({
    payload,
  }: {
    payload: {
      creatorID: string;
      contentType: string;
    };
  }) => createSEvent({ type: "CLICK_NEW_PROJECT", payload });

  const createNewProjectSevent = ({
    payload,
  }: {
    payload: {
      creatorID: string;
      contentType: string;
      productType: string;
    };
  }) => createSEvent({ type: "CREATE_NEW_PROJECT", payload });

  const addTagsOnProjectSevent = ({
    payload,
  }: {
    payload: {
      creatorID: string;
      courseID: string;
      contentType: string;
      tags: string[];
    };
  }) => createSEvent({ type: "ADD_TAGS_ON_PROJECT", payload });

  const publishProjectSevent = ({
    payload,
  }: {
    payload: {
      creatorID: string;
      courseID: string;
      contentType: string;
    };
  }) => createSEvent({ type: "PUBLISH_PROJECT", payload });

  const support = {
    success: supportSuccessSEvent,
    failure: supportFailureSEvent,
  };

  return {
    createSEvent,
    createShareIntentSEvent,
    createViewCreatorSEvent,
    createViewContentSEvent,
    liveOpenChatSEvent,
    liveCloseChatSEvent,
    rateCourseSEvent,
    viewItemSevent,
    askItemSevent,
    shareItemSevent,
    viewLibraryPageSevent,
    uploadFileSevent,
    successFileUploadedSevent,
    failFileUploadedSevent,
    clickNewProjectSevent,
    createNewProjectSevent,
    addTagsOnProjectSevent,
    publishProjectSevent,
    exportSocialContentSevent,
    successExportSocialContentSevent,
    failExportSocialContentSevent,
    failPublishProjectSevent,
    live,
    support,
  };
};

export { useSEvents };
