import { FunctionComponent } from "react";

export const EditAddressIcon: FunctionComponent<IconProps> = ({
  className,
}) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="fi-rs-clock" clipPath="url(#clip0_777_1351)">
      <g id="01 align center">
        <path
          id="Vector"
          d="M15.2933 0.706707C14.8401 0.254173 14.2258 0 13.5853 0C12.9448 0 12.3305 0.254173 11.8773 0.706707L0 12.584V16H3.41599L15.2933 4.1227C15.7457 3.66938 15.9997 3.05512 15.9997 2.4147C15.9997 1.77428 15.7457 1.16002 15.2933 0.706707ZM2.86666 14.6667H1.33333V13.1333L10.2066 4.2667L11.74 5.80003L2.86666 14.6667ZM14.3506 3.18003L12.6793 4.85136L11.1493 3.31803L12.82 1.64937C13.0233 1.44604 13.2991 1.33181 13.5866 1.33181C13.8742 1.33181 14.15 1.44604 14.3533 1.64937C14.5566 1.8527 14.6709 2.12848 14.6709 2.41604C14.6709 2.70359 14.5566 2.97937 14.3533 3.1827L14.3506 3.18003Z"
          fill="#33878E"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_777_1351">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
