import { FunctionComponent } from "react";
import { classNames } from "@/utils/classNames";

export const MessageIcon: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    width={19}
    height={18}
    xmlns="http://www.w3.org/2000/svg"
    className={classNames("fill-current", className)}
  >
    <path d="M18.5 12v6h-6a6 6 0 0 1-5.19-3 8.225 8.225 0 0 0 1.682-.186A4.492 4.492 0 0 0 12.5 16.5H17V12a4.49 4.49 0 0 0-1.689-3.508c.12-.553.184-1.116.189-1.682a6 6 0 0 1 3 5.19ZM14 6.75a6.75 6.75 0 0 0-13.5 0v6.75h6.75A6.758 6.758 0 0 0 14 6.75Zm-12 0A5.25 5.25 0 1 1 7.25 12H2V6.75Z" />
    <circle cx={4.891} cy={7.087} r={0.787} />
    <circle cx={7.25} cy={7.087} r={0.787} />
    <circle cx={9.615} cy={7.087} r={0.787} />
  </svg>
);
