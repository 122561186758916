import { Stripe, loadStripe } from "@stripe/stripe-js";
import { ParsedUrlQuery } from "querystring";

let stripePromise: Promise<Stripe | null>;
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY!);
  }
  return stripePromise;
};

export const getEncodedBackUrl = (query: ParsedUrlQuery, pathname: string) => {
  if(Object.keys(query).length >= 1){
  const pathnameWithQuery = pathname.replace(
    `[${Object.keys(query)[0]}]`,
    `${Object.values(query)[0]}`
  );
  return encodeURI(`${process.env.NEXT_PUBLIC_URL}/${pathnameWithQuery}`);
  }
  else{
    return encodeURI(`${process.env.NEXT_PUBLIC_URL}${pathname}`)
  }
};

export default getStripe;
