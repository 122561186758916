import { FunctionComponent } from "react";

export const KumuGoToIcon: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.9999 10.75C11.8099 10.75 11.6199 10.68 11.4699 10.53C11.1799 10.24 11.1799 9.75999 11.4699 9.46999L19.6699 1.26999C19.9599 0.97999 20.4399 0.97999 20.7299 1.26999C21.0199 1.55999 21.0199 2.03999 20.7299 2.32999L12.5299 10.53C12.3799 10.68 12.1899 10.75 11.9999 10.75Z"
      fill="#202421"
    />
    <path
      d="M21 6.55C20.59 6.55 20.25 6.21 20.25 5.8V1.75H16.2C15.79 1.75 15.45 1.41 15.45 1C15.45 0.59 15.79 0.25 16.2 0.25H21C21.41 0.25 21.75 0.59 21.75 1V5.8C21.75 6.21 21.41 6.55 21 6.55Z"
      fill="#202421"
    />
    <path
      d="M14 22H8C2.57 22 0 19.43 0 14V8C0 2.57 2.57 0 8 0H10C10.5 0 11 0.5 11 1C11 1.5 10.5 2 10 2H8C3.39 2 2 3.39 2 8V14C2 18.61 3.39 20 8 20H14C18.61 20 20 18.61 20 14V12C20 11.5 20.5 11 21 11C21.5 11 22 11.5 22 12V14C22 19.43 19.43 22 14 22Z"
      fill="#202421"
    />
  </svg>
);
