import { FunctionComponent } from "react";

const CloseIcon: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M16 0.942667L15.0573 0L8 7.05733L0.942667 0L0 0.942667L7.05733 8L0 15.0573L0.942667 16L8 8.94267L15.0573 16L16 15.0573L8.94267 8L16 0.942667Z" />
  </svg>
);

export { CloseIcon };
