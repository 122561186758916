import { FunctionComponent } from "react";

const ShippingIcon: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
    className={className}
  >
    <path d="M12.667 3.049h-1.334v-.667a2 2 0 00-2-2H2a2 2 0 00-2 2v10h1.36a2.31 2.31 0 00-.027.333 2.333 2.333 0 004.667 0 2.31 2.31 0 00-.027-.333h4.054a2.31 2.31 0 00-.027.333 2.333 2.333 0 104.667 0 2.311 2.311 0 00-.028-.333H16v-6a3.338 3.338 0 00-3.333-3.333zm0 1.333a2 2 0 012 2v.667h-3.334V4.382h1.334zm-8 8.333a1 1 0 11-2 0c0-.114.021-.227.062-.333h1.876c.04.106.061.22.062.333zM10 11.05H1.333V2.382A.667.667 0 012 1.715h7.333a.667.667 0 01.667.667v8.667zm3.333 1.666a1 1 0 11-2 0 .94.94 0 01.062-.333h1.876a.94.94 0 01.062.333zm-2-1.666V8.382h3.334v2.667h-3.334z"></path>
  </svg>
);

export { ShippingIcon };
