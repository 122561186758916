import { FunctionComponent } from "react";

const userSmallIcon: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    width="8"
    height="11"
    viewBox="0 0 8 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g id="user">
      <path
        id="Vector"
        d="M3.78244 5.49953C3.28369 5.49953 2.79615 5.35292 2.38146 5.07824C1.96677 4.80356 1.64356 4.41315 1.4527 3.95638C1.26183 3.49961 1.2119 2.99699 1.3092 2.51209C1.4065 2.02718 1.64667 1.58176 1.99933 1.23216C2.352 0.882567 2.80132 0.644487 3.29048 0.548033C3.77964 0.451579 4.28667 0.501083 4.74745 0.690284C5.20823 0.879485 5.60206 1.19989 5.87915 1.61097C6.15623 2.02205 6.30413 2.50536 6.30413 2.99976C6.30346 3.66254 6.03757 4.29798 5.56481 4.76663C5.09204 5.23529 4.45103 5.49887 3.78244 5.49953ZM3.78244 1.33326C3.44994 1.33326 3.12491 1.43099 2.84845 1.61411C2.57199 1.79723 2.35652 2.0575 2.22928 2.36202C2.10204 2.66653 2.06874 3.00161 2.13361 3.32488C2.19848 3.64816 2.35859 3.9451 2.5937 4.17816C2.82881 4.41123 3.12836 4.56995 3.45446 4.63425C3.78057 4.69855 4.11859 4.66555 4.42578 4.53942C4.73296 4.41328 4.99552 4.19968 5.18024 3.92563C5.36497 3.65157 5.46356 3.32937 5.46356 2.99976C5.46356 2.55778 5.28645 2.1339 4.97117 1.82136C4.6559 1.50883 4.2283 1.33326 3.78244 1.33326Z"
        fill="white"
      />
      <path
        id="Vector_2"
        d="M7.56508 10.4993H6.72451V8.39822C6.72407 8.07162 6.59299 7.75852 6.36002 7.52758C6.12705 7.29664 5.81121 7.1667 5.48174 7.16626H2.08334C1.75387 7.1667 1.43802 7.29664 1.20506 7.52758C0.972087 7.75852 0.841009 8.07162 0.840564 8.39822V10.4993H0V8.39822C0.000667336 7.8507 0.220376 7.32579 0.610933 6.93863C1.00149 6.55147 1.53101 6.33367 2.08334 6.33301H5.48174C6.03407 6.33367 6.56359 6.55147 6.95415 6.93863C7.3447 7.32579 7.56441 7.8507 7.56508 8.39822V10.4993Z"
        fill="white"
      />
    </g>
  </svg>
);

export { userSmallIcon };
