import React, { FunctionComponent } from 'react';

interface OwnProps {
    className: string
}

type Props = OwnProps;

export const KumuChevronUp: FunctionComponent<Props> = ({ className }) => {
  return (
      <svg width="16" height="11" viewBox="0 0 16 11" fill="#1C1B1F" xmlns="http://www.w3.org/2000/svg" className={className}>
          <path d="M8 0.500521L0 8.50052L1.86667 10.3672L8 4.23385L14.1333 10.3672L16 8.50052L8 0.500521Z" />
      </svg>
  );
};
