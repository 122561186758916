import { FunctionComponent } from "react";

const SixtyLogoWhiteFooter: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    width={50}
    height={50}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M42.251 49.941c-1.587.027-2.805-.58-3.871-1.504-1.443-1.251-2.73-2.665-4.082-4.01a2566.378 2566.378 0 0 1-9-9.014c-.236-.239-.373-.267-.629-.01-3.903 3.923-7.82 7.833-11.728 11.753-.697.7-1.401 1.382-2.236 1.922-1.584 1.025-3.267 1.228-4.978.444C3.252 48.388 1.44 46.533.4 44.018c-.82-1.984-.32-3.82.983-5.46.772-.972 1.715-1.784 2.577-2.671.705-.725 1.429-1.431 2.142-2.148l8.4-8.433c.343-.345.343-.347-.019-.71-3.822-3.825-7.647-7.65-11.466-11.479-.733-.736-1.392-1.531-1.892-2.452-1.272-2.343-1.158-4.594.423-6.756.751-1.028 1.618-1.941 2.665-2.67C6.71-.497 9.2-.406 11.573 1.495c.366.293.705.613 1.036.944a346932.43 346932.43 0 0 0 34.886 34.913c.619.62 1.186 1.277 1.65 2.02.97 1.553 1.14 3.2.38 4.851-1.171 2.549-3.087 4.37-5.693 5.437-.542.222-1.117.313-1.58.281Z"
      fill="#fff"
    />
    <path
      d="M27.07 6.531c.141-2.467 1.307-4.742 4.02-5.972 2.417-1.096 4.94-.504 6.816 1.689.156.182.303.372.444.567.125.172.213.162.34-.009a9.148 9.148 0 0 1 1.212-1.347c2.3-2.079 5.696-1.719 7.792.215 2.762 2.549 3.052 7.025.659 9.9-.905 1.088-1.979 2.022-2.99 3.012-.883.867-1.79 1.708-2.686 2.564-1.196 1.143-2.4 2.277-3.657 3.355-.494.424-.508.44-1.006.006a73.268 73.268 0 0 1-2.365-2.129c-1.63-1.55-3.235-3.124-4.86-4.678-.693-.663-1.411-1.302-2.032-2.037-1.15-1.36-1.682-2.942-1.687-5.136Z"
      fill="#03BD93"
    />
  </svg>
);

export { SixtyLogoWhiteFooter };
