import { FunctionComponent } from "react";

const TopicNutritional: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    width={38}
    height={40}
    viewBox="0 0 38 40"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.207 0a1.235 1.235 0 0 0-.9.42c-2.546 2.876-3.197 6.252-3.046 9.642-2.235-1.575-4.645-2.541-7.073-2.565-.08 0-.161.009-.24.024-.634.006-1.265.072-1.886.198-3.47.759-5.998 3.4-7.2 6.822-1.203 3.424-1.17 7.653.245 11.972A21.996 21.996 0 0 0 8.15 36.886c3.07 2.415 6.705 3.65 10.17 2.89.147-.032.284-.075.425-.112.142.04.283.078.424.113 3.466.759 7.106-.472 10.17-2.89a21.963 21.963 0 0 0 7.045-10.374c1.415-4.319 1.448-8.544.245-11.967-1.202-3.423-3.724-6.068-7.19-6.827-3.197-.698-6.601.471-9.661 2.73-.24-3.145.235-5.918 2.404-8.37a1.25 1.25 0 0 0-.9-2.074L21.207 0ZM10.174 9.996c2.47.024 5.253 1.264 7.7 3.621a1.25 1.25 0 0 0 1.735 0c3.018-2.904 6.469-4.074 9.289-3.456 2.55.556 4.385 2.414 5.366 5.21.98 2.796 1.009 6.502-.26 10.374a19.55 19.55 0 0 1-6.22 9.18c-2.635 2.08-5.53 2.97-8.08 2.41a5.806 5.806 0 0 1-.58-.156 1.288 1.288 0 0 0-.755 0c-.192.06-.387.11-.585.15-2.55.562-5.455-.33-8.091-2.409a19.549 19.549 0 0 1-6.22-9.18c-1.268-3.857-1.235-7.544-.25-10.373.986-2.83 2.83-4.654 5.362-5.21a7.018 7.018 0 0 1 1.589-.166v.005ZM6.25 18.743A1.25 1.25 0 0 0 5 19.993c0 1.565 0 2.998.543 4.559.542 1.56 1.622 3.126 3.574 5.073a1.25 1.25 0 0 0 1.763-1.768c-1.801-1.801-2.598-3.046-2.985-4.14-.386-1.094-.396-2.16-.396-3.725a1.25 1.25 0 0 0-1.25-1.25Z"
    />
  </svg>
);

export { TopicNutritional };
