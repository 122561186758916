import { FunctionComponent } from "react";
import { classNames } from "@/utils/classNames";

export const SubscribersIcon: FunctionComponent<IconProps> = ({
  className,
}) => (
  <svg
    className={classNames(className, "fill-current")}
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M7.80558 10C6.808 10 5.83282 9.70676 5.00336 9.15735C4.17391 8.60794 3.52742 7.82705 3.14566 6.91342C2.7639 5.99979 2.66402 4.99446 2.85864 4.02455C3.05326 3.05465 3.53364 2.16373 4.23903 1.46447C4.94443 0.765206 5.84316 0.289002 6.82158 0.0960758C7.79999 -0.0968503 8.81414 0.00216643 9.73579 0.380605C10.6574 0.759043 11.4452 1.39991 11.9994 2.22215C12.5536 3.0444 12.8494 4.0111 12.8494 5C12.8481 6.32568 12.3163 7.59668 11.3707 8.53407C10.425 9.47147 9.14289 9.99868 7.80558 10ZM7.80558 1.66667C7.14053 1.66667 6.49041 1.86217 5.93744 2.22844C5.38446 2.59471 4.95347 3.1153 4.69897 3.72439C4.44446 4.33348 4.37787 5.0037 4.50762 5.6503C4.63737 6.29691 4.95762 6.89085 5.42788 7.35703C5.89815 7.8232 6.4973 8.14067 7.14958 8.26929C7.80185 8.3979 8.47796 8.33189 9.09239 8.0796C9.70682 7.82731 10.232 7.40007 10.6015 6.8519C10.9709 6.30374 11.1682 5.65927 11.1682 5C11.1682 4.11595 10.8139 3.2681 10.1833 2.64298C9.55268 2.01786 8.69739 1.66667 7.80558 1.66667Z" />
    <path d="M15.3718 19.9998H13.6905V15.7973C13.6896 15.1441 13.4275 14.5178 12.9615 14.0559C12.4955 13.5939 11.8637 13.334 11.2048 13.3332H4.4073C3.74831 13.334 3.11656 13.5939 2.65057 14.0559C2.18459 14.5178 1.92241 15.1441 1.92152 15.7973V19.9998H0.240234V15.7973C0.241569 14.7022 0.681027 13.6522 1.46222 12.8779C2.2434 12.1035 3.30254 11.6678 4.4073 11.6665H11.2048C12.3095 11.6678 13.3687 12.1035 14.1498 12.8779C14.931 13.6522 15.3705 14.7022 15.3718 15.7973V19.9998Z" />
    <path d="M18.7351 6.5625C18.2673 6.5856 17.8277 6.79147 17.5126 7.13506C17.1976 7.47864 17.0326 7.93197 17.0538 8.39583C17.0751 7.93197 16.9101 7.47864 16.595 7.13506C16.28 6.79147 15.8404 6.5856 15.3726 6.5625C14.9047 6.5856 14.4652 6.79147 14.1501 7.13506C13.835 7.47864 13.67 7.93197 13.6913 8.39583C13.6913 10.3125 17.0538 12.6733 17.0538 12.6733C17.0538 12.6733 20.4164 10.3158 20.4164 8.39583C20.4377 7.93197 20.2727 7.47864 19.9576 7.13506C19.6425 6.79147 19.203 6.5856 18.7351 6.5625Z" />
  </svg>
);
