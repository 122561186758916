import { FunctionComponent } from "react";

const LockIcon: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="14"
    height="17"
    viewBox="0 0 14 17"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M11.3333 5.83333V5.16667C11.3333 3.92899 10.8417 2.742 9.9665 1.86684C9.09133 0.991665 7.90434 0.5 6.66667 0.5C5.42899 0.5 4.242 0.991665 3.36683 1.86684C2.49167 2.742 2 3.92899 2 5.16667V5.83333H0V14.5C0 15.0304 0.210714 15.5391 0.585786 15.9142C0.960859 16.2893 1.46957 16.5 2 16.5H11.3333C11.8638 16.5 12.3725 16.2893 12.7475 15.9142C13.1226 15.5391 13.3333 15.0304 13.3333 14.5V5.83333H11.3333ZM3.33333 5.16667C3.33333 4.28261 3.68452 3.43477 4.30964 2.80964C4.93476 2.18452 5.78261 1.83333 6.66667 1.83333C7.55072 1.83333 8.39857 2.18452 9.02369 2.80964C9.64881 3.43477 10 4.28261 10 5.16667V5.83333H3.33333V5.16667ZM12 14.5C12 14.6768 11.9298 14.8464 11.8047 14.9714C11.6797 15.0964 11.5101 15.1667 11.3333 15.1667H2C1.82319 15.1667 1.65362 15.0964 1.5286 14.9714C1.40357 14.8464 1.33333 14.6768 1.33333 14.5V7.16667H12V14.5Z" />
    <path d="M7.33334 9.83594H6V12.5026H7.33334V9.83594Z" />
  </svg>
);

export { LockIcon };
