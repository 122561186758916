import { FunctionComponent } from "react";

const ShoppingBagIcon: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g id="fi-rs-shopping-bag" clipPath="url(#clip0_777_2002)">
      <g id="01 align center">
        <path
          id="Vector"
          d="M12 4C12 2.93913 11.5786 1.92172 10.8284 1.17157C10.0783 0.421427 9.06087 0 8 0C6.93913 0 5.92172 0.421427 5.17157 1.17157C4.42143 1.92172 4 2.93913 4 4H0V14C0 14.5304 0.210714 15.0391 0.585786 15.4142C0.960859 15.7893 1.46957 16 2 16H14C14.5304 16 15.0391 15.7893 15.4142 15.4142C15.7893 15.0391 16 14.5304 16 14V4H12ZM8 1.33333C8.70724 1.33333 9.38552 1.61428 9.88562 2.11438C10.3857 2.61448 10.6667 3.29276 10.6667 4H5.33333C5.33333 3.29276 5.61428 2.61448 6.11438 2.11438C6.61448 1.61428 7.29276 1.33333 8 1.33333ZM14.6667 14C14.6667 14.1768 14.5964 14.3464 14.4714 14.4714C14.3464 14.5964 14.1768 14.6667 14 14.6667H2C1.82319 14.6667 1.65362 14.5964 1.5286 14.4714C1.40357 14.3464 1.33333 14.1768 1.33333 14V5.33333H4V6.66667H5.33333V5.33333H10.6667V6.66667H12V5.33333H14.6667V14Z"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_777_2002">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export { ShoppingBagIcon };
