import { FunctionComponent } from "react";

const TruckIcon: FunctionComponent<IconProps> = ({ className }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g id="fi-rs-truck-side" clipPath="url(#clip0_777_1392)">
      <path
        id="Vector"
        d="M12.6667 3.33268H11.3333V2.66602C11.3333 2.13558 11.1226 1.62687 10.7475 1.2518C10.3725 0.876729 9.86377 0.666016 9.33333 0.666016H2C1.46957 0.666016 0.960859 0.876729 0.585786 1.2518C0.210714 1.62687 0 2.13558 0 2.66602L0 12.666H1.36067C1.34351 12.7763 1.33438 12.8877 1.33333 12.9993C1.33333 13.6182 1.57917 14.2117 2.01675 14.6493C2.45434 15.0869 3.04783 15.3327 3.66667 15.3327C4.28551 15.3327 4.879 15.0869 5.31658 14.6493C5.75417 14.2117 6 13.6182 6 12.9993C5.99896 12.8877 5.98982 12.7763 5.97267 12.666H10.0273C10.0102 12.7763 10.001 12.8877 10 12.9993C10 13.6182 10.2458 14.2117 10.6834 14.6493C11.121 15.0869 11.7145 15.3327 12.3333 15.3327C12.9522 15.3327 13.5457 15.0869 13.9832 14.6493C14.4208 14.2117 14.6667 13.6182 14.6667 12.9993C14.6656 12.8877 14.6565 12.7763 14.6393 12.666H16V6.66602C15.9989 5.78229 15.6474 4.93505 15.0225 4.31016C14.3976 3.68527 13.5504 3.33374 12.6667 3.33268ZM12.6667 4.66602C13.1971 4.66602 13.7058 4.87673 14.0809 5.2518C14.456 5.62687 14.6667 6.13558 14.6667 6.66602V7.33268H11.3333V4.66602H12.6667ZM4.66667 12.9993C4.66667 13.2646 4.56131 13.5189 4.37377 13.7065C4.18624 13.894 3.93188 13.9993 3.66667 13.9993C3.40145 13.9993 3.1471 13.894 2.95956 13.7065C2.77202 13.5189 2.66667 13.2646 2.66667 12.9993C2.66708 12.8854 2.68808 12.7725 2.72867 12.666H4.60467C4.64525 12.7725 4.66626 12.8854 4.66667 12.9993ZM10 11.3327H1.33333V2.66602C1.33333 2.4892 1.40357 2.31964 1.5286 2.19461C1.65362 2.06959 1.82319 1.99935 2 1.99935H9.33333C9.51014 1.99935 9.67971 2.06959 9.80474 2.19461C9.92976 2.31964 10 2.4892 10 2.66602V11.3327ZM13.3333 12.9993C13.3333 13.2646 13.228 13.5189 13.0404 13.7065C12.8529 13.894 12.5985 13.9993 12.3333 13.9993C12.0681 13.9993 11.8138 13.894 11.6262 13.7065C11.4387 13.5189 11.3333 13.2646 11.3333 12.9993C11.3336 12.8854 11.3546 12.7725 11.3953 12.666H13.2713C13.312 12.7725 13.333 12.8854 13.3333 12.9993ZM11.3333 11.3327V8.66602H14.6667V11.3327H11.3333Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_777_1392">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export { TruckIcon };
